import React from 'react';
import { withRouter } from 'react-router';

import './style.scss';

import containsRoute from '../../utils/containsRoute';

import FooterSection from './FooterSection';
import { navLinks, socialLinks } from './footerLinks';

function Footer({ location: { pathname } }) {
  const currentYear = new Date().getFullYear();
  const pathsToHide = [];

  const isVisible = !containsRoute(pathsToHide, pathname);

  if (isVisible) {
    return (
      <footer className="Footer">
        <section className="Footer__navs">
          { navLinks.map((footerData) => (
            <FooterSection
              title={ footerData.title }
              items={ footerData.items }
              key={ footerData.title }
            />
          )) }

          <FooterSection title={ socialLinks.title } items={ socialLinks.items } social />
        </section>

        <p className="Footer__copyrights">
          Copyright &#169; { currentYear } Mednavi. Wszelkie prawa zastrzeżone
        </p>
      </footer>
    );
  }

  return null;
}

export { Footer };
export default withRouter(Footer);
