/* eslint-disable */
import React from 'react';

function LinkedInIcon({ ...props }) {
  return (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" { ...props } >
      <g fill="none" fillRule="evenodd">
        <path d="M.5 16c0 8.56 6.94 15.5 15.5 15.5 8.56 0 15.5-6.94 15.5-15.5C31.5 7.44 24.56.5 16 .5 7.44.5.5 7.44.5 16z" stroke="currentColor"/>
        <path d="M11.937 22v-9.259h-2.85V22h2.85zm0-12.54C11.92 8.63 11.37 8 10.477 8S9 8.63 9 9.46c0 .81.567 1.46 1.443 1.46h.017c.91 0 1.477-.65 1.477-1.46zM16.585 22v-5.275c0-.283.02-.565.103-.766.226-.564.74-1.149 1.603-1.149 1.131 0 1.84.645 1.84 1.915V22H23v-5.416c0-2.902-1.542-4.252-3.599-4.252-1.686 0-2.426.947-2.837 1.592l.02-1.239h-2.863c.041.887 0 9.315 0 9.315h2.864z" fill="currentColor" fillRule="nonzero"/>
      </g>
    </svg>
  );
}

export default LinkedInIcon;
