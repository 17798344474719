import React from 'react';
import { AuthProvider } from '../../../providers/authProvider';
import cookie from '../../../utils/cookieHelper';

const UserContext = React.createContext({});

export default function withUserContext(WrappedComponent, withRedirect) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.authProvider = props.authProvider
        || new AuthProvider({ onUserChange: this.onUserChange, onNoUser: this.onNoUser });
    }

    state = {
      userData: null,
    };

    onUserChange = userData => {
      cookie.set('isAuthenticated', userData ? true : false);
      this.setState({ userData });
    };

    onNoUser = () => {
      cookie.set('isAuthenticated', false);

      cookie.set('userPath', window.location.pathname);
    }

    render() {
      const context = {
        ...this.state,
        signOut: this.authProvider.signOut,
        retrieveCurrentUser: this.authProvider.retrieveCurrentUser,
      };

      return (
        <UserContext.Provider value={ context }>
          <WrappedComponent context={ context } { ...this.props } />
        </UserContext.Provider>
      );
    }
  };
}

export { UserContext };
