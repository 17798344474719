import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import clientManagerInstance from '../../providers/clientProvider';
import getApiPath from '../../utils/getApiPath';
import Loader from '../Loader';
import HospitalItem from './HospitalItem';
import emptyIcon from '../../assets/images/svg/emptyIcon.svg';
import Button from '../Button';
import filterIcon from '../../assets/images/svg/filterIcon.svg';
import './style.scss';

const propTypes = {
  filters: PropTypes.shape({
    activeFilters: PropTypes.array,
    activeCity: PropTypes.string,
    activeVoivodeship: PropTypes.string,
  }),
};

class HospitalList extends Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);
    this.client = props.client || clientManagerInstance.client;

    const state = {
      isLoading: false,
      isLoginModalOpen: false,
      isLoadingMore: false,
      hospitals: [],
      meta: {},
      pages: 0,
      activePage: null,
      error: null,
    };

    this.state = state;
  }

  componentDidUpdate(prevProps) {
    const { filters } = this.props;

    if (filters !== prevProps.filters) {
      this.getHospitals({
        filters_ids: filters.activeFilters,
        city: filters.activeCity,
        voivodeship: filters.activeVoivodeship,
        query: filters.activeQuery,
        page_number: filters.activePage + 1,
        latitude: filters.latitude,
        longitude: filters.longitude,
      });

      this.setState({
        activePage: filters.activePage,
      });
    }
  }

  getHospitals = async (params) => {
    this.setState({
      isLoading: !this.state.isLoadingMore && true,
    });

    try {
      const res = await this.client.post(getApiPath('HOSPITALS'), params);

      this.setState({
        isLoading: false,
        isLoadingMore: false,
        hospitals: res.data.hospitals,
        meta: res.data.meta,
        pages: res.data.total_pages,
        all: res.data.all_count,
        filtered: res.data.filtered_count,
        error: null,
      });

    } catch (e) {
      this.setState({
        isLoading: false,
        error: e.response ? e.response.statusText : e,
      });

      return e;
    }
  }

  handlePageClick = ({ selected }) => {
    const { filters, setActivePage } = this.props;
    const activePage = selected + 1;
    const params = {
      filters_ids: filters.activeFilters,
      city: filters.activeCity,
      voivodeship: filters.activeVoivodeship,
      query: filters.activeQuery,
      page_number: activePage,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
    };

    setActivePage(selected);
    this.getHospitals(params);

    window.scrollTo(0, 0);
  }

  renderHospitalsList = (hospitals) => {
    const { filters } = this.props;
    const { activePage } = this.state;
    return (
      hospitals.map(hospital => (
        <HospitalItem hospital={ hospital } key={ hospital.id }
          onClick={ e => { this.handleHospitalClick(e, hospital.slug); } }
          filters={ { ...filters, activePage } } />
      ))
    );
  }

  renderEmptyState = () => {
    return (
      <figure className="HospitalList--empty">
        <img src={ emptyIcon } alt="Brak wyników" className="HospitalList__icon" />
        <figcaption className="HospitalList__figcaption">
          Nie znaleźliśmy żadnych placówek spełniających określane parametry wyszukiwania. Spróbuj poszerzyć ich zakres.
        </figcaption>
      </figure>
    );
  }

  render() {
    const { isLoading, isLoadingMore, error, hospitals, meta, pages, filtered, all } = this.state;
    const { filters } = this.props;

    if (error) {
      return this.renderEmptyState();
    }

    if (!hospitals.length && !isLoading) {
      return this.renderEmptyState();
    }

    if (isLoading) {
      return <Loader isStrech />;
    }

    return (
      <>
        <Helmet>
          <title>Gdzie się leczyć</title>
          <meta name="description" content={ meta.description } />

          <meta property="og:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | Gdzie się leczyć` } />
          <meta property="og:description" content={ meta.description } />
          <meta property="og:image" content={ meta.image_url } />
          <meta property="og:image:secure_url" content={ meta.image_url } />
          <meta property="og:image:width" content="600px" />
          <meta property="og:image:height" content="314px" />

          <meta name="twitter:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | Gdzie się leczyć` } />
          <meta name="twitter:description" content={ meta.description } />
          <meta name="twitter:image" content={ meta.image_url } />
        </Helmet>

        <h3 className="Hospitals__list-heading">
          <span>{ filtered && all ? `Lista placówek (${filtered} z ${all})` : 'Lista placówek' }</span>
          <Button
            className="Hospitals__list-button"
            text
            onClick={ this.props.toggleFilter }
          >
            <img src={ filterIcon } alt="" className="Hospitals__list-icon" />
            Filtruj wyniki
          </Button>
        </h3>

        { isLoadingMore ? (
          <Loader isStrech />
        ) : (
          <ul className="HospitalList">
            { this.renderHospitalsList(hospitals) }
          </ul>
          ) }

        <ReactPaginate
          previousLabel={ '' }
          nextLabel={ '' }
          breakLabel={ '...' }
          pageCount={ pages }
          forcePage={ filters.activePage }
          marginPagesDisplayed={ 2 }
          pageRangeDisplayed={ 5 }
          onPageChange={ this.handlePageClick }
          containerClassName={ 'HospitalList__pagination' }
          pageClassName={ 'HospitalList__page' }
          pageLinkClassName={ 'HospitalList__link' }
          activeClassName={ 'HospitalList__page--active' }
          previousClassName={ 'HospitalList__page HospitalList__page--prev' }
          nextClassName={ 'HospitalList__page HospitalList__page--next' }
          previousLinkClassName={ 'HospitalList__link HospitalList__link--prev' }
          nextLinkClassName={ 'HospitalList__link HospitalList__link--next' }
          breakClassName={ 'HospitalList__page HospitalList__page--brake' }
          breakLinkClassName={ 'HospitalList__link HospitalList__link--brake' }
        />
      </>
    );
  }
}

export default withRouter(HospitalList);
