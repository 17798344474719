const navLinks = [
  {
    title: 'Mednavi',
    items: [
      { name: 'O nas', url: 'ABOUT' },
      { name: 'Kontakt', url: 'CONTACT' },
    ],
  },
  {
    title: 'Warto wiedzieć',
    items: [
      { name: 'Odpowiedzi ekspertów', url: 'ARTICLES' },
      { name: 'Historie pacjentów', url: 'STORIES' },
    ],
  },
  {
    title: 'Usługi',
    items: [
      { name: 'Polityka prywatności', url: 'PRIVACY_POLICY' },
      { name: 'Regulamin', url: 'TERMS' },
      { name: 'FAQ', url: 'FAQ' },
    ],
  },
];

// TODO: replace with correct links
const socialLinks = {
  title: 'Społeczność',
  items: [
    { name: 'Facebook', url: 'https://www.facebook.com/MednaviPolska' },
  ],
};

export {
  navLinks,
  socialLinks,
};
