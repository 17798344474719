import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Group, Arrow, Circle } from 'react-konva';

const propTypes = {
  connections: PropTypes.arrayOf(PropTypes.shape({
    x1: PropTypes.number,
    y1: PropTypes.number,
    x2: PropTypes.number,
    y2: PropTypes.number,
    x3: PropTypes.number,
    y3: PropTypes.number,
    x4: PropTypes.number,
    y4: PropTypes.number,
    x5: PropTypes.number,
    y5: PropTypes.number,
    type: PropTypes.string,
  })),
};

class DiagramConnections extends Component {
  static propTypes = propTypes;

  render() {
    const { connections, isPrinting } = this.props;

    return (
      <Group>

        { connections.map((connection, index) => {
          const { x1, y1, x2, y2, x3, y3, x4, y4, x5, y5, x6, y6, type } = connection;
          const arrowX1 = type === 'horizontal' ? x1 + 7 : x1;
          const circleX1 = type === 'horizontal' ? x1 + 8 : x1;
          const connColor = isPrinting ? '#46516a' : '#7f8ba8';

          return (
            <Group key={ index }>
              <Arrow
                points={ [arrowX1, y1, x2, y2, x3, y3, x4, y4, x5, y5, x6, y6] }
                stroke={ connColor }
                strokeWidth={ 1 }
                fill={ connColor }
                pointerLength={ 10 }
                pointerWidth={ 10 }
              />
              <Circle
                radius={ 5 }
                x={ circleX1 }
                y={ y1 }
                fill={ '#FAFAFB' }
                stroke={ connColor }
                strokeWidth={ 0.75 }
              />
            </Group>
          );
        }) }
      </Group>
    );
  }
}

export default DiagramConnections;
