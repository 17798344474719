import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash/collection';

import './style.scss';

import withContent from '../../_enhancers/withContent';
import getApiPath from '../../../utils/getApiPath';
import getRoutePath from '../../../utils/getRoutePath';

import ArticlesCarousel from '../ArticlesCarousel';
import Button from '../../Button';

const propTypes = {
  content: PropTypes.shape({
    stories: PropTypes.arrayOf(PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      full_name: PropTypes.string.isRequired,
    })),
  }),
};

function ArticleList({ content, ...props }) {
  const category = filter(content.categories, { slug: props.categorySlug });
  const articles = category[0].articles.slice(0, 4);

  return (
    <section className="ArticleList">
      <div className="ArticleList__container">
        <h2 className="ArticleList__heading">
          { props.header }
        </h2>

        <h3 className="ArticleList__subheading">
          { props.subheader }
        </h3>

        <ArticlesCarousel articles={ articles }/>

        <Button
          secondary
          className="ArticleList__button"
          to={ getRoutePath('ARTICLES') }
        >
          Zobacz podobne artykuły
        </Button>
      </div>
    </section>
  );
}

ArticleList.propTypes = propTypes;

export default withContent(ArticleList, getApiPath('CONTENT_ARTICLES'));
