import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

import getRoutePath from '../../../utils/getRoutePath';
import getStripString from '../../../utils/getStripString';

import Button from '../../Button';

const propTypes = {
  mission: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    link_label: PropTypes.string.isRequired,
  }),
};

function Mission(mission) {
  return (
    <section className="Mission">
      <div className="Mission__container">
        <section className="Mission__content">
          <h3 className="Mission__heading">{ mission.heading }</h3>

          <h2 className="Mission__title">{ mission.title }</h2>

          { mission.text && (
            <p className="Mission__text">
              { getStripString(mission.text) }
            </p>
          ) }

          { mission.link_label && (
            <Button
              dark
              large
              className="Mission__button"
              to={ getRoutePath('ABOUT') }
            >
              { mission.link_label }
            </Button>
          ) }
        </section>
      </div>
    </section>
  );
}

Mission.propTypes = propTypes;

export default Mission;
