import axios from 'axios';

export class ClientProvider {
  constructor(client ) {
    this.client = client || this.generateClient();
  }

  generateClient () {
    const API_URL = process.env.REACT_APP_URL_API;

    return axios.create({
      baseURL: API_URL,
    });
  }

  addClientHeaders = (headers) => {
    this.client.defaults.headers.common = {
      ...this.client.defaults.headers.common,
      ...headers,
    };
  };

  removeClientHeaders = (headerNames) => {
    const headers = { ...this.client.defaults.headers.common };
    headerNames.forEach(name => {
      delete headers[name];
    });
    this.client.defaults.headers.common = headers;
  };

  addResponseInterceptor(interceptorParams) {
    if (this.client.interceptors && interceptorParams) {
      this.client.interceptors.response.use(...interceptorParams);
    }
  }
}

export default new ClientProvider();
