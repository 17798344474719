/* eslint-disable */
import React from 'react';

function PauseButtonIcon({ ...props }) {
  return (
    <svg viewBox="0 0 102 102" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <defs>
        <path d="M51 11c22.1 0 40 17.9 40 40S73.1 91 51 91 11 73.1 11 51s17.9-40 40-40zm0 77c20.39625 0 37-16.60375 37-37S71.39625 14 51 14 14 30.60375 14 51s16.60375 37 37 37z" id="b"/>
        <filter x="-18.1%" y="-11.9%" width="136.2%" height="136.2%" filterUnits="objectBoundingBox" id="a">
          <feOffset dy="5" in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0" in="shadowBlurOuter1"/>
        </filter>
        <path d="M6 0c.55228475 0 1 .44771525 1 1v20c0 .5522847-.44771525 1-1 1H1c-.55228475 0-1-.4477153-1-1V1c0-.55228475.44771525-1 1-1h5zm15 0c.5522847 0 1 .44771525 1 1v20c0 .5522847-.4477153 1-1 1h-5c-.5522847 0-1-.4477153-1-1V1c0-.55228475.4477153-1 1-1h5z" id="d"/>
        <filter x="-65.9%" y="-43.2%" width="231.8%" height="231.8%" filterUnits="objectBoundingBox" id="c">
          <feOffset dy="5" in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0" in="shadowBlurOuter1"/>
        </filter>
      </defs>
      <g fillRule="nonzero" fill="none">
        <ellipse fill="#FFF" transform="matrix(-1 0 0 1 101.98288 0)" cx="50.9914405" cy="50.9914405" rx="50.189463" ry="50.2075949"/>
        <use fill="#000" filter="url(#a)" xlinkHref="#b"/>
        <use fill="#E8EBF0" xlinkHref="#b"/>
        <g>
          <g transform="translate(40 40)">
            <use fill="#000" filter="url(#c)" xlinkHref="#d"/>
            <use fill="#00C5B8" xlinkHref="#d"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PauseButtonIcon;
