/* eslint-disable */

import React from 'react';

function PrintIcon({ ...props }) {
  return (
    <svg height="14" viewBox="0 0 16 14" width="16" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <path d="m26.6 130h-11.2c-1.328 0-2.4 1.042222-2.4 2.333333v4.666667h3.0077515v3h9.9899292v-3h3.0023193v-4.666667c0-1.291111-1.072-2.333333-2.4-2.333333zm-8.6 4h6v4h-6zm8-8h-10v3h10z" fill="#00c5b8" transform="translate(-13 -126)"/>
    </svg>
  );
}

export default PrintIcon;
