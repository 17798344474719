import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import './style.scss';

import withContent from '../../_enhancers/withContent';
import getApiPath from '../../../utils/getApiPath';

import PhoneIcon from '../../svg/PhoneIcon';
import LetterIcon from '../../svg/LetterIcon';
import MapWrapper from '../../MapWrapper';

const propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    latitude: PropTypes.string.isRequired,
    longitude: PropTypes.string.isRequired,
  }).isRequired,
};

function Contact({ content }) {
  const { title, subtitle, email, phone, latitude, longitude, meta } = content;

  return (
    <>
      <Helmet>
        <title>Kontakt</title>
        <meta name="description" content={ meta.description } />

        <meta property="og:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | Kontakt` } />
        <meta property="og:description" content={ meta.description } />
        <meta property="og:image" content={ meta.image_url } />
        <meta property="og:image:secure_url" content={ meta.image_url } />
        <meta property="og:image:width" content="600px" />
        <meta property="og:image:height" content="314px" />

        <meta name="twitter:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | Kontakt` } />
        <meta name="twitter:description" content={ meta.description } />
        <meta name="twitter:image" content={ meta.image_url } />

        <script type="application/ld+json">
          { `
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "name": "Mednavi",
              "location": {
                "@type": "Place",
                "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": "${latitude}",
                  "longitude": "${longitude}"
                }
              },
              "contactPoint": {
                 "@type": "ContactPoint",
                 "contactType": "customer service",
                 "telephone": "${phone}",
                 "email": "${email}"
               },
               "telephone": "${phone}",
               "email": "${email}"
            }
          ` }
        </script>
      </Helmet>

      <section className="Contact">
        <div className="Contact__container">
          <div className="Contact__wrapper">

            <h1 className="Contact__heading">
              { title }
            </h1>

            <h3 className="Contact__subheading">
              { subtitle }
            </h3>

            <div className="Contact__company-data">
              <p className="Contact__company-name">mednavi.pl</p>
            </div>

            <section className="Contact__details">
              <a href={ `tel:${phone}` } title="Kontakt telefoniczny" className="Contact__phone Contact__link">
                <PhoneIcon className="Contact__icon"/>
                <span>{ phone }</span>
              </a>
              <a href={ `mailto:${email}` } title="Kontakt mailowy" className="Contact__mail Contact__link">
                <LetterIcon className="Contact__icon"/>
                <span>{ email }</span>
              </a>

              <div className="Contact__map-wrapper">
                <MapWrapper lat={ latitude } lng={ longitude } />
              </div>

            </section>
          </div>
        </div>
      </section>
    </>
  );
}

Contact.propTypes = propTypes;

export default withContent(Contact, getApiPath('CONTENT_CONTACT'));
