import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';

import './style.scss';

import WrappedField from '../../WrappedField';
import Button from '../../Button';

const propTypes = {
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

class FaqSearch extends Component {
  static propTypes = propTypes;

  onSubmit = ( { search } ) => {
    this.props.onSearch(search.toLowerCase());
  }

  render() {
    const { title } = this.props;
    return (
      <section className="FaqSearch">
        <div className="FaqSearch__container">
          <h1 className="FaqSearch__title">
            { title }
          </h1>

          <Formik
            onSubmit={ this.onSubmit }
            initialValues={ { search: '' } }
          >
            { ({ errors, touched }) => (
              <Form
                className="FaqSearch__form"
              >
                <WrappedField
                  large
                  search
                  name="search"
                  type="text"
                  placeholder="Wyszukaj"
                  isTouched={ touched.search }
                  error={ errors.search }
                  autoComplete="off"
                />

                <Button
                  large
                  className="FaqSearch__form-button"
                  type="submit"
                >
                  Szukaj
                </Button>
              </Form>
            ) }
          </Formik>
        </div>
      </section>
    );
  }
}

FaqSearch.propTypes = propTypes;

export default FaqSearch;
