import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import printJS from 'print-js';
import { truncate } from 'lodash/string';
import cn from 'classnames';
/* eslint-disable */
import './style.scss';

import withNotification from '../_enhancers/withNotification';
import clientManagerInstance from '../../providers/clientProvider';
import getRoutePath from '../../utils/getRoutePath';
import getApiPath from '../../utils/getApiPath';
import withContent from '../_enhancers/withContent';
import removeTrailingLetters from '../../utils/removeTrailingLetters';
import HospitalItem from '../HospitalList/HospitalItem';
import TherapyFilters from './TherapyFilters';
import Button from '../Button';
import Loader from '../Loader';
import PrintIcon from '../svg/PrintIcon';
import PaddlockIcon from '../svg/PaddlockIcon';
import filterIcon from '../../assets/images/svg/filterIcon.svg';
import emptyIcon from '../../assets/images/svg/emptyIcon.svg';
import PriceCardPremium from '../_landingComponents/PricingPremium/PriceCardPremium';

import getNotificationContent from '../../utils/getNotificationContent';


const propTypes = {
  category_slug: PropTypes.string,
  mutation_slug: PropTypes.string,
  slug: PropTypes.string,
};


class Therapy extends Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);
    this.client = props.client || clientManagerInstance.client;

    const state = {
      error: null,
      therapy: null,
      page: 1,
      activeFilters: [],
      activeVoivodeship: null,
      activeCity: null,
      isFilterVisible: false,
      latitude: null,
      longitude: null,
    };

    this.state = state;
  }

  componentDidMount() {
    const { location } = this.props;

    if (location.state && location.state.filters) {
      this.setFilters(location.state.filters);
    }

    navigator.geolocation && navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        this.setState({
          latitude: coords.latitude,
          longitude: coords.longitude,
        });
      },
      () => { },
      {
        timeout: 5000,
      }
    );

    if (location.search.includes('payment_result=success')) {
      this.props.toggleNotification(getNotificationContent('p24_success'));
      this.tempProlong();
    }

    this.getTherapy();
  }

  setFilters = async (filters) => {
    await this.setState({
      activeVoivodeship: filters.activeVoivodeship,
      activeCity: filters.activeCity,
    });
  }

  tempProlong = async () => {
    try {
      await this.client.get(getApiPath('PROLONG_SUBSCRIPTION'));
    } catch (error) {
      this.setState({
        error: error.response ? error.response.statusText : error,
      });
    }
  }

  getTherapy = async () => {
    try {
      const { category, mutation, therapy } = this.props;
      const { latitude, longitude } = this.state;
      const res = await this.client.get(getApiPath('THERAPY', [category, mutation, therapy]),
        { params: { latitude: latitude, longitude: longitude } });

      this.setState({
        therapy: res.data,
      });
    } catch (error) {
      this.setState({
        error: error.response ? error.response.statusText : error,
      });
    }
  }

  printTherapy = () => {
    printJS({
      printable: 'TherapyPrint',
      type: 'html',
      documentTitle: `${this.state.therapy.cancer_mutation_name}`,
      targetStyles: ['*'],
    });
  }

  loadMoreHospital = () => {
    this.setState({
      page: this.state.page + 1,
    });
  }

  onSelectChange = (region) => {
    region.type === 'city' && this.setState({ activeCity: region.value });
    region.type === 'voivodeships' && this.setState({ activeVoivodeship: region.value });
  }

  filteredHospitals = () => {
    const { activeCity, activeVoivodeship, therapy } = this.state;
    const { hospitals } = therapy;

    if (!hospitals.length) {
      return [];
    }

    return hospitals.filter(hospital => {
      if (activeCity) {
        return hospital.address2.split(' ')[1] === activeCity;
      } else if (activeVoivodeship) {
        return hospital.voivodeship === activeVoivodeship;
      }

      return hospitals;
    });
  }

  renderAlreadyRegisteredButton = () => (
    <p className="Therapy__lock-login">
      Masz już konto?&nbsp;
      <Link
        className="Therapy__lock-link"
        to={{ pathname: getRoutePath('SIGN_IN'), state: { currentPath: window.location.pathname } }}
      >
        Zaloguj się
      </Link>
    </p>
  )

  renderBlockContent = truncatedDescription => {
    const promptText = 'Czas jest cenny. Nie trać go na szukanie informacji.';
    const subText = 'Skorzystaj z Mednavi Premium aby poznać szczegóły terapii i placówki, które je oferują.';
    const subSubText = 'Wiedza lekarzy, dane NFZ i opisy szpitali w jednym miejscu.';
    const { pricing } = this.props.content;

    return (
      <>
        <div
          className="Therapy__description Therapy__description--truncated"
          dangerouslySetInnerHTML={{ __html: truncatedDescription }}
        />
        <div className="Therapy__lock">
          <PaddlockIcon className="Therapy__paddlock-icon" />
          <div className="Therapy__lock-copy">
            <h3 className="LoginPromptModal__header">
              <div dangerouslySetInnerHTML={{ __html: removeTrailingLetters(promptText) }} />
            </h3>
            <h6 className="LoginPromptModal__sub-header">
              <div dangerouslySetInnerHTML={{ __html: removeTrailingLetters(subText) }} />
            </h6>
            <div className="LoginPromptModal__header">
              <div dangerouslySetInnerHTML={{ __html: removeTrailingLetters(subSubText) }} />
            </div>
          </div>
          <div className="Therapy__cards">
            {pricing.plans.map((plan, i) => {
              return (
                <PriceCardPremium index={i} isPremium={true} buttonText={pricing.link_label} {...plan} />
              );
            })}
          </div>
        </div>
      </>
    );
  }

  toggleFilter = () => {
    this.setState(prevState => ({
      isFilterVisible: !prevState.isFilterVisible,
    }));
  }

  renderHospitalsList = () => {
    const { location } = this.props;
    const { page } = this.state;

    const activeNode = location.state && location.state.activeNode;
    const activeColumn = location.state && location.state.activeColumn;
    const hospitals = this.filteredHospitals();

    const filtersClassName = cn(
      'Therapy__filter',
      { 'Therapy__filter--visible': this.state.isFilterVisible }
    );

    return (
      <section className="Therapy__hospitals">

        <div className="Therapy__hospitals-container">
          <h3 className="Therapy__hospitals-header">
            Placówki oferujące terapię
          </h3>

          <aside className={filtersClassName}>
            <TherapyFilters
              onSelectChange={this.onSelectChange}
              filtersState={this.state}
              toggleFilter={this.toggleFilter}
            />
          </aside>

          <Button
            className="Hospitals__list-button"
            text
            onClick={this.toggleFilter}
          >
            <img src={filterIcon} alt="" className="Hospitals__list-icon" />
            Filtruj wyniki
          </Button>
        </div>

        {hospitals.length > 0 ? (
          <ul className="Therapy__list">
            {hospitals.slice(0, 5 * page).map(hospital => (
              <HospitalItem
                hospital={hospital}
                key={hospital.id}
                filters={this.state}
                activeNode={activeNode}
                activeColumn={activeColumn}
              />
            ))}
          </ul>
        ) : this.emptyFilteredHospitalsList()}

        {(hospitals.length > 5)
          && (page <= Math.round(hospitals.length / page))
          && (
            <Button
              className="Therapy__button"
              onClick={this.loadMoreHospital}
            >
              Wczytaj więcej
            </Button>
          )}

      </section>
    );
  }

  emptyFilteredHospitalsList = () => {
    return (
      <figure className="Therapy--empty">
        <img src={emptyIcon} alt="Brak wyników" className="Therapy__icon" />
        <figcaption className="Therapy__figcaption">
          Nie znaleźliśmy dostępnych placówek w wybranej okolicy oferujących ten typ terapii.
          Spróbuj poszerzyć zakres.
        </figcaption>
      </figure>
    );
  }

  render() {
    const { category, mutation, location } = this.props;
    const { therapy } = this.state;

    const activeNode = location.state && location.state.activeNode;
    const activeColumn = location.state && location.state.activeColumn;
    const activeCategory = location.state && location.state.activeCategory;

    if (therapy) {
      const { name, hospitals_count, description } = therapy;
      const truncatedDescription = truncate(description, {
        'length': 330,
      });

      return (
        <section className="Therapy">
          <Helmet>
            <title>{process.env.REACT_APP_PAGE_TITLE} | {name}</title>
          </Helmet>

          <Button
            className="Therapy__print"
            icon
            onClick={this.printTherapy}
          >
            <PrintIcon />
          </Button>

          <div className="Therapy__container">
            <Link
              to={{
                pathname: getRoutePath('IOCh', [category, mutation]),
                state: { activeNode, activeColumn, activeCategory },
              }}
              className="Therapy__back"
            >
              Wróć
            </Link>

            <ul className="Therapy__breadcrumbs">
              <li>
                <Link
                  className="Therapy__link"
                  to={getRoutePath('CATEGORIES', category)}
                >
                  {therapy.cancer_kind_name} /
                </Link>
              </li>
              <li>
                <Link
                  className="Therapy__link"
                  to={getRoutePath('IOCh', [category, mutation])}
                >
                  {therapy.cancer_mutation_name}
                </Link>
              </li>
            </ul>

            <section id="TherapyPrint">
              <h1 className="Therapy__title">
                {name}
              </h1>

              {hospitals_count > 0 && (
                <p className="Therapy__subtitle">
                  Terapię można podjąć w
                  <span className="Therapy__count">
                    {hospitals_count}
                    {hospitals_count > 1 ? ' szpitalach' : ' szpitalu'}
                  </span>
                </p>
              )}

              <div
                className="Therapy__description"
                dangerouslySetInnerHTML={{ __html: description }}
              />

              {hospitals_count >= 0 && this.renderHospitalsList()}
            </section>

          </div>
        </section>
      );
    }

    return <Loader isStrech />;
  }
}

export default withRouter(withContent(withNotification(Therapy), getApiPath('CONTENT_PREMIUM')));
