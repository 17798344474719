import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DIRECTION_LEFT, DIRECTION_RIGHT } from 'hammerjs';
import Hammer from 'react-hammerjs';

import './style.scss';
import ArticleItem from './ArticleItem';

const propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  })),
};

class ArticlesCarousel extends Component {
  static propTypes = propTypes;

  state = {
    activeId: this.props.initialSlide || 0,
  };

  onChange = () => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(this.state.activeId);
    }
  };

  setActiveSlide = index => {
    this.setState({ activeId: index }, () => {
      this.onChange(index);
    });
  };

  nextSlide = () => {
    const { articles } = this.props;
    const itemsCount = articles.length;

    if (this.state.activeId < itemsCount - 1) {
      this.setState(
        prevState => ({ activeId: prevState.activeId + 1 }),
        () => {
          this.onChange();
        }
      );
    }
  };

  prevSlide = () => {
    if (this.state.activeId > 0) {
      this.setState(
        prevState => ({ activeId: prevState.activeId - 1 }),
        () => {
          this.onChange();
        }
      );
    }
  };

  onSwipe = ({ direction }) => {
    switch (direction) {
      case DIRECTION_LEFT:
        this.nextSlide();
        break;
      case DIRECTION_RIGHT:
        this.prevSlide();
        break;
      default:
        return;
    }
  };

  render() {
    const { articles } = this.props;
    let spacing = 300;

    if (window.innerWidth <= 768) {
      spacing = 290;
    };

    return (
      <Hammer onSwipe={ this.onSwipe }>
        <div className="ArticlesCarousel">
          <div className="ArticlesCarousel__container">
            <div className="ArticlesCarousel__inner">
              { articles.map((article, index) => (
                <div
                  className="ArticlesCarousel__item"
                  key={ article.slug }
                  style={ {
                    transform: `translate(-${this.state.activeId * spacing}px, 0)`,
                  } }
                  onClick={ () => this.setActiveSlide(index) }
                  onKeyDown={ () => this.nextSlide() }
                  role="presentation"
                >
                  <ArticleItem article={ article } index={ index }
                    active={ this.state.activeId } tip={ this.props.tip } />
                </div>
              )) }
            </div>
          </div>
        </div>
      </Hammer>
    );
  }
}

export default ArticlesCarousel;
