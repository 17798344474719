import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '100%',
};

export class MapWrapper extends Component {
  render() {
    return (
      <Map
        google={ this.props.google }
        zoom={ 15 }
        style={ mapStyles }
        initialCenter={ {
          lat: this.props.lat,
          lng: this.props.lng,
        } }
      >
        <Marker />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(MapWrapper);
