import React from 'react';
import './style.scss';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import copyIcon from '../../../assets/images/svg/copy.svg';
import insta from '../../../assets/images/svg/insta.svg';
import removeTrailingLetters from '../../../utils/removeTrailingLetters';

function UpsideDownMission() {
  return (
    <>
      <section className="UpsideDownMission">
        <div className="UpsideDownMission__container">
          <section className="UpsideDownMission__content">
            <h5 style={ { color: 'white' } }>Pomóż, dołączając się do akcji #swiatdogorynogami</h5>
            <section className="UpsideDownMission__suffix UpsideDownMission__suffix--narrow">
              <p className="UpsideDownMission__suffix-content UpsideDownMission__suffix-content--narrow"
                style={ { color: 'white' } }
                dangerouslySetInnerHTML={ {
                  __html: removeTrailingLetters('Biorąc udział w akcji, pomagasz Mednavi dotrzeć z informacjami,' +
                  ' jak i gdzie leczyć raka, do osób, które właśnie teraz takiej wiedzy poszukują.'),
                } } />
            </section>

            <section className="UpsideDown__join-area">

              <div className="UpsideDownTabs__content-panel UpsideDownTabs__content-panel--turqoise
              UpsideDown__join-tab">
                <h5 dangerouslySetInnerHTML={ {
                  __html: removeTrailingLetters(
                    'Obróć swoje zdjęcie profilowe w mediach społecznościowych'
                  ),
                } } />
                <div
                  className="UpsideDown__avatar"
                />
                <a
                  without rel="noopener noreferrer"
                  className="UpsideDownMission__info"
                  href="https://mednavi.insignia.pl/"
                  target="_blank">
                  Sprawdź, jak obrócić zdjęcie
                </a>
              </div>

              <h2 style={ { padding: '0 14px', fontSize: '40px', color: '#001066', marginBottom: 0 } }>+</h2>

              <div className="UpsideDownTabs__content-panel UpsideDownTabs__content-panel--turqoise
              UpsideDown__join-tab UpsideDown__join-tab--vertical">
                <div className="UpsideDownMission__sharing">
                  <div>
                    <h5
                      className="UpsideDown__turqoise"
                      dangerouslySetInnerHTML={ {
                        __html: removeTrailingLetters(
                          'Dodaj post z odwróconym zdjęciem'
                        ),
                      } } />

                    <h6 className="UpsideDown__join-suffix">W opisie dodaj link do akcji:</h6>
                    <div
                    className="UpsideDownTabs__send-link"
                    role="menuitem"
              tabIndex={ 0 }
                    style={ { marginBottom: '12px', outline: 'none' } }
                    onClick={ () => toast.info(<span>Link został skopiowany do schowka.</span>,
                    { className: 'Home__notification' }) }
                    onKeyPress={ () => toast.info(<span>Link został skopiowany do schowka.</span>,
                      { className: 'Home__notification' }) }>
                      <CopyToClipboard text="https://mednavi.pl/swiatdogorynogami">
                        <div
                        className="UpsideDownTabs__fake-input UpsideDownTabs__fake-input--narrow"
                        style={ { cursor: 'pointer' } }
                        >
                          <h6 className="UpsideDownTabs__fake-input-heading UpsideDownTabs__fake-input-heading--narrow">
                            https://mednavi.pl/swiatdogorynogami
                          </h6>
                        </div>
                      </CopyToClipboard>

                      <CopyToClipboard text="https://mednavi.pl/swiatdogorynogami"
                      onClick={ () => toast.info(<span>Link został skopiowany do schowka.</span>,
                          { className: 'Home__notification' }) }>
                        <img src={ copyIcon } alt="Skopiuj do schowka" className="UpsideDownTabs__send-link-icon">
                        </img>
                      </CopyToClipboard>

                    </div>
                    <h6 className="UpsideDown__join-suffix">oraz nasze hashtagi:</h6>
                    <h5>
                      #swiatdogorynogami<br />#mednavi
                    </h5>
                  </div>
                  <div style={ { minWidth: '200px' } }>
                    <img src={ insta } className="UpsideDown__insta-img" alt="Dołącz" />
                  </div>

                </div>
              </div>
            </section>
          </section>
        </div>
      </section>
      <section className="UpsideDownMission__suffix">
        <h5 className="UpsideDownMission__suffix-title">Pomóż sobie i innym.</h5>
        <p className="UpsideDownMission__suffix-content"
          dangerouslySetInnerHTML={ {
            __html: removeTrailingLetters('Mednavi skraca drogę, jaką pokonuje chory i jego bliscy,' +
            ' by dowiedzieć się jak i gdzie leczyć raka. Łączymy metody leczenia z miejscami, które je oferują.'),
          } } />
      </section>
    </>
  );
}

export default UpsideDownMission;
