import React from 'react';
import cn from 'classnames';

import './style.scss';


function Loader({ isCover, isStrech, isInline, className }) {
  const classes = cn('Loader',
    { 'Loader--cover': isCover },
    { 'Loader--strech': isStrech },
    className);

  return (
    <div className={ classes }>
      <div className={ cn('Loader__spinner', { 'Loader__spinner--inline': isInline }) } />
    </div>
  );
}

export default Loader;
