import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './style.scss';

import getRoutePath from '../../../../utils/getRoutePath';

const propTypes = {
  article: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  index: PropTypes.number.isRequired,
  active: PropTypes.number.isRequired,
};

function ArticleItem({ article, index, active, tip }) {
  const isFaded = index === active - 1 || index === active + 4;

  return (
    <div className={ `ArticleItem ${isFaded && 'ArticleItem--faded'}` }>
      <Link
        className="ArticleItem__link"
        to={ getRoutePath(tip ? 'TIP' : 'ARTICLE', article.slug) }
      >
        <picture
          style={ { backgroundImage: `url(${article.image_url})` } }
          className="ArticleItem__thumb"
        />
      </Link>

      <Link
        className="ArticleItem__link"
        to={ getRoutePath(tip ? 'TIP' : 'ARTICLE', article.slug) }
      >
        <h3 className="ArticleItem__label">{ article.title }</h3>
      </Link>
    </div>
  );
}

ArticleItem.propTypes = propTypes;

export default ArticleItem;
