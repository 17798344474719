/* eslint-disable */

import React from 'react';

function PhoneIcon({ ...props }) {
  return (
    <svg height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <path d="m14.522 13.471-2.334 2.3-5.959-5.959 2.3-2.334c.28448509-.28939124.36587322-.72201258.206-1.095l-2.395-5.59c-.19568219-.45561286-.69568735-.69901963-1.175-.572l-4.4 1.162c-.45280168.1171582-.76391127.5325248-.749 1 .27983813 5.11388846 2.40613859 9.9524301 5.984 13.617 3.6657405 3.5783789 8.5058438 5.7043856 13.621 5.983.4674752.0149113.8828418-.2961983 1-.749l1.162-4.4c.1270196-.4793126-.1163871-.9793178-.572-1.175l-5.59-2.395c-.3742843-.1614326-.8090918-.0795352-1.099.207z" fill="#20c5d8"/>
    </svg>
  );
}


export default PhoneIcon;