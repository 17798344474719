import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './style.scss';

import getApiPath from '../../../utils/getApiPath';
import removeTrailingLetters from '../../../utils/removeTrailingLetters';
import ZoomOutIcon from '../../svg/ZoomOutIcon';
import FacebookIcon from '../../svg/FacebookShareIcon';
import InstagramIcon from '../../svg/InstagramShareIcon';
import ReactTooltip from 'react-tooltip';
import LetterIcon from '../../svg/LetterShareIcon';
import getStripString from '../../../utils/getStripString';

import Button from '../../Button';
import ArticleList from '../../_landingComponents/ArticleList';
import clientManagerInstance from '../../../providers/clientProvider';
import withNotification from '../../_enhancers/withNotification';
import Loader from '../../Loader';
import getNotificationContent from '../../../utils/getNotificationContent';
import getRoutePath from '../../../utils/getRoutePath';


const propTypes = {
  content: PropTypes.shape({
    article: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      image_url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired, //richtext
      author_avatar_url: PropTypes.string.isRequired,
      author_full_name: PropTypes.string.isRequired,
      author_position: PropTypes.string.isRequired,
      category_slug: PropTypes.string.isRequired,
    }),
    more_articles: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      subheading: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

class Article extends Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);
    this.client = props.client || clientManagerInstance.client;

    const state = {
      isLoading: false,
      article: null,
      moreArticles: null,
      isHovered: false,
      error: null,
    };

    this.state = state;
  }

  componentDidMount() {
    this.getArticle(this.props.slug);
  }

  componentDidUpdate(prevProps) {
    if (this.props.slug !== prevProps.slug) {
      this.setState({ isLoading: true });
      this.getArticle(this.props.slug);
    }
  }

  isArticleFavourited = async () => {
    const favourites = await this.client.get(getApiPath('LIBRARY'));
    const filtered = favourites.data.articles.filter(article => article.slug === this.props.slug);

    return filtered.length > 0;
  }

  getArticle = async (slug) => {
    try {
      const res = await this.client.get(getApiPath('CONTENT_ARTICLE', slug));

      this.setState({
        article: res.data.content.article,
        moreArticles: res.data.content.more_articles,
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        error: e.response ? e.response.statusText : e,
      });
    }
  }

  handleHover = () => {
    this.setState(prevState => ({ isHovered: !prevState.isHovered }));
  }

  renderArticle = () => {
    const { article, moreArticles } = this.state;
    const { location } = this.props;
    const backLink = location.state ? location.state.prevPath : getRoutePath('ARTICLES');
    const url = window.location.href;
    const firstParagraphRegexp = article.text.match(/<div>([^<]*)<\/div>/);
    const firstParagraph = firstParagraphRegexp ? firstParagraphRegexp[0] : '';
    const restContent = article.text.replace(firstParagraph, '');

    const facebookUrl = `https://www.facebook.com/sharer.php?u=${url}`;
    const twitterUrl = `https://twitter.com/share?url=${url}&text=${article.title}`;

    return (
      <>
        <Helmet>
          <title>{ article.title }</title>
          <meta name="description" content={ getStripString(article.text) } />

          <meta property="og:title" content={ `${article.title}` } />
          <meta property="og:type" content="article" />
          <meta property="og:description" content={ getStripString(article.text) } />
          <meta property="og:image" content={ article.image_url } />
          <meta property="og:image:secure_url" content={ article.image_url } />
          <meta property="og:image:width" content="600px" />
          <meta property="og:image:height" content="314px" />

          <meta name="twitter:title" content={ `${article.title}` } />
          <meta name="twitter:description" content={ getStripString(article.text) } />
          <meta name="twitter:image" content={ article.image_url } />
          <script type="application/ld+json">
            { `
            {
              "@context": "http://schema.org",
              "@type": "Article",
              "articleBody": "${getStripString(article.text)}",
              "name": "${article.title}",
              "headline": "${article.title}",
              "image": "${article.image_url}",
              "author": {
                "@type": "Person",
                "name": "${article.author_full_name}",
                "jobTitle": "${article.author_position}"
              }
            }
          ` }
          </script>
        </Helmet>

        <article className="Article">
          <section className="Article__container">
            <div className="Article__nav-actions">
              <Link
                to={ { pathname: backLink } }
                className="Article__link"
              >
                Wróć
              </Link>

              <div className="Article__social-media">
                <div className={ `Article__sharing ${!this.state.isHovered ? 'Article__sharing--hidden' : ''}` }>
                  <Button
                    className="Article__button"
                    icon
                    href={ twitterUrl }
                    target="_blank"
                    onClick={ this.handleHover }
                  >
                    <InstagramIcon className="Article__icon" data-tip="Udostępnij na Twitterze" />
                    <ReactTooltip place="top" type="light" />
                  </Button>
                  <Button
                    className="Article__button"
                    href={ facebookUrl }
                    target="_blank"
                    onClick={ this.handleHover }
                    icon
                  >
                    <FacebookIcon className="Article__icon" data-tip="Udostępnij na Facebooku" />
                  </Button>
                  <CopyToClipboard text={ window.location.href }>
                    <Button
                      className="Article__button"
                      icon
                      onClick={ () => {
                        this.props.toggleNotification(getNotificationContent('diagram_link_copied'));
                        this.handleHover();
                      } }
                    >
                      <LetterIcon className="Article__icon" data-tip="Skopiuj link" />
                    </Button>
                  </CopyToClipboard>
                </div>
                <Button
                  className="Article__button Article__button--margined"
                  onClick={ this.handleHover }
                  icon
                >
                  <ZoomOutIcon />
                </Button>
              </div>
            </div>

            <div className="Article__header">
              <h1 className="Article__title">{ article.title }</h1>

              <div className="Article__author">
                <picture
                  className="Article__bio-avatar"
                  style={ { backgroundImage: `url(${article.author_avatar_url})` } }
                />
                <h5 className="Article__heading">odpowiada:</h5>
                <div>
                  <h4 className="Article__bio-name">{ article.author_full_name }</h4>
                  <h3 className="Article__bio-position">{ article.author_position }</h3>
                </div>

              </div>
            </div>


            <div
              className="Article__text"
              dangerouslySetInnerHTML={ { __html: firstParagraph && removeTrailingLetters(firstParagraph) } }
            />

            <div className="Article__image">
              <div className="Article__image-wrapper" style={ { backgroundImage: `url(${article.image_url})` } } />
            </div>

            <div
              className="Article__text"
              dangerouslySetInnerHTML={ { __html: removeTrailingLetters(restContent) } }
              />

            { article.suffix && (
              <div className="Story__annotation">
                <img
                  className="Story__annotation-image"
                  src={ article.source_image_url }
                  alt=""
                />
                <div
                  className="Story__annotation-text"
                  dangerouslySetInnerHTML={ { __html: removeTrailingLetters(article.suffix) } }
                />
              </div>
            ) }
          </section>

          <Button
            className="Article__button Article__button--main"
            large
            to={ getRoutePath('CATEGORIES') }
          >
            Zobacz jak się leczyć
          </Button>
        </article>


        <ArticleList
          categorySlug={ article.category_slug }
          header={ moreArticles.heading }
          subheader={ moreArticles.subheading }
        />
      </>
    );
  }

  render() {
    const { article, isLoading } = this.state;

    return (
      article && !isLoading ? this.renderArticle() : <Loader />
    );
  }
}

export default withRouter(withNotification(Article));
