import React from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import withNotification from '../../../_enhancers/withNotification';
import { UserContext } from '../../../_enhancers/withUserContext';
import Button from '../../../Button';
import getRoutePath from '../../../../utils/getRoutePath';
import PaymentModal from '../../../PaymentModal';
import './style.scss';
import cookies from '../../../../utils/cookieHelper';

class PriceCardPremium extends React.Component {
  state = {
    modalIsOpen: false,
  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { price, total_price, duration_string,
      favoured_premium, name, buttonText, isPremium } = this.props;

    const localPrice = (price / 100).toLocaleString('pl-PL', { style: 'currency', currency:'PLN' });
    const localTotalPrice = (total_price / 100).toLocaleString('pl-PL', { style: 'currency', currency:'PLN' });

    return (
      <>
        <PaymentModal
          modalIsOpen={ this.state.modalIsOpen }
          activePlanId={ this.props.id }
          onModalClose={ this.closeModal }
        />

        <div className={ classnames('PriceCardPremium',
          { 'PriceCardPremium--favoured': favoured_premium || isPremium }) }>
          <h4 className="PriceCardPremium__title">{ name }</h4>

          <p className="PriceCardPremium__amount">
            <span className="PriceCardPremium__price">{ localTotalPrice }</span>
            <span className="PriceCardPremium__subheader">{ localPrice } / { duration_string }</span>
          </p>

          <UserContext.Consumer>
            { ({ userData }) => {
              return (
                <Button
                  secondary={ !favoured_premium && !isPremium }
                  className="PriceCardPremium__apply"
                  onClick={ () => cookies.set('paymentPlan', this.props.id) }
                  { ...(userData
                    ? { onClick: () => this.setState({ modalIsOpen: true }) }
                    : { to: { pathname: getRoutePath('SIGN_IN'), state: { currentPath: getRoutePath('PREMIUM') }  } }
                  ) }
                >
                  { buttonText }
                </Button>
              );
            } }
          </UserContext.Consumer>
        </div>
      </>
    );
  }
}

export default withRouter(withNotification(PriceCardPremium));
