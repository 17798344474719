import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import getApiPath from '../../../utils/getApiPath';
import cookie from '../../../utils/cookieHelper';
import withContent from '../../_enhancers/withContent';
import RankItem from './RanklItem';
import './style.scss';

const propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    ranks: PropTypes.array.isRequired,
  }).isRequired,
};

class Ranks extends React.Component {
  componentDidMount() {
    const { state } = this.props.location;

    cookie.remove('categorySlug');

    if (state && state.categorySlug) {
      document.getElementById(state.categorySlug).scrollIntoView(true);
      window.scrollBy(0, window.innerWidth < 992 ? -120 : -80);
    }
  }

  render() {
    const { ranks, heading, subheading } = this.props.content;

    return (
      <>
        <Helmet>
          <title>{ subheading }</title>
        </Helmet>

        <section className="Ranks">
          <div className="Ranks__container">
            <h3 className="Ranks__subheading">
              { heading }
            </h3>

            <h1 className="Ranks__heading">
              { subheading }
            </h1>
            <div className="Ranks__categories">
              { ranks.map((rank, idx) => (
                <section className="Ranks__category" key={ idx } id={ rank.slug }>
                  <h5 className="Ranks__category-name">{ rank.name }</h5>
                  { rank.ranks.map((el, idx) => {
                    return (
                      <div className="Rank__tile-content Ranks__tile-content" key={ idx }>
                        <div className="Rank__tile-hospital Ranks__tile-hospital">
                          <RankItem hospital={ el.hospital } categorySlug={ rank.slug } />
                        </div>
                        <div className="Rank__tile-value-wrapper Ranks__tile-value-wrapper">
                          <h5 className="Rank__tile-value Ranks__tile-value">{ el.count }</h5>
                        </div>
                      </div>
                    );
                  }) }
                </section>
              )) }
            </div>
          </div>

        </section>
      </>
    );
  }
}

Ranks.propTypes = propTypes;

export default withContent(Ranks, getApiPath('CONTENT_RANKS'));
