import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Group, Rect, Text } from 'react-konva';

const propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};


class DiagramNodeVariants extends Component {
  static propTypes = propTypes;

  constructor(props){
    super(props);

    const state = {
      isActive: false,
      headerWrapperHeight: 0,
      headerHeight: 0,
      spacing: 12,
      nodeHeight: 124,
      nodeWidth: 311,
    };

    this.state = state;
  }

  componentDidMount() {
    this.setState({
      headerWrapperHeight: this.headerWrapper.getClientRect().height,
      headerHeight: this.header.height(),
    });

    this.setChildrenPosition();
  }

  componentDidUpdate() {
    if(this.props.id === this.props.activeNode && !this.state.isActive ) {
      this.setState({
        isActive: true,
      });
    }
    if(this.props.id !== this.props.activeNode && this.state.isActive) {
      this.setState({
        isActive: false,
      });
    }
  }

  setChildrenPosition = () => {
    const { spacing } = this.state;
    const children = this.childrenWrapper.getChildren();

    children.reduce((acc, child, index) => {
      const height = child.getAttr('height');
      const position = spacing * index + acc;
      acc += height;
      child.setAttr('y', position );
      return acc;
    }, 0);
  }

  hideVariants = () => {
    this.props.setActiveNode(false, this.props.activeColumn);
  }

  showVariants = () => {
    const { id, setActiveNode, scrollToNode, activeColumn } = this.props;

    setActiveNode(id, activeColumn);
    scrollToNode(id);
  }

  renderButton = () => {
    const { children } = this.props;
    const { isActive, nodeHeight, nodeWidth } = this.state;
    const childrenNodes = React.Children.count(children);

    const y = isActive ?
      nodeHeight + (nodeHeight + 12)  * childrenNodes - 30:
      nodeHeight;

    const text = isActive ? 'Zwiń szczegóły' : 'Rozwiń szczegóły';
    const fill = isActive ? '#08198C' : '#20C5D8';

    const handleClick = isActive ? this.hideVariants : this.showVariants;
    const isMobile = ('ontouchstart' in window)
                      || (navigator.MaxTouchPoints > 0)
                      || (navigator.msMaxTouchPoints > 0);

    return (
      <Group
        x={ 0 }
        y={ y - 40 }
        width={ nodeWidth }
        height={ 40 }
        onClick={ !isMobile && handleClick }
        onTap={ isMobile && handleClick }
        preventDefault={ false }
        onMouseEnter={ () => {
          document.body.style.cursor = 'pointer';
        } }
        onMouseLeave={ () => {
          document.body.style.cursor = 'default';
        } }
      >
        <Rect
          x={ 0 }
          y={ 0 }
          width={ nodeWidth }
          height={ 10 }
          fill={ fill }
          stroke={ fill }
          strokeWidth={ 1 }
          preventDefault={ false }
        />
        <Rect
          x={ 0 }
          y={ 1 }
          width={ nodeWidth }
          height={ 40 }
          fill={ fill }
          cornerRadius={ 8 }
          stroke={ fill }
          strokeWidth={ 1 }
          preventDefault={ false }
        />
        <Text
          x={ 0 }
          y={ 0 }
          width={ nodeWidth }
          height={ 40 }
          fill="#fff"
          fontFamily="aktiv-grotesk-extended, sans-serif"
          fontSize={ 12 }
          fontStyle="bold"
          align="center"
          verticalAlign="middle"
          text={ text }
          listening={ false }
        />
      </Group>
    );
  }


  variantCopy = (variants) => {
    const dividedCount = variants % 10;

    if(variants === 1) { return 'Możliwy jeden wariant terapii'; }
    else if((variants < 10 || variants > 20) && dividedCount > 1 && dividedCount < 5) {
      return `Możliwe ${variants} warianty terapii`;
    }
    else return `Możliwych ${variants} wariantów terapii`;
  }

  render() {
    const { id, name, children, activeNode } = this.props;
    const { isActive, nodeHeight, nodeWidth } = this.state;
    const childrenNodes = React.Children.count(children);

    const nodeFill = isActive ? '#001066' : '#fff';
    const textFill = isActive ? '#fff' : '#001066';
    const activeNodeHeight = id === activeNode ?
      nodeHeight + (nodeHeight + 12)  * childrenNodes - 30:
      nodeHeight;

    return (
      <Group
        id={ id }
        width={ nodeWidth }
        height={ activeNodeHeight }
        ref={ node => { this[`diagramNodeVariants${id}`] = node; } }
      >
        <Rect
          x={ 0 }
          y={ 0 }
          width={ nodeWidth }
          height={ activeNodeHeight }
          cornerRadius={ 8 }
          fill={ nodeFill }
          shadowColor="rgba(127,151,168,0.2)"
          shadowOffsetX={ 0 }
          shadowOffsetY={ 8 }
          shadowBlur={ 16 }
          listening={ false }
        />
        <Group
          x={ 0 }
          y={ 0 }
          width={ nodeWidth }
          padding={ 16 }
          listening={ false }
          ref={ node => {
            this.headerWrapper = node;
          } }
        >
          <Text
            x={ 0 }
            y={ 0 }
            width={ nodeWidth }
            padding={ 16 }
            fill={ textFill }
            lineHeight={ 1.2 }
            fontFamily="aktiv-grotesk-extended, sans-serif"
            fontSize={ 14 }
            fontStyle="bold"
            align="left"
            text={ name }
            listening={ false }
            ref={ node => {
              this.header = node;
            } }
          />

          { !isActive && (
            <Text
              x={ 0 }
              y={ this.state.headerHeight - 24 }
              width={ nodeWidth }
              padding={ 16 }
              fill="#001066"
              fontFamily="aktiv-grotesk-extended, sans-serif"
              fontSize={ 12 }
              fontStyle="bold"
              text={ this.variantCopy(childrenNodes) }
            />
          ) }
        </Group>

        <Group
          x={ 12 }
          y={ this.state.headerWrapperHeight - 32 }
          width={ nodeWidth - 24 }
          visible={ isActive }
          ref={ node => { this.childrenWrapper = node; } }
        >
          { children }
        </Group>


        { this.renderButton() }
      </Group>
    );
  }
}

export default DiagramNodeVariants;
