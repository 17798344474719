import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import classnames from 'classnames';

import './style.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

function Accordion({ title, className, children, ...props }) {
  return (
    <Collapsible
      trigger={ title }
      transitionTime={ 200 }
      className={ classnames('Accordion', className) }
      openedClassName={ classnames('Accordion', className) }
      triggerClassName="Accordion__trigger"
      triggerOpenedClassName={ classnames('Accordion__trigger', 'Accordion__trigger--open', className) }
      contentInnerClassName="Accordion__content"
      { ...props }
    >
      { children }
    </Collapsible>
  );
};

Accordion.propTypes = propTypes;

export default Accordion;
