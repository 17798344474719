/* eslint-disable */
import React from 'react';

function FullscreenIcon({ ...props }) {
  return (
    <svg width="42" height="42" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <g fillRule="nonzero" fill="none">
        <circle fill="#FFF" cx="21" cy="21" r="21"/>
        <path d="M23.3333333 14l1.7888889 1.7888889-2.2477778 2.2322222 1.1044445 1.1044445 2.2322222-2.2477778L28 18.6666667V14h-4.6666667zM14 18.6666667l1.7888889-1.7888889 2.2322222 2.2477778 1.1044445-1.1044445-2.2477778-2.2322222L18.6666667 14H14v4.6666667zM18.6666667 28l-1.7888889-1.7888889 2.2477778-2.2322222-1.1044445-1.1044445-2.2322222 2.2477778L14 23.3333333V28h4.6666667zM28 23.3333333l-1.7888889 1.7888889-2.2322222-2.2477778-1.1044445 1.1044445 2.2477778 2.2322222L23.3333333 28H28v-4.6666667z" fill="#00C5B8"/>
      </g>
    </svg>
  )
};

export default FullscreenIcon;
