/* eslint-disable */
import React from 'react';

function CloseIcon({ ...props }) {
  return (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <path d="M2.34315 15.07107L15.07107 2.34315 13.65685.92893.92893 13.65685l1.41422 1.41422zm0-14.14214L.92893 2.34315l12.72792 12.72792 1.41422-1.41422L2.34315.92893z" fill="currentColor" fillRule="nonzero"/>
    </svg>

  );
};

export default CloseIcon;
