// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/svg/tickIcon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://use.typekit.net/oob4opl.css);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".WrappedCheckbox {\n  display: flex;\n  margin-bottom: 12px;\n  font-size: 12px;\n  line-height: 16px; }\n  .WrappedCheckbox__box {\n    flex-shrink: 0;\n    width: 24px;\n    height: 24px;\n    margin-right: 12px;\n    background-color: white;\n    border: 1px solid #e4e5e9;\n    border-radius: 4px; }\n    .WrappedCheckbox__box--checked {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      background-color: #00c5b8;\n      border: none; }\n      .WrappedCheckbox__box--checked::after {\n        content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); }\n    .WrappedCheckbox__box--invalid {\n      border-color: #d63230; }\n", ""]);
// Exports
module.exports = exports;
