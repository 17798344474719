// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/svg/arrow-down.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://use.typekit.net/oob4opl.css);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".Voucher__link {\n  font-family: aktiv-grotesk-extended, sans-serif; }\n\n.Voucher, .Voucher__container {\n  position: relative;\n  width: 100%;\n  max-width: 1230px;\n  margin: 0 auto;\n  padding: 0 20px; }\n  @media only screen and (min-width: 992px) {\n    .Voucher, .Voucher__container {\n      padding: 0 30px; } }\n\n.Voucher {\n  margin-top: 120px;\n  margin-bottom: 114px; }\n  .Voucher__nav-actions {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    place-content: space-between; }\n  .Voucher__text {\n    margin-bottom: 48px;\n    font-size: 14px;\n    line-height: 22px;\n    color: #677491; }\n  .Voucher__link {\n    display: inline-flex;\n    margin-bottom: 24px;\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 24px;\n    color: #001066;\n    text-decoration: none; }\n    .Voucher__link::before {\n      margin-right: 10px;\n      content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n      transform: rotate(90deg); }\n  .Voucher__header {\n    text-align: center; }\n  .Voucher__form {\n    width: 60%;\n    text-align: right; }\n  .Voucher__form-wrapper {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    place-content: center; }\n", ""]);
// Exports
module.exports = exports;
