import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './style.scss';

import getRoutePath from '../../../utils/getRoutePath';
import RightArrow from '../../svg/RightArrow';

const propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

function LinkItem({ item }) {
  return (
    <Link to={ getRoutePath(item.url) } className="Footer__link">
      <span className="Footer__icon">
        <RightArrow />
      </span>

      { item.name }
    </Link>
  );
}

LinkItem.propTypes = propTypes;

export default LinkItem;
