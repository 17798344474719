import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import './style.scss';

import clientManagerInstance from '../../../providers/clientProvider';
import getApiPath from '../../../utils/getApiPath';

import CancerAutocomplete from '../../CancerAutocomplete';
import CategoryTile from './CategoryTile';
import Loader from '../../Loader';
import getRoutePath from '../../../utils/getRoutePath';
import withNotification from '../../_enhancers/withNotification';
import getNotificationContent from '../../../utils/getNotificationContent';

class Categories extends Component {

  constructor(props) {
    super(props);
    const state = {
      isLoading: false,
      categories: null,
      error: null,
    };

    this.state = state;
    this.client = props.client || clientManagerInstance.client;
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories = async (query) => {
    const { slug } = this.props;

    this.setState({
      isLoading: true,
    });

    try {
      const res = await this.client.get(getApiPath('CATEGORIES', slug), { params: { query: query } });

      this.setState({
        categories: res.data,
        isLoading: false,
      });

    } catch (error) {
      this.setState({
        error: error.response ? error.response.statusText : error,
      });

      return error;
    }
  }

  handleInactiveTile = (e) => {
    e.preventDefault();
    this.props.toggleNotification(getNotificationContent('no_mutations'));
  }

  render() {
    const { categories, isLoading } = this.state;

    if (categories) {
      return (
        <>
          <Helmet>
            <title>Jak się leczyć</title>
          </Helmet>

          <section className="Categories">
            <div className="Categories__container">
              <div className="Categories__header">
                <h3 className="Categories__subheading">
                  Znajdź Instrukcję Obsługi Choroby
                </h3>

                <h1 className="Categories__heading">
                  Jak się leczyć
                </h1>
              </div>

              <CancerAutocomplete
                onSearch={ this.getCategories }
              />

              <div className="Categories__list">
                { isLoading ? (
                  <Loader/>
              ):(
                categories.map(category => (
                  <CategoryTile
                    landing
                    key={ category.slug }
                    category={ category.slug }
                    link_to= { getRoutePath('CATEGORIES', category.slug) }
                    handleInactiveTile= { this.handleInactiveTile }
                    inactive= { category.mutations_count === 0 }
                    { ...category }
                  />
                ))
              ) }
              </div>

            </div>
          </section>
        </>
      );
    }

    return <Loader isStrech/>;
  }
}

export default withNotification(Categories);
