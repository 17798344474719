import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import './style.scss';

import clientManagerInstance from '../../providers/clientProvider';

import getApiPath from '../../utils/getApiPath';
import getOptions from '../../utils/getOptions';

import Checkbox from '../Checkbox';
import Button from '../Button';

import Logo from '../svg/Logo';
import CloseIcon from '../svg/CloseIcon';

const propTypes = {
  onFilterChange: PropTypes.func,
  onSelectChange: PropTypes.func,
};

class RefineSearch extends Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);
    this.client = props.client || clientManagerInstance.client;

    const state = {
      filters: [],
      cities: [],
      voivodeships: [],
      activeCity: null,
      activeVoivodeship: null,
      error: null,
      activeFiltersCount: 0,
    };

    this.state = state;
  };

  componentDidMount() {
    const { filtersState } = this.props;

    this.setState({
      activeCity: filtersState.activeCity,
      activeVoivodeship: filtersState.activeVoivodeship,
    });

    this.getFilters();
  }

  getFilters = async () => {
    try {
      const res = await this.client.get(getApiPath('HOSPITALS_FILTER'));

      this.setState({
        filters: res.data.filters,
        cities: res.data.cities,
        voivodeships: res.data.voivodeships,
      });
    } catch (e) {
      this.setState({
        error: e.response ? e.response.statusText : e,
      });

      return e;
    };
  };

  getCitiesByVoivodeship = async (activeVoivodeship) => {
    const params = {
      'voivodeship': activeVoivodeship ? activeVoivodeship.value : null,
    };

    try {
      const res = await this.client.post(getApiPath('HOSPITALS_FILTER_CITES'), params);

      this.setState({
        cities: res.data,
      });
    } catch (e) {
      this.setState({
        error: e.response ? e.response.statusText : e,
      });
    }
  };

  onFilterChange = (value) => {
    this.props.onFilterChange(value);
    this.setActiveFiltersCount(value);
  };

  onVoivodeshipsChange = (selected) => {
    const { activeCity, activeVoivodeship } = this.state;

    if(selected){
      this.props.onSelectChange({ type: 'voivodeships', value: selected.value });
      !activeVoivodeship && this.setActiveFiltersCount(selected);
    } else {
      this.clearSelect('voivodeships');
    }

    activeCity && this.clearSelect('city');

    this.setState({
      activeVoivodeship: selected,
      activeCity: null,
    });

    this.getCitiesByVoivodeship( selected );
  };

  onCitiesChange = (selected) => {
    const { activeCity } = this.state;
    if(selected){
      this.props.onSelectChange({ type: 'city', value: selected.value });
      !activeCity && this.setActiveFiltersCount(selected);
    } else {
      this.clearSelect('city');
    }

    this.setState({
      activeCity: selected,
    });
  };

  clearSelect = (currentType) => {
    this.props.onSelectChange({ type: currentType, value: null });
    this.setActiveFiltersCount();
  };

  setActiveFiltersCount = (filter) => {
    const isPositive = filter && ( filter.value || filter.target.checked);
    const activeFilter = isPositive ? 1 : -1;
    this.setState(prevState => ({
      activeFiltersCount: prevState.activeFiltersCount + activeFilter,
    }));
  }


  render() {
    const { filters, cities, voivodeships, activeVoivodeship, activeCity } = this.state;
    const { activeFilters } = this.props.filtersState;

    const selectedVoivodeship = this.props.filtersState.activeVoivodeship ?
      {
        label: this.props.filtersState.activeVoivodeship,
        value: this.props.filtersState.activeVoivodeship,
      }: activeVoivodeship;

    const selectedCity = this.props.filtersState.activeCity ?
      {
        label: this.props.filtersState.activeCity,
        value: this.props.filtersState.activeCity,
      }: activeCity;

    return (
      <>
        <div className="RefineSearch__header">
          <CloseIcon className="RefineSearch__close" onClick={ this.props.toggleFilter }/>
          <Logo className="RefineSearch__logo" />
        </div>

        <div className="RefineSearch__wrapper">
          <section className="RefineSearch__filters">
            <h3 className="RefineSearch__label">Filtruj wyniki</h3>
            <ul>
              { filters.map(filter => (
                <li key={ filter.id }>
                  <Checkbox
                    id={ filter.id }
                    name={ filter.name }
                    label={ filter.name }
                    tooltip={ filter.tooltip }
                    onChange={ this.onFilterChange }
                    checked={ activeFilters.map(Number).includes(filter.id) }
                  />
                </li>
              )) }
            </ul>
          </section>

          <section className="RefineSearch__voivodeships">
            <h3 className="RefineSearch__label RefineSearch__label--select">Województwo</h3>
            <Select
              className={ 'RefineSearch__select' }
              classNamePrefix={ 'RefineSearch' }
              components={ { IndicatorSeparator: () => null } }
              value={ selectedVoivodeship }
              defaultValue={ selectedVoivodeship && { value: selectedVoivodeship, label: selectedVoivodeship } }
              isClearable={ true }
              onChange={ this.onVoivodeshipsChange }
              options={ getOptions(voivodeships) }
              placeholder="Wybierz województwo"
            />
          </section>

          <section className="RefineSearch__cities">
            <h3 className="RefineSearch__label RefineSearch__label--select">Miasto</h3>
            <Select
              className={ 'RefineSearch__select' }
              classNamePrefix={ 'RefineSearch' }
              components={ { IndicatorSeparator: () => null } }
              value={ selectedCity }
              defaultValue={ selectedCity && { value: selectedCity, label: selectedCity } }
              isClearable={ true }
              onChange={ this.onCitiesChange }
              options={ getOptions(cities) }
              placeholder="Wybierz miasto"
            />
          </section>
        </div>

        <div className="RefineSearch__apply">
          <Button
            className="RefineSearch__submit"
            onClick={ this.props.toggleFilter }
          >
            Zastosuj filtry { this.state.activeFiltersCount > 0 && `(${this.state.activeFiltersCount})` }
          </Button>
        </div>
      </>
    );
  };
}

export default RefineSearch;
