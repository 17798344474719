import React from 'react';
import removeTrailingLetters from '../../../../utils/removeTrailingLetters';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import './style.scss';

function CategoryTile({ name, image_url, hovered_image_url, link_to, inactive, handleInactiveTile, landing }) {
  return (
    <Link
      to={ link_to }
      onClick={ e => { inactive && handleInactiveTile(e); } }
      className={ cn('CategoryTile', landing && 'CategoryTile--landing') }
    >
      <div
        className={ cn('CategoryTile__card', landing && 'CategoryTile__card--landing') }
        style={ { backgroundImage: `url('${image_url}')` } }
        dangerouslySetInnerHTML={ { __html: removeTrailingLetters(name) } }
      />

      <div
        className={ cn('CategoryTile__card', 'CategoryTile__card-secondary', landing && 'CategoryTile__card--landing') }
        style={ { backgroundImage: `url('${hovered_image_url}')` } }
      >
        <p className={ cn('CategoryTile__title', landing && 'CategoryTile__title--landing') }
          dangerouslySetInnerHTML={ { __html: removeTrailingLetters(name) } } />
      </div>
    </Link>
  );
}

export default CategoryTile;
