import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Group } from 'react-konva';

const propTypes = {
  id: PropTypes.string,
  x: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};


class DiagramColumn extends Component {
  static propTypes = propTypes;

  componentDidMount() {
    this.setChildrenPosition();
  }

  componentDidUpdate (prevProps) {
    if(prevProps.activeNode !== this.props.activeNode) {
      this.setChildrenPosition();
    }
  }

  getChildrenHeight = () => {
    const children = this.column.getChildren();

    const childrenHeight = children.reduce((acc, child) => {
      const height = child.getAttr('height');

      acc += height;
      return acc;
    }, 0);

    return childrenHeight;
  }

  setChildrenPosition = () => {
    const children = this.column.getChildren();
    children.reduce((acc, child, index) => (this.setChildPosition(children.length, acc, child, index)), 0);
  }

  setChildPosition = (amount ,acc, child, index) => {
    const { height, width, updateNodePosition, depthIndex, includedNodes, offsetX } = this.props;
    const { id } = child.getAttrs();

    const childrenHeight = this.getChildrenHeight();
    const availableSpace = height - childrenHeight;
    const spacing = availableSpace / (amount + 1 );

    const childWidth = child.getAttr('width');
    const childHeight = child.getAttr('height');
    const childIndex = includedNodes.findIndex(el => el.id === id);

    const childPositionX = (width - childWidth) / 2;
    const childY = includedNodes.filter(el => el.id === id)[0].y_coord;
    const childPositionY = childY ? childY : spacing * (index + 1) + acc;

    child.setAttr('y', childPositionY );
    child.setAttr('x', childPositionX );

    acc += childHeight;

    updateNodePosition && updateNodePosition({
      id: id,
      x: child.getAbsolutePosition().x + offsetX,
      y: child.getAbsolutePosition().y,
      width: childWidth,
      height: childHeight,
      depthIndex: depthIndex,
      isFirst: childIndex === 0,
      isLast: childIndex === includedNodes.length - 1,
    });
    return acc;
  }

  render() {
    const { x, y, id, children } = this.props;

    return (
      <Group
       id={ id }
       x={ x }
       y={ y }
       ref={ node => {
         this.column = node;
       } }
     >
        { children }
      </Group>
    );
  }
}

export default DiagramColumn;
