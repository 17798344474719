import React from 'react';
import cookies from '../../utils/cookieHelper';
import Button from '../Button';
import './style.scss';

class CookiesNotice extends React.Component {
  constructor() {
    super();

    this.state = {
      visible: true,
      modalVisible: false,
    };

    this.cookieName = 'CookiesNoticeMednavi';
    this.expires = 365;
  }

  componentDidMount() {
    const isCookieSet = cookies.get(this.cookieName);

    if (isCookieSet) {
      this.setState({ visible: false });
    }
  }

  onAccept = () => {
    const { cookieName, expires } = this;
    cookies.set(cookieName, true, expires);

    this.setState({ visible: false });
  };

  onModalOpen = () => {
    this.setState({ modalVisible: true });
  };

  onModalClose = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    const { visible } = this.state;

    if (visible) {
      return (
        <div className="CookiesNotice">
          <div className="CookiesNotice__container">
            <div className="CookiesNotice__container-row">
              <p>
                W aplikacji używamy plików cookies wyłącznie niezbędnych do
                funkcjonowania serwisu. W tym celu w plikach cookies
                przechowywane są między innymi informacje o Twojej lokalizacji
                oraz o odwiedzanych podstronach. Jeżeli nie chcesz żeby były one
                wobec Ciebie stosowane, usuń je ze swojej przeglądarki. W takim
                przypadku strona może nie działać prawidłowo.
              </p>
              <p>
                Administratorem Twoich danych osobowych jest mednavi.pl.
              </p>
              <p>
                Twoje dane przetwarzamy w następujących celach: zapewnienia
                funkcjonowania strony (art. 6 ust. 1 lit. f RODO), archiwalnym i
                dowodowym (art. 6 ust. 1 lit. f RODO), ustalenia, dochodzenia
                lub obrony przed roszczeniami (art. 6 ust. 1 lit. f RODO),
                wykonywania obowiązków wynikających z RODO (art. 6 ust. 1 lit. f
                RODO, art. 6 ust. 1 lit. c RODO), wykorzystywania cookies (art.
                6 ust. 1 lit. f RODO).
              </p>
              <p>
                Przysługuje Ci prawo do dostępu do swoich danych osobowych,
                sprostowania danych osobowych, usunięcia danych osobowych,
                ograniczenia przetwarzania danych osobowych, sprzeciwu wobec
                przetwarzania danych osobowych, prawo do przenoszenia danych, a
                także prawo do wniesienia skargi do Prezesa Urzędu Ochrony
                Danych Osobowych jeśli w Twojej ocenie przetwarzamy dane w
                sposób nieprawidłowy.
              </p>
            </div>

            <Button className="CookiesNotice__accept" onClick={ this.onAccept }>
              Akceptuję
            </Button>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default CookiesNotice;
