import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Group, Rect, Text } from 'react-konva';

const propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
};


class DiagramRoot extends Component {
  static propTypes = propTypes;

  setActive = () => {
    const { id, setActiveNode, scrollToNode } = this.props;

    setActiveNode(id, 0);
    scrollToNode(id);
  }

  render() {
    const { id, name, description } = this.props;

    const rootHeight = 120;
    const rootWidth = 260;

    return (
      <Group
       id={ id }
       width={ rootWidth }
       height={ rootHeight }
       preventDefault={ false }
       onClick={ this.setActive }
       onTap={ this.setActive }
       onMouseEnter={ () => {
        document.body.style.cursor = 'pointer';
       } }
       onMouseLeave={ () => {
         document.body.style.cursor = 'default';
       } }
     >
        <Rect
         x={ 0 }
         y={ 0 }
         width={ rootWidth }
         height={ rootHeight }
         fill="#fff"
         cornerRadius={ 8 }
         shadowColor="rgba(127,151,168,0.2)"
         shadowOffsetX={ 0 }
         shadowOffsetY={ 8 }
         shadowBlur={ 16 }
         preventDefault={ false }
       />

        <Text
         x={ 10 }
         y={ 0 }
         listening={ false }
         width={ rootWidth - 20 }
         height={ rootHeight }
         padding={ 18 }
         fill="#001066"
         fontFamily="aktiv-grotesk-extended, sans-serif"
         fontSize={ 14 }
         fontStyle="bold"
         align="center"
         verticalAlign="middle"
         text={ name }
       />

        <Text
          width={ rootWidth - 20 }
          height={ rootHeight }
          listening={ false }
          padding={ 20 }
          fontSize={ 12 }
          fontFamily="aktiv-grotesk"
          fill="#677491"
          align="center"
          verticalAlign="bottom"
          text={ description }
        />
      </Group>
    );
  }
}

export default DiagramRoot;
