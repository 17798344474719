import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Link } from 'react-router-dom';

import './style.scss';

import clientManagerInstance from '../../../providers/clientProvider';
import getRoutePath from '../../../utils/getRoutePath';
import getApiPath from '../../../utils/getApiPath';

import Loader from '../../Loader';

class TipCategory extends Component {

  constructor(props) {
    super(props);
    const state = {
      content: null,
      error: null,
    };

    this.state = state;
    this.client = props.client || clientManagerInstance.client;
  }

  componentDidMount() {
    this.getTipCategory();
  }

  getTipCategory = async () => {
    const { slug } = this.props.match.params;
    try {
      const res = await this.client.get(getApiPath('CONTENT_TIPS_CATEGORIES', slug));

      this.setState({
        content: res.data.content,
      });

    } catch (error) {
      this.setState({
        error: error.response ? error.response.statusText : error,
      });

      return error;
    }
  }

  renderArticleItem = article => {
    return (
      <div className="TipCategory__item" key={ article.slug }>

        <Link
          className="TipCategory__item-link"
          to={ {
            pathname: getRoutePath('TIP', article.slug),
            state: {
              prevPath: window.location.pathname,
            },
          } }
        >
          <picture
            style={ { backgroundImage: `url(${article.image_url})` } }
            className="TipCategory__item-thumb"
          />
        </Link>

        <Link
          className="TipCategory__item-link"
          to={ getRoutePath('TIP', article.slug) }
        >
          <h3 className="TipCategory__item-label">{ article.title }</h3>
        </Link>
      </div>
    );
  }

  render() {
    const { content, error } = this.state;

    if (error) {
      return <Redirect to={ getRoutePath('TIP') } />;
    }

    if (content) {
      const { heading, subheading, category } = content;

      return (
        <>
          <Helmet>
            <title>{ process.env.REACT_APP_PAGE_TITLE } | { category.name }</title>
          </Helmet>

          <section className="TipCategory">

            <div className="TipCategory__container">

              <Link to={ getRoutePath('TIPS') } className="TipCategory__link" >
                Wróć
              </Link>
              <h1 className="TipCategory__heading">
                { heading }
              </h1>

              <h3 className="TipCategory__subheading">
                { subheading }
              </h3>

              <div className="TipCategory__categories">
                <section className="TipCategory__category">
                  <h2 className="TipCategory__category-name">
                    { category.name }
                  </h2>
                </section>

                <div className="TipCategory__items">
                  { category.articles.length > 0
                    && category.articles.map(article => this.renderArticleItem(article)) }
                </div>
              </div>
            </div>

          </section>
        </>
      );
    }

    return <Loader isStrech/>;
  }
}

export default TipCategory;
