import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import clientManagerInstance from '../../../providers/clientProvider';
import getApiPath from '../../../utils/getApiPath';
import Button from '../../Button';
import NotificationModal from '../../NotificationModal';
import withContent from '../../_enhancers/withContent';
import withNotification from '../../_enhancers/withNotification';
import Faq from '../../_landingComponents/FaqList';
import Hero from '../../_landingComponents/Hero';
import Mission from '../../_landingComponents/Mission';
import MutationCategories from '../../_landingComponents/MutationCategories';
import Publications from '../../_landingComponents/Publications';
import Rank from '../../_landingComponents/Rank';
import './style.scss';

const propTypes = {
  content: PropTypes.shape({
    hero: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      link_label: PropTypes.string.isRequired,
      notice: PropTypes.string,
      background_image_urls: PropTypes.shape({
        small: PropTypes.string.isRequired,
        medium: PropTypes.string.isRequired,
        large: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    modules: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired, //richtext
      link_label: PropTypes.string.isRequired,
      image_url: PropTypes.string.isRequired,
    })).isRequired,
    mission: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      link_label: PropTypes.string.isRequired,
    }).isRequired,
    pricing: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      plans: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired, //float
        features: PropTypes.arrayOf(PropTypes.shape({
          description: PropTypes.string.isRequired,
          is_included: PropTypes.bool.isRequired,
        })),
      })).isRequired,
    }),
    faq: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({
        question: PropTypes.string.isRequired,
        answer: PropTypes.string.isRequired, //richtext
      })),
      link_label: PropTypes.string.isRequired,
    }).isRequired,
    features: PropTypes.arrayOf(PropTypes.shape({
      image_url: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })).isRequired,
    publications: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      link_label: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
};

class Home extends React.Component {
  constructor(props) {
    super(props);
    const state = {
      isModalOpen: false,
      isNoticeModalOpen: false,
      isLoading: false,
      isPaymentProcess: false,
      categories: null,
      error: null,
    };

    this.state = state;
    this.client = props.client || clientManagerInstance.client;
  }

  componentDidMount() {
    this.getCategories();

    if (this.props.content.hero.notice) {
      this.setState({ isNoticeModalOpen: true });
    }
  }

  getCategories = async (query) => {
    const { slug } = this.props;

    this.setState({
      isLoading: true,
    });

    try {
      const res = await this.client.get(getApiPath('CATEGORIES', slug), { params: { query: query } });

      this.setState({
        categories: res.data,
        isLoading: false,
      });

    } catch (error) {
      this.setState({
        error: error.response ? error.response.statusText : error,
      });

      return error;
    }
  }
 
  renderNoticeModal = () => {
    return (
      <NotificationModal isOpen={ true }>
        <div class="Home__content">
          <div
          class="Home__modal-notification"
          dangerouslySetInnerHTML={ { __html: this.props.content.hero.notice } }
          />

          <Button
            onClick={ () => this.setState({ isNoticeModalOpen: false }) }>
            Zamknij
          </Button>
        </div>
      </NotificationModal>
    );
  }

  render() {
    const { content } = this.props;
    const { hero, faq, meta, mission, ranks } = content;

    return (
      <>
        <Helmet>
          <title>Twoja medyczna nawigacja</title>
          <meta
            name="description"
            content={
              meta.description ||
              'Jak i gdzie leczyć raka - Mednavi - Twoja medyczna nawigacja'
            }
          />
          <meta
            property="og:title"
            content={ `${process.env.REACT_APP_PAGE_TITLE} | Twoja medyczna nawigacja` }
          />
          <meta property="og:description" content={ meta.description } />
          <meta property="og:image" content={ meta.image_url } />
          <meta property="og:image:secure_url" content={ meta.image_url } />
          <meta property="og:image:width" content="600px" />
          <meta property="og:image:height" content="314px" />

          <meta
            name="twitter:title"
            content={ `${process.env.REACT_APP_PAGE_TITLE} | Twoja medyczna nawigacja` }
          />
          <meta name="twitter:description" content={ meta.description } />
          <meta name="twitter:image" content={ meta.image_url } />
        </Helmet>

        <Hero { ...hero } />

        <MutationCategories
          categories={ this.state.categories }
          isLoading={ this.state.isLoading }
          toggleNotification={ this.props.toggleNotification }
          getCategories={ this.getCategories }
        />

        <Rank { ...ranks } />

        <Mission { ...mission } />

        <Faq { ...faq } />

        <Publications { ...content } />

        { this.state.isNoticeModalOpen && this.renderNoticeModal() }
      </>
    );
  }
}

Home.propTypes = propTypes;

export { Home };
export default withNotification(withRouter(withContent(Home, getApiPath('CONTENT_HOME'))));
