import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './style.scss';

import cn from 'classnames';
import { truncate } from 'lodash';

import getRoutePath from '../../../utils/getRoutePath';
import getStripString from '../../../utils/getStripString';

import placeholder from '../../../assets/images/jpg/hospital-placeholder.jpg';

const propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  address: PropTypes.string,
  address2: PropTypes.string,
  phone: PropTypes.string,
  url: PropTypes.string,
  voivodeship: PropTypes.string,
  description: PropTypes.string,
  refunded: PropTypes.bool,
};

function HospitalItem({ hospital, filters, activeNode, activeColumn, onClick }) {
  const { photo_url, name, address, address2, description, bcu, slug } = hospital;
  const className = cn('HospitalItem', bcu && 'HospitalItem--highlighted');
  const img = photo_url ? photo_url : placeholder;

  return (
    <li className={ className }>
      <img src={ img } alt={ getStripString(name) } className="HospitalItem__image"/>

      <div className="HospitalItem__info">
        <Link
          to={ {
            pathname: getRoutePath('HOSPITAL', slug),
            state: {
              prevPath: window.location.pathname,
              filters: filters,
              activeNode: activeNode,
              activeColumn: activeColumn,
            },
          } }
          onClick={ onClick }
          className="HospitalItem__heading-link"
        >
          <h3 className="HospitalItem__name">{ getStripString(name) }</h3>
        </Link>

        <p className="HospitalItem__address">{ address }, { address2 }</p>

        <p className="HospitalItem__description">
          <span className="HospitalItem__text">
            { truncate(getStripString(description), {
              'length': 100,
              'separator': ' ',
            }) }
          </span>
          <Link
            to={ {
              pathname: getRoutePath('HOSPITAL', slug),
              state: {
                prevPath: window.location.pathname,
                filters: filters,
                activeNode: activeNode,
                activeColumn: activeColumn,
              },
            } }
            onClick={ onClick }
            className="HospitalItem__link"
          >
            więcej
          </Link>
        </p>

      </div>
    </li>
  );
}

HospitalItem.propTypes = propTypes;

export default HospitalItem;
