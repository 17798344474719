import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';

import './style.scss';

import getApiPath from '../../../utils/getApiPath';
import removeTrailingLetters from '../../../utils/removeTrailingLetters';
import clientManagerInstance from '../../../providers/clientProvider';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Loader from '../../Loader';

import StoryList from '../../_landingComponents/StoryList';

import ZoomOutIcon from '../../svg/ZoomOutIcon';
import FacebookIcon from '../../svg/FacebookShareIcon';
import InstagramIcon from '../../svg/InstagramShareIcon';
import ReactTooltip from 'react-tooltip';
import LetterIcon from '../../svg/LetterShareIcon';

import Button from '../../Button';
import withNotification from '../../_enhancers/withNotification';
import getNotificationContent from '../../../utils/getNotificationContent';
import getRoutePath from '../../../utils/getRoutePath';


const propTypes = {
  slug: PropTypes.string.isRequired,
};

class Story extends Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);
    this.client = props.client || clientManagerInstance.client;

    const state = {
      isLoading: false,
      content: null,
      isHovered: false,
      error: null,
    };

    this.state = state;
  }

  componentDidMount() {
    this.getStory(this.props.slug);
  }

  componentDidUpdate(prevProps) {
    if(this.props.slug !== prevProps.slug) {
      this.setState({ isLoading: true });
      this.getStory(this.props.slug);
    }
  }

  getStory = async (slug) => {
    try {
      const res = await this.client.get(getApiPath('CONTENT_STORY', slug));

      this.setState({
        content: res.data.content,
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        error: e.response ? e.response.statusText : e,
      });
    }
  }

  handleHover = () => {
    this.setState(prevState => ({ isHovered: !prevState.isHovered }));
  }

  renderStory = () => {
    const { content } = this.state;
    const url = window.location.href;
    const facebookUrl = `https://www.facebook.com/sharer.php?u=${url}`;
    const twitterUrl = `https://twitter.com/share?url=${url}&text=${`Mednavi || Historia ${content.full_name}`}`;

    return (
      <>
        <Helmet>
          <title>Historia { content.full_name }</title>

          <meta property="og:title" content={ `Historia ${content.full_name}` } />
          <meta property="og:type" content="website" />
          <meta property="og:image" content={ content.avatar_url } />
          <meta property="og:image:secure_url" content={ content.avatar_url } />
          <meta property="og:image:width" content="600px" />
          <meta property="og:image:height" content="314px" />

          <meta name="twitter:title" content={ `Historia ${content.full_name}` } />
          <meta name="twitter:image" content={ content.avatar_url } />
        </Helmet>
        <article className="Story">
          <div className="Story__nav-actions">
            <Link
                to={ { pathname: getRoutePath('STORIES') } }
                className="Story__link"
              >
                Wróć
            </Link>
            <div className="Story__social-media">
              <div className={ `Story__sharing ${!this.state.isHovered ? 'Story__sharing--hidden' : ''}` }>
                <Button
              className="Story__button"
              icon
              href={ twitterUrl }
              target="_blank"
              onClick={ this.handleHover }
            >
                  <InstagramIcon className="Story__icon" data-tip="Udostępnij na Twitterze" />
                  <ReactTooltip place="top" type="light" />
                </Button>
                <Button
              className="Story__button"
              href={ facebookUrl }
              target="_blank"
              onClick={ this.handleHover }
              icon
            >
                  <FacebookIcon className="Story__icon" data-tip="Udostępnij na Facebooku" />
                </Button>
                <CopyToClipboard text={ window.location.href }>
                  <Button
              className="Story__button"
              icon
              onClick={ () => {
                this.props.toggleNotification(getNotificationContent('story_link_copied'));
                this.handleHover();
              } }
            >
                    <LetterIcon className="Story__icon" data-tip="Skopiuj link" />
                  </Button>
                </CopyToClipboard>
              </div>
              <Button
              className="Story__button Story__button--margined"
              onClick={ this.handleHover }
              icon
            >
                <ZoomOutIcon/>
              </Button>
            </div>
          </div>
          <div className="Story__bio">
            <picture
              className="Story__bio-avatar"
              style={ { backgroundImage: `url(${content.avatar_url})` } }
            />

            <h1 className="Story__bio-name">{ content.full_name }</h1>

            <h3 className="Story__bio-position">{ content.position }</h3>

            <h3  className="Story__bio-hospital">{ content.position2 }</h3>
          </div>

          <div
            className="Story__text"
            dangerouslySetInnerHTML={ { __html: removeTrailingLetters(content.text) } }
          />

          { content.suffix && (
            <div className="Story__annotation">
              <img
                className="Story__annotation-image"
                src={ content.source_image_url }
                alt=""
              />
              <div
                className="Story__annotation-text"
                dangerouslySetInnerHTML={ { __html: removeTrailingLetters(content.suffix) } }
              />
            </div>
          ) }
        </article>

        <StoryList />
      </>
      );
  }

  render() {
    const { content, isLoading } = this.state;

    return (
      content && !isLoading ? this.renderStory() : <Loader />
    );
  }
}

export default withRouter(withNotification(Story));
