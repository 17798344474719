import { pick } from 'lodash';

import clientManagerInstance from '../clientProvider/index';
import storageProviderInstance from '../storageProvider/index';
import cookie from '../../utils/cookieHelper';
import getApiPath from '../../utils/getApiPath/index';

const AUTH_HEADER_NAMES = ['access-token', 'uid', 'client'];

class AuthProvider {
  constructor({ onUserChange, clientManager, storageProvider, onNoUser }) {
    this.onUserChange = onUserChange;
    this.onNoUser = onNoUser;
    this.clientManager = clientManager || clientManagerInstance;
    this.storageProvider = storageProvider || storageProviderInstance;
    this.clientManager.addResponseInterceptor(this.authResponseInterceptorParams);

    this.initializeUser();
  };

  isAuthorized = false;

  static allAuthHeadersPresent(headers = {}) {
    return AUTH_HEADER_NAMES.every(key => !!headers[key]);
  }

  authResponseInterceptorParams = [
    (response) => {
      if (response && response.headers) {
        const authHeaders = pick(response.headers, AUTH_HEADER_NAMES);
        AuthProvider.allAuthHeadersPresent(authHeaders) ? this.setAuthData(authHeaders) : this.removeAuthData();
      }
      return response;
    },
    (error) => {
      if (error && error.response && error.response.status === 401) {
        this.removeAuthData();
      }
      return Promise.reject(error);
    },
  ];

  initializeUser = async() => {
    const storedHeaders = await this.storageProvider.getAuthItem();

    if(AuthProvider.allAuthHeadersPresent(storedHeaders)) {
      cookie.set('isAuthenticated', true);
      this.clientManager.addClientHeaders(storedHeaders);
      this.retrieveCurrentUser();
    } else {
      this.onNoUser();
      this.storageProvider.clearAuthItem();
    }
  };

  retrieveCurrentUser = async () => {
    const res = await this.clientManager.client.get(getApiPath('CURRENT_USER'));
    this.onUserChange(res.data);
  };

  removeUser = () => {
    this.clientManager.removeClientHeaders(AUTH_HEADER_NAMES);
    this.onUserChange(null);
  };

  setAuthData = (authHeaders) => {
    cookie.set('isAuthenticated', true);
    this.storageProvider.putAuthItem(authHeaders);
    this.clientManager.addClientHeaders(authHeaders);

    if (!this.isAuthorized) {
      this.retrieveCurrentUser();
    }
    this.isAuthorized = true;
  };

  removeAuthData = () => {
    this.storageProvider.clearAuthItem();
    this.removeUser();
    this.isAuthorized = false;
  };

  signOut = () => {
    cookie.set('isAuthenticated', false);
    this.clientManager.client.delete(getApiPath('SIGN_OUT'));
    this.removeAuthData();
  }
}

export { AUTH_HEADER_NAMES, AuthProvider };
