import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Group, Rect, Text } from 'react-konva';
import { truncate } from 'lodash/string';
import { withRouter } from 'react-router';

import getStripString from '../../../utils/getStripString';
import getRoutePath from '../../../utils/getRoutePath';

const propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  hospitals: PropTypes.number,
};


class DiagramNode extends Component {
  static propTypes = propTypes;

  constructor(props){
    super(props);

    const state = {
      isActive: false,
      headerWrapperHeight: 0,
      hospitalsWidth: 0,
      nodeHeight: 124,
      nodeWidth: 311,
    };

    this.state = state;
  }

  componentDidMount() {
    const { isWrapped } = this.props;
    const { nodeWidth } = this.state;

    this.setState({
      headerWrapperHeight: this.wrapper.getClientRect().height,
      hospitalsWidth: this.hospitals.getTextWidth(),
      nodeWidth: isWrapped ? nodeWidth - 24: nodeWidth,
    });
  }

  showDetails = () => {
    const { id, isWrapped, mutation, category, slug, history, setActiveNode,
      scrollToNode, activeNode, activeColumn, activeCategory, column } = this.props;

    if (!this.state.isActive || isWrapped) {
      history.push({ pathname: getRoutePath('THERAPY', [category, mutation, slug]),
        state: { activeNode, activeColumn, activeCategory } });
    } else {
      setActiveNode(id, column);
      scrollToNode(id);
    }
  }

  componentDidUpdate() {
    if(this.props.id === this.props.activeNode && !this.state.isActive ) {
      this.setState({
        isActive: true,
      });
    } else if(this.props.id !== this.props.activeNode && this.state.isActive) {
      this.setState({
        isActive: false,
      });
    }
  }

  renderDescription = () => {
    const { headerWrapperHeight, nodeWidth } = this.state;
    const description = truncate(getStripString(this.props.description || ''), { 'length': 150 });
    return (
      <Group
        y={ headerWrapperHeight - 16 }
        visible={ false }
      >
        <Text
          width={ nodeWidth }
          padding={ 16 }
          fill="#fff"
          fontSize={ 12 }
          fontFamily="aktiv-grotesk, sans-serif"
          text={ description }
        />
      </Group>
    );
  }

  hospitalsSuffix = (count) => {
    const dividedCount = count % 10;

    if(count === 1) { return 'placówka'; }
    else if((count < 10 || count > 20) && dividedCount > 1 && dividedCount < 5) { return 'placówki'; }
    else return 'placówek';
  };

  renderButton = () => {
    const { isActive, nodeHeight, nodeWidth } = this.state;
    const activeNodeHeight = isActive ? nodeHeight * 1.5 : nodeHeight;
    const text = isActive || this.props.isWrapped ? 'Przejdź do terapii' : 'Dowiedz się więcej';

    return (
      <Group
        x={ 0 }
        y={ activeNodeHeight - 40 }
        width={ nodeWidth }
        height={ 40 }
        preventDefault={ false }
        onClick={ this.showDetails }
        onTap={ this.showDetails }
        onMouseEnter={ () => {
          document.body.style.cursor = 'pointer';
        } }
        onMouseLeave={ () => {
          document.body.style.cursor = 'default';
        } }
      >
        <Rect
          x={ 0 }
          y={ 0 }
          width={ nodeWidth }
          height={ 10 }
          fill="#20C5D8"
          stroke="#20C5D8"
          strokeWidth={ 1 }
          preventDefault={ false }
        />
        <Rect
          x={ 0 }
          y={ 1 }
          width={ nodeWidth }
          height={ 40 }
          fill="#20C5D8"
          cornerRadius={ 8 }
          stroke="#20C5D8"
          strokeWidth={ 1 }
          preventDefault={ false }
        />
        <Text
          x={ 0 }
          y={ 0 }
          height={ 40 }
          width={ nodeWidth }
          fill="#fff"
          fontFamily="aktiv-grotesk-extended, sans-serif"
          fontSize={ 12 }
          fontStyle="bold"
          align="center"
          verticalAlign="middle"
          text={ text }
        />
      </Group>
    );
  }

  render() {
    const { id, name,  hospitals } = this.props;
    const { nodeHeight, nodeWidth } = this.state;
    const isActive = this.props.id === this.props.activeNode;

    const nodeFill = isActive ? '#001066' : '#fff';
    const textFill = isActive ? '#fff' : '#001066';
    const activeNodeHeight = isActive ? nodeHeight * 1.5 : nodeHeight;

    return (
      <Group
        id={ id }
        width={ nodeWidth }
        height={ activeNodeHeight }
        className="DiagramNode"
        ref={ node => { this[`diagramNode${id}`] = node; } }
      >
        <Group
          listening={ false }
        >
          <Rect
            x={ 0 }
            y={ 0 }
            width={ nodeWidth }
            height={ activeNodeHeight }
            fill={ nodeFill }
            cornerRadius={ 8 }
            shadowColor="rgba(127,151,168,0.2)"
            shadowOffsetX={ 0 }
            shadowOffsetY={ 8 }
            shadowBlur={ 16 }
            preventDefault={ false }
          />

          <Group
            x={ 0 }
            y={ 0 }
            width={ nodeWidth }
            padding={ 16 }
            ref={ node => {
              this.wrapper = node;
            } }
          >
            <Text
              listening={ false }
              x={ 0 }
              y={ 0 }
              width={ nodeWidth }
              padding={ 16 }
              fill={ textFill }
              textHeight={ 18 }
              fontFamily="aktiv-grotesk-extended, sans-serif"
              fontSize={ 14 }
              fontStyle="bold"
              align="left"
              wrap="word"
              text={ name }
              ref={ node => {
                this.header = node;
              } }

            />

            <Text
              listening={ false }
              y={ 44 }
              padding={ 16 }
              fill="#00C5B8"
              fontFamily="aktiv-grotesk, sans-serif"
              fontSize={ 12 }
              fontStyle="bold"
              text={ `${hospitals} ${this.hospitalsSuffix(hospitals)} ` }
              ref={ node => {
                this.hospitals = node;
              } }
            />

            <Text
              listening={ false }
              x={ this.state.hospitalsWidth }
              y={ 44 }
              padding={ 16 }
              fill="#677491"
              fontFamily="aktiv-grotesk, sans-serif"
              fontSize={ 12 }
              text={ `${this.hospitalsSuffix(hospitals) === 'placówki' ? 'oferują' : 'oferuje'} tę terapię` }
            />

          </Group>

          { this.renderDescription() }

        </Group>

        { this.renderButton() }
      </Group>
    );
  }
}

export default withRouter(DiagramNode);
