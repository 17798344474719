import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './style.scss';

import withContent from '../../_enhancers/withContent';
import getApiPath from '../../../utils/getApiPath';
import getRoutePath from '../../../utils/getRoutePath';

import Button from '../../Button';

const propTypes = {
  content: PropTypes.shape({
    stories: PropTypes.arrayOf(PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      full_name: PropTypes.string.isRequired,
    })),
  }),
};

function StoryList({ content }) {
  const { stories } = content;

  return (
    <section className="StoryList">
      <div className="StoryList__container">
        <h2 className="StoryList__heading">
          { content.heading }
        </h2>

        <h3 className="StoryList__subheading">
          { content.subheading }
        </h3>

        <ul className="StoryList__wrapper">
          { stories.slice(0, 4).map(story => (
            <li key={ story.slug } className="StoryList__item">
              <Link
                className="StoryList__item-link"
                to={ getRoutePath('STORY', story.slug) }
              >
                <picture
                  style={ { backgroundImage: `url(${story.avatar_url})` } }
                  className="StoryList__item-thumb"
                />
                <h3 className="StoryList__item-label">{ story.full_name }</h3>
              </Link>
            </li>
          )) }
        </ul>

        <Button
          secondary
          className="StoryList__button"
          to={ getRoutePath('STORIES') }
        >
          Wszystkie historie
        </Button>
      </div>
    </section>
  );
}

StoryList.propTypes = propTypes;

export default withContent(StoryList, getApiPath('CONTENT_STORIES'));
