/* eslint-disable */
import React from 'react';

function FacebookIcon({ ...props }) {
  return (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" { ...props } >
      <g fill="none" fillRule="evenodd">
        <path d="M17.134 24.874V16.17h2.403l.319-3h-2.722l.004-1.5c0-.783.075-1.202 1.199-1.202h1.502v-3h-2.404c-2.886 0-3.902 1.455-3.902 3.902v1.801h-1.8v3h1.8v8.704h3.601z" fill="currentColor"/>
      </g>
    </svg>
  );
}

export default FacebookIcon;
