import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import './style.scss';

import withContent from '../../_enhancers/withContent';
import getApiPath from '../../../utils/getApiPath';

import Button from '../../Button';
import getRoutePath from '../../../utils/getRoutePath';
import Mission from '../../_landingComponents/Mission';
import Team from '../../_landingComponents/Team';
import Press from '../../_landingComponents/Press';
import Council from '../../_landingComponents/Council';

const propTypes = {
  content: PropTypes.shape({
    story: PropTypes.shape({
      id: PropTypes.number.isRequired,
      lead: PropTypes.string.isRequired,
      avatar_url: PropTypes.string.isRequired,
      full_name: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      link_label: PropTypes.string.isRequired,
    }).isRequired,
    mission: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
    team: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      subheading: PropTypes.string.isRequired,
      people: PropTypes.arrayOf(PropTypes.shape({
        avatar_url: PropTypes.string.isRequired,
        full_name: PropTypes.string.isRequired,
        position: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired, //richText
      })).isRequired,
    }).isRequired,
    council: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      subheading: PropTypes.string.isRequired,
      is_visible: PropTypes.bool.isRequired,
      people: PropTypes.arrayOf(PropTypes.shape({
        avatar_url: PropTypes.string.isRequired,
        full_name: PropTypes.string.isRequired,
        specialization: PropTypes.string.isRequired,
      })).isRequired,
    }),
    press: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      logos: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        image_url: PropTypes.string.isRequired,
      })),
    }).isRequired,
  }).isRequired,
};

function About({ content }) {
  const { story, mission, team, council, meta, press } = content;

  return (
    <>
      <Helmet>
        <title>O nas</title>
        <meta name="description" content={ meta.description } />

        <meta property="og:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | O nas` } />
        <meta property="og:description" content={ meta.description } />
        <meta property="og:image" content={ meta.image_url } />
        <meta property="og:image:secure_url" content={ meta.image_url } />
        <meta property="og:image:width" content="600px" />
        <meta property="og:image:height" content="314px" />

        <meta name="twitter:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | O nas` } />
        <meta name="twitter:description" content={ meta.description } />
        <meta name="twitter:image" content={ meta.image_url } />

        <script type="application/ld+json">
          { `
            {
              "@context": "http://schema.org",
              "@type": "AboutPage"
            }
          ` }
        </script>
      </Helmet>

      <section className="About__story">

        <q className="About__quote">{ story.lead }</q>

        <figure className="About__bio">
          <img
            className="About__avatar"
            src={ story.avatar_url }
            alt={ story.full_name }
          />

          <figcaption className="About__author">
            <span className="About__fullname">{ story.full_name }</span>
            <span className="About__position">{ story.position }</span>
          </figcaption>
        </figure>

        <Button
          large
          className="About__link"
          to={ getRoutePath('STORY', story.slug) }
        >
          { story.link_label }
        </Button>
      </section>

      <Mission { ...mission }/>

      <section className="About__team">
        <h2 className="About__team-heading">{ team.heading }</h2>

        <h3 className="About__team-subheading">
          { team.subheading }
        </h3>

        <Team { ...team }/>
      </section>

      { council.is_visible && (<Council { ...content }/>) }

      { press.logos.length > 0 && <Press press={ press } /> }
    </>
  );
}

About.propTypes = propTypes;

export { About };
export default withContent(About, getApiPath('CONTENT_ABOUT'));
