import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import './style.scss';

import clientManagerInstance from '../../../providers/clientProvider';
import getApiPath from '../../../utils/getApiPath';
import { Link } from 'react-router-dom';

import CancerAutocomplete from '../../CancerAutocomplete';
import MutationTile from './MutationTile';
import Loader from '../../Loader';
import getRoutePath from '../../../utils/getRoutePath';
import { withRouter } from 'react-router-dom';

class Category extends Component {

  constructor(props) {
    super(props);
    const state = {
      isLoading: false,
      categories: null,
      error: null,
    };

    this.state = state;
    this.client = props.client || clientManagerInstance.client;
  }

  componentDidMount() {
    this.getCategory();
  }

  getCategory = async (query) => {
    const { slug } = this.props;

    this.setState({
      isLoading: true,
    });

    try {
      const res = await this.client.get(getApiPath('CATEGORIES', slug), { params : { query: query } });

      this.setState({
        category: res.data[0],
        isLoading: false,
      });

    } catch (error) {
      this.setState({
        error: error.response ? error.response.statusText : error,
      });

      return error;
    }
  }

  render() {
    const { category, isLoading } = this.state;

    if (category) {
      return (
        <>
          <Helmet>
            <title>{ category.name }</title>
          </Helmet>

          <section className="Category">
            <div className="Category__container">
              <div className="Category__header">
                <Link
                    to={ getRoutePath('CATEGORIES') }
                    className="Category__back"
                  >
                    Wróć
                </Link>
                <h3 className="Category__subheading">
                  Znajdź Instrukcję Obsługi Choroby
                </h3>

                <h1 className="Category__heading">
                  { category.name }
                </h1>
              </div>

              <CancerAutocomplete
                category={ category.slug }
                onSearch={ this.getCategory }
              />

              <div className="Category__list">
                { isLoading ? (
                  <Loader/>
              ):(
                category.mutations.map(mutation => (
                  <MutationTile
                    key={ mutation.slug }
                    category={ category.slug }
                    slug= { mutation.slug }
                    landing
                    heigh
                    { ...mutation }
                  />
                ))
              ) }
              </div>

            </div>
          </section>
        </>
      );
    }

    return <Loader isStrech/>;
  }
}

export default withRouter(Category);
