// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/svg/tickIcon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://use.typekit.net/oob4opl.css);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".Checkbox {\n  position: relative;\n  display: flex;\n  align-items: center;\n  margin-bottom: 12px;\n  font-size: 14px;\n  line-height: 24px;\n  color: #677491; }\n  .Checkbox::before {\n    width: 16px;\n    height: 16px;\n    margin-right: 12px;\n    background-color: #fff;\n    border: 1px solid #e3e9ed;\n    border-radius: 4px;\n    content: ''; }\n  .Checkbox:hover {\n    cursor: pointer; }\n  .Checkbox__input {\n    display: none; }\n  .Checkbox__input:checked ~ .Checkbox__label::before {\n    position: absolute;\n    top: 50%;\n    left: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 16px;\n    height: 16px;\n    background-color: #00c5b8;\n    border: 1px solid #00c5b8;\n    border-radius: 4px;\n    content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    transform: translate(0, -50%); }\n  .Checkbox__tooltip {\n    margin-left: 12px;\n    cursor: pointer; }\n", ""]);
// Exports
module.exports = exports;
