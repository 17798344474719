import React from 'react';
import Modal from 'react-modal';

import './style.scss';

const modalStyles = {
  content: {
    position: 'relative',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '40%',
    transform: 'translate(-50%, -50%)',
    zIndex: 999,
    boxShadow: '0 4px 8px 0 #6e7699',
    margin: 0,
  },
};

function NotificationModal({ isOpen, children, closeModal, ...props }) {
  return (
    <Modal
        isOpen={ true }
        onRequestClose={ closeModal }
        className="Modal__wrapper"
        style={ modalStyles }
        ariaHideApp={ false }
        { ...props }
      >
      { children }
    </Modal>
  );
}

export default NotificationModal;
