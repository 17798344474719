import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import './style.scss';

import getRoutePath from '../../utils/getRoutePath';

const propTypes = {
  error: PropTypes.string,
  isTouched: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  extra: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  className: PropTypes.string,
  large: PropTypes.bool,
  search: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
  ]),
};

const defaultProps = {
  error: '',
  isTouched: false,
  label: '',
  type: 'text',
  placeholder: '',
  extra: {
    text: '',
    url: '',
  },
  className: '',
  large: false,
  search: false,
};

class WrappedField extends React.Component {
  render() {
    const { error, isTouched, name, label, extra, className, type, search, large, children, ...rest } = this.props;

    const wrapperClasses = cn([
      'WrappedField',
      { 'WrappedField--invalid': error && isTouched },
      { 'WrappedField--search': search },
      className,
    ]);
    const fieldClasses = cn([
      'WrappedField__input',
      { 'WrappedField__input--large': large },
    ]);
    const textAreaClasses = cn([
      'WrappedField__textarea',
    ]);

    return (
      <div className={ wrapperClasses }>
        <div className="WrappedField__label-wrapper">
          { label && <label className="WrappedField__label" htmlFor={ name }>{ label }</label> }

          { extra.text && extra.url &&
            <Link to={ getRoutePath(extra.url) } className="WrappedField__link">{ extra.text }</Link> }
        </div>

        <div className="WrappedField__input-wrapper">
          { type !== 'textarea' ? (
            <Field className={ fieldClasses } id={ name } name={ name } type={ type } { ...rest } />
          ):(
            <Field className={ textAreaClasses } id={ name } name={ name } component="textarea"/>
          ) }

          { children }
        </div>

        <span className="WrappedField__message">{ ( error && isTouched ) ? error : '' }</span>
      </div>
    );
  }
}

WrappedField.propTypes = propTypes;
WrappedField.defaultProps = defaultProps;

export default WrappedField;
