// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/svg/arrow-down.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://use.typekit.net/oob4opl.css);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".Dropdown {\n  position: relative; }\n  .Dropdown__title {\n    display: flex;\n    align-items: center;\n    margin: 0;\n    padding-right: 24px;\n    white-space: nowrap;\n    cursor: pointer;\n    outline: none; }\n    .Dropdown__title::after {\n      position: absolute;\n      right: 0;\n      display: inline-flex;\n      justify-content: center;\n      width: 24px;\n      content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n      transition: transform 0.2s ease; }\n  .Dropdown__content {\n    position: absolute;\n    right: 0;\n    bottom: 0;\n    background-color: #fff;\n    border-radius: 6px;\n    transform: translateY(calc(100% + 16px));\n    box-shadow: 0 8px 16px 0 rgba(127, 139, 168, 0.1); }\n    .Dropdown__content--hidden {\n      visibility: hidden; }\n", ""]);
// Exports
module.exports = exports;
