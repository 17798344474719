import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

import getRoutePath from '../../../utils/getRoutePath';
import removeTrailingLetters from '../../../utils/removeTrailingLetters';

import Button from '../../Button';
import Accordion from '../../Accordion';

const propTypes = {
  faq: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    })),
    link_label: PropTypes.string.isRequired,
  }),
};

function Faq(faq) {
  return (
    <section className="FaqList">
      <div className="FaqList__header">
        <h3 className="Pricing__title">Najczęściej zadawane pytania</h3>
      </div>
      <div className="FaqList__container">
        { faq.items.map((faqItem, i) => (
          <Accordion className="FaqList__item" title={ faqItem.question } key={ faqItem.question }>
            <div className="FaqList__item-desc"
              dangerouslySetInnerHTML={ { __html: removeTrailingLetters(faqItem.answer) } } />
          </Accordion>
        )) }
      </div>

      <Button to={ getRoutePath('FAQ') } className="FaqList__more">{ faq.link_label }</Button>
    </section>
  );
}

Faq.propTypes = propTypes;

export default Faq;
