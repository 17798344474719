import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

const propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

class Dropdown extends Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
    };

    this.wrapperRef = React.createRef();
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleDocumentClick);
    document.addEventListener('touchend', this.handleDocumentClick);
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleDocumentClick);
    document.removeEventListener('touchend', this.handleDocumentClick);
  }

  handleDocumentClick = (event) => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }

  toggleContent = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  renderContent = () => {
    const { children } = this.props;

    return (
      <div
        ref={ this.contentRef }
        role="menuitem"
        tabIndex={ 0 }
        className="Dropdown__content"
        onClick={ this.toggleContent }
        onKeyPress={ this.toggleContent }
      >
        { children }
      </div>
    );
  }

  render() {
    const { isOpen } = this.state;
    const { title, titleClassName, className } = this.props;

    const dropdownClasses = classnames('Dropdown', className);
    const titleClasses = classnames('Dropdown__title', titleClassName);

    return (
      <div className={ dropdownClasses } ref={ this.wrapperRef }>
        <div
          className={ titleClasses }
          role="menu"
          tabIndex={ 0 }
          onKeyPress={ this.toggleContent }
          onClick={ this.toggleContent }
        >
          { title }
        </div>

        { isOpen && this.renderContent() }
      </div>
    );
  }
}

Dropdown.propTypes = propTypes;

export default Dropdown;
