import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

import FacebookIcon from '../../svg/FacebookIcon';
import InstagramIcon from '../../svg/InstagramIcon';
import LinkedInIcon from '../../svg/LinkedinIcon';
import Button from '../../Button';

const propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

const icons = {
  Instagram: InstagramIcon,
  Facebook: FacebookIcon,
  LinkedIn: LinkedInIcon,
};

function SocialItem({ item }) {
  const Icon = icons[item.name];

  return (
    <Button
      href={ item.url }
      text
      target="_blank"
      rel="noopener noreferrer"
      className={ classnames('Footer__icon', 'Footer__social-icon') }
    >
      <Icon />
    </Button>
  );
};

SocialItem.propTypes = propTypes;

export default SocialItem;
