import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import './style.scss';

import withContent from '../../_enhancers/withContent';
import getApiPath from '../../../utils/getApiPath';
import getRoutePath from '../../../utils/getRoutePath';
import ArticlesCarousel from '../../_landingComponents/ArticlesCarousel';

const propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      tips: PropTypes.arrayOf(PropTypes.shape({
        slug: PropTypes.string.isRequired,
        image_url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })),
    })).isRequired,
  }).isRequired,
};

function Tips({ content }) {
  const { categories, heading, subheading } = content;

  return (
    <>
      <Helmet>
        <title>Przewodniki</title>
        <meta property="og:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | Przewodniki` } />
        <meta name="twitter:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | Przewodniki` } />
      </Helmet>

      <section className="Tips">

        <div className="Tips__container">
          <h1 className="Tips__heading">
            { heading }
          </h1>

          <h3 className="Tips__subheading">
            { subheading }
          </h3>

          <div className="Tips__categories">
            { categories.map(category => (
              <section
                className="Tips__category"
                key={ category.slug }
              >
                <h2 className="Tips__category-name">
                  { category.name }
                  <Link
                    className="Tips__category-link"
                    to={ getRoutePath('TIPS', category.slug) }
                  >
                    Zobacz więcej
                  </Link>
                </h2>
                <ArticlesCarousel articles={ category.articles } tip={ true } />
              </section>
            )) }
          </div>
        </div>

      </section>
    </>
  );
}

Tips.propTypes = propTypes;

export { Tips };
export default withContent(Tips, getApiPath('CONTENT_TIPS'));
