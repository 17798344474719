import clientManagerInstance from '../clientProvider/index';
import getApiPath from '../../utils/getApiPath/index';

import storageProviderInstance from '../storageProvider/index';

class ContentProvider {
  constructor (client, storageProvider) {
    this.client = client || clientManagerInstance.client;
    this.storageProvider = storageProvider || storageProviderInstance;

    this.updatedAtTimestamps = {};
    this.getUpdatedTimestamps();
  }

  getUpdatedTimestamps = async () => {
    this.updatedAtTimestamps = await this.client.get(getApiPath('CONTENT_TIMESTAMPS'));
  };

  getContent = async (url) => {
    const lastUpdatedAt =  this.updatedAtTimestamps[url];
    const cachedItem = await this.storageProvider.getContentItem('url', url);

    const bothTimestampsExist = lastUpdatedAt && cachedItem && cachedItem.updated_at;
    if(bothTimestampsExist) {
      const areTimestampsEqual = cachedItem.updated_at === lastUpdatedAt;

      if (areTimestampsEqual) {
        return cachedItem.details;
      }
    }

    return this.updateContent(url);
  };

  updateContent = async (url) => {
    const res = await this.client.get(url);

    const item = res.data;
    const details = (item && item.content) || null;
    if (details) {
      details.meta = (item && item.meta);
    }
    const updated_at = (item && item.updated_at) || null;

    if(details) {
      this.storageProvider.putContentItem({ url, updated_at, details });
    }

    if(updated_at) {
      this.updatedAtTimestamps[url] = updated_at;
    }

    return details;
  };
}

export { ContentProvider };

export default new ContentProvider();
