import React, { Component } from 'react';
import { Stage, Layer } from 'react-konva';
import { isEqual } from 'lodash';

import './style.scss';

import DiagramRoot from './DiagramRoot';
import DiagramNodeGroup from './DiagramNodeGroup';
import DiagramColumn from './DiagramColumn';
import DiagramConnections from './DiagramConnections';

class DiagramPreview extends Component {

  constructor(props) {
    super(props);

    const state = {
      nodes: {},
      edges: [],
      connections: [],
      scale: 1,
    };

    this.state = state;
  }

  componentDidMount() {
    this.zoomOutStage();
  }

  componentDidUpdate(_prevProps, prevState) {
    const { ioch } = this.props;

    if (prevState.nodes !== this.state.nodes) {
      this.createConnections(ioch.edges);
    }
  }

  connectNodes = (nodeStart, nodeFinish) => {
    const nodeFinishCenterX = (nodeFinish.x + nodeFinish.width / 2);
    const nodeStartCenterX = (nodeStart.x + nodeStart.width / 2);

    const x1 = nodeStart.x + nodeStart.width;
    const y1 = nodeStart.y + nodeStart.height / 2 - 0.5;

    const x2 = nodeFinishCenterX - (nodeFinishCenterX - (nodeStartCenterX)) / 2;
    const y2 = y1;

    const x3 = x2;
    const y3 = nodeFinish.y + nodeFinish.height / 2 - 0.5;

    const x4 = nodeFinish.x - 9;
    const y4 = y3;

    const connection = { x1, y1, x2, y2, x3, y3, x4, y4, type: 'horizontal' };

    this.setState(prevState => ({
      connections: [...prevState.connections, connection],
    }));
  }

  drawAroundConnections = (nodeStart, nodeFinish) => {
    const connection = nodeFinish.isFirst ? this.getTopLineCorrds(nodeStart, nodeFinish) 
      : this.getBottomLineCorrds(nodeStart, nodeFinish);

    this.setState(prevState => ({
      connections: [...prevState.connections, connection],
    }));
  }

  getTopLineCorrds = (nodeStart, nodeFinish) => {
    const diagramHeight = this.getIOChHeight();

    const x1 = nodeStart.x + nodeStart.width / 2;
    const y1 = nodeStart.y - 12;

    const x2 = x1;
    const y2 = diagramHeight - (diagramHeight - 20);

    const x3 = nodeFinish.x + nodeFinish.width / 2 + 0.5;
    const y3 = y2;

    const x4 = x3;
    const y4 = nodeFinish.y - 12;

    return { x1, y1, x2, y2, x3, y3, x4, y4, type: 'vertical' };
  }

  getBottomLineCorrds = (nodeStart, nodeFinish) => {
    const diagramHeight = this.getIOChHeight();

    const x1 = nodeStart.x + nodeStart.width / 2;
    const y1 = nodeStart.y + nodeStart.height + 12;

    const x2 = x1;
    const y2 = diagramHeight - 20;

    const x3 = nodeFinish.x + nodeFinish.width / 2 - 0.5;
    const y3 = y2;

    const x4 = nodeFinish.x + nodeFinish.width / 2 - 0.5;
    const y4 = nodeFinish.y + nodeFinish.height + 12;

    return { x1, y1, x2, y2, x3, y3, x4, y4, type: 'vertical' };
  }

  createConnections = async (edges) => {
    const { nodes } = this.state;

    await edges.map((edge) => {
      const sourceNode = nodes[`preview-${edge.source}`];
      const targetNode = nodes[`preview-${edge.target}`];

      if(sourceNode && targetNode) {
        const twoDepthIndexes = sourceNode.depthIndex === targetNode.depthIndex - 2;
        const shouldDrawAroundConn = twoDepthIndexes && (targetNode.isFirst || targetNode.isLast);

        return sourceNode && targetNode && (shouldDrawAroundConn ?
          this.drawAroundConnections(sourceNode, targetNode, edge.id) 
          : this.connectNodes(sourceNode, targetNode, edge.id));
      } else return (null);
    });
  }

  updateNodePosition = (node) => {
    const { id } = node;

    this.setState(prevState => ({
      nodes: {
        ...prevState.nodes,
        [id]: {
          x: node.x,
          y: node.y,
          height: node.height,
          width: node.width,
          depthIndex: node.depthIndex,
          isFirst: node.isFirst,
          isLast: node.isLast,
        },
      },
      connections: [],
    }));
  }

  getIOChHeight = () => {
    const { ioch } = this.props;
    const columnNodes = ioch.nodes.map(({ included_nodes }) => {
      return included_nodes.reduce((acc, { therapies }) => {
        therapies.map((therapy) =>
          therapy.variants.length ? acc = acc + therapy.variants.length * 100 : acc = acc + 200);
        return acc;
      }, 0);
    });

    return Math.max(...columnNodes);
  }

  zoomOutStage = () => {
    const scaleX = window.innerWidth / 2 / this.diagramPreview.attrs.width;
    const scaleY = window.innerHeight / 2 / this.diagramPreview.attrs.height;

    const scale = (scaleX < 1 && scaleX) || (scaleY < 1 && scaleY) || 1;

    this.setState({
      scale: scale,
    });
  }

  renderNode = (id, isActive) => {
    return (
      <DiagramRoot
        key={ id }
        id={ id }
        isActive={ isActive }
      />
    );
  }

  renderColumn = (nodes, isActive) => {
    return (
      nodes.map(({ therapies }, index) => {
        const { depth_index, id } = nodes[0];
        const node = therapies[0];

        if (depth_index === 0) {
          return this.renderNode(id, isActive);
        }

        if (therapies.length > 1) {
          return (
            <DiagramNodeGroup
              id={ nodes[index].id }
              isActive={ isActive }
              key={ `prev-${nodes[index].id}` }
            >
              { therapies.map((therapy) => (
                therapy && this.renderNode(therapy.id, isActive)
              )) }
            </DiagramNodeGroup>

          );
        }

        if (node) {
          return this.renderNode(nodes[index].id, isActive);
        }

        return null;
      })
    );
  }

  render() {
    const { connections, scale } = this.state;
    const { ioch } = this.props;

    const depth = ioch.nodes.length;
    const diagramWidth = depth * 460;
    const diagramHeight = this.getIOChHeight();

    return (
      <Stage
        width={ diagramWidth * scale }
        height={ diagramHeight * scale }
        ref={ stage => this.diagramPreview = stage }
        className="DiagramPreview"
        scaleX={ scale }
        scaleY={ scale }
      >
        <Layer>
          { ioch.nodes.map((node, index) => (
            <DiagramColumn
              x={ diagramWidth / depth * index }
              height={ diagramHeight }
              width={ diagramWidth / depth }
              updateNodePosition={ this.updateNodePosition }
              depthIndex={ node.depth_index }
              includedNodes={ node.included_nodes }
              id={ `prev-col-${index}` }
              key={ `prev-${index}` }
              isActive={ this.props.activeColumn === index }
            >
              { this.renderColumn(node.included_nodes, isEqual(this.props.activeColumn, index)) }
            </DiagramColumn>
          )) }

          { connections.length > 0 && <DiagramConnections connections={ connections }/> }
        </Layer>
      </Stage>
    );
  }
}

export default DiagramPreview;
