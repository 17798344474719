import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Group, Rect } from 'react-konva';

const propTypes = {
  id: PropTypes.number,
};


class DiagramRoot extends Component {
  static propTypes = propTypes;

  render() {
    const { id } = this.props;

    const rootHeight = 109;
    const rootWidth = 311;

    return (
      <Group
       id={ `preview-${id}` }
       width={ rootWidth }
       height={ rootHeight }
     >
        <Rect
         x={ 0 }
         y={ 0 }
         width={ rootWidth }
         height={ rootHeight }
         fill={ this.props.isActive ? '#D63230' : '#20C5D8' }
         cornerRadius={ 8 }         
         preventDefault={ false }
       />
      </Group>
    );
  }
}

export default DiagramRoot;
