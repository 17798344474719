import cn from 'classnames';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React from 'react';
import { findDOMNode } from 'react-dom';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import removeTrailingLetters from '../../../utils/removeTrailingLetters';
import Button from '../../Button';
import FullscreenIcon from '../../svg/FullscreenIcon';
import PauseButtonIcon from '../../svg/PauseButtonIcon';
import PlayButtonIcon from '../../svg/PlayButtonIcon';
import './style.scss';

class UpsideDownHero extends React.Component {
  constructor() {
    super();

    this.isMobile = window.innerWidth < 992;

    this.defaultUrl = 'https://youtu.be/NjM25173SuI';

    this.state = {
      isActive: false,
      played: 0,
      playing: !this.isMobile,
      fullscreen: false,
      controls: this.isMobile ? 1 : 0,
      url: this.defaultUrl,
    };
  }

  componentDidMount() {
    if (screenfull.enabled) {
      screenfull.on('change', () => {
        if (!screenfull.isFullscreen) {
          this.setState({
            isActive: false,
          }, () => this.hideControls());
        }
      });
    }
  }

  componentWillUnmount() {
    if (screenfull.enabled) {
      screenfull.off('change');
    }
  }

  onClick = () => {
    document.getElementsByClassName('Categories--landing')[0].scrollIntoView();
  }

  toggleActive = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive,
      playing: !prevState.isActive,
    }), () => {
      if (this.state.playing) {
        this.player.seekTo(0);
      }
    });
  }

  onSeekChange = value => {
    this.setState({ played: value },
      () => this.player.seekTo(value));
  }

  playingState = ({ played }) => {
    this.setState({ played });
  }

  onPause = () => {
    this.setState({
      playing: false,
      ...(!screenfull.isFullscreen && { isActive: false }),
    });
  }

  onClickFullscreen = () => {
    if (screenfull.enabled) {
      screenfull.request(findDOMNode(this.player));
      this.showControls();
    }
  }

  showControls = () => {
    this.setState({
      controls: 1,
      url: null,
    }, () => this.loadUrl());
  }

  hideControls = () => {
    this.setState({
      controls: 0,
      url: null,
    }, () => this.loadUrl());
  }

  loadUrl = () => {
    this.setState({
      url: this.defaultUrl,
    });
  }

  ref = player => {
    this.player = player;
  }

  render() {
    const { isActive, played, playing } = this.state;

    const PlayButton = isActive ? PauseButtonIcon : PlayButtonIcon;

    return (
      <>
        <div className="UpsideDownHero__mobile-title">
          <h2 >
            Gdy pojawia się rak, świat wywraca się do góry nogami.
          </h2>

          <p
            dangerouslySetInnerHTML={ {
              __html: removeTrailingLetters('Mednavi pozwala dowiedzieć się więcej o chorobie' +
              ' oraz metodach i miejscach jej leczenia. Nie traćmy czasu!'),
            } }>
          </p>
        </div>
        <section className={ `UpsideDownHero ${!isActive && 'UpsideDownHero--inactive'}` }>
          <div className={ `UpsideDownHero__container ${isActive && 'UpsideDownHero__container--active'}` }>
            <section className="UpsideDownHero__content">
              <h1 className="UpsideDownHero__title">
                Gdy pojawia się rak, świat wywraca się do góry nogami.
              </h1>

              <div className="UpsideDownHero__subtitle-wrapper">
                <h2 className="UpsideDownHero__subtitle"
                  dangerouslySetInnerHTML={ {
                    __html: removeTrailingLetters('Mednavi pozwala dowiedzieć się więcej' +
                    ' o chorobie oraz metodach i miejscach jej leczenia. Nie traćmy czasu!'),
                  } }>
                </h2>
              </div>

              <Button
                large
                className="UpsideDownHero__button"
                onClick={ this.toggleActive }
              >
                Zobacz film
              </Button>
            </section>
          </div>

          <div className="UpsideDownHero__wrapper">
            <ReactPlayer
              ref={ this.ref }
              url={ this.state.url }
              playing={ playing }
              config={ {
                youtube: {
                  playerVars: {
                    enablejsapi: 1,
                    playsinline: 0,
                    disablekb: 1,
                    controls: this.state.controls,
                    fs: 0,
                  },
                },
              } }
              onProgress={ this.playingState }
              onPause={ this.onPause }
              muted={ !isActive }
              width="100%"
              height="100%"
            />
          </div>

          <div
            className={ cn('UpsideDownHero__play-button',
            { 'UpsideDownHero__play-button--hidden': this.isMobile && playing }) }
          >
            <PlayButton onClick={ this.toggleActive } />
          </div>

          <div className="UpsideDownHero__controls">
            <FullscreenIcon onClick={ this.onClickFullscreen } className="Hero__fullscreen" />

            <Slider
              className="UpsideDownHero__slider"
              trackStyle={ { backgroundColor: '#00c5b8' } }
              handleStyle={ { borderColor: '#00c5b8' } }
              min={ 0 }
              max={ 1 }
              step={ 0.01 }
              value={ played }
              onChange={ this.onSeekChange }
              onSeekMouseUp={ this.onSeekMouseUp }
              onSeekMouseDown={ this.onSeekMouseDown }
            />
          </div>
        </section>
      </>
    );
  }
}

export default UpsideDownHero;
