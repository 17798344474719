import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import getApiPath from '../../../utils/getApiPath';
import Button from '../../Button';
import clientManagerInstance from '../../../providers/clientProvider';
import getNotificationContent from '../../../utils/getNotificationContent';
import getRoutePath from '../../../utils/getRoutePath';
import WrappedField from '../../WrappedField';
import withNotification from '../../_enhancers/withNotification';
import './style.scss';

const formSchema = Yup.object().shape({
  code: Yup.string()
    .required('Kod jest wymagany'),
});

const initialValues = {
  code: '',
};

class Voucher extends Component {
  constructor(props) {
    super(props);

    this.client = props.client || clientManagerInstance.client;
  }

  onSubmit = async (values, { resetForm, setErrors }) => {
    const { history, toggleNotification } = this.props;

    const data = {
      ...values,
    };

    try {
      await this.client.post(getApiPath('VALIDATE_DISCOUNT'), data);
      toggleNotification(
        getNotificationContent('subscription_prolonged'),
        null,
        () => history.push(getRoutePath('PROFILE_SUBSCRIPTION'))
      );
      resetForm();
    } catch (error) {
      let formErrors = {};
      formErrors['code'] = getNotificationContent('discount_invalid');
      setErrors(formErrors);
      };
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{ process.env.REACT_APP_PAGE_TITLE } | Zrealizuj kod</title>
        </Helmet>

        <article className="Voucher">
          <section className="Voucher__container">
            <div className="Voucher__nav-actions">
              <Link
                to={ getRoutePath('PROFILE_SUBSCRIPTION') }
                className="Voucher__link"
              >
                Wróć
              </Link>
            </div>

            <h3 className="Modules__heading">
              Mednavi Premium
            </h3>

            <h2 className="Modules__title">
              Zrealizuj kod
            </h2>

            <div className="Modules__text">
              Wprowadź otrzymany kod, który umożliwia skorzystanie z Mednavi Premium.
            </div>

            <div className="Voucher__form-wrapper">
              <Formik
                validationSchema={ formSchema }
                onSubmit={ this.onSubmit }
                initialValues={ initialValues }
              >
                { ({ errors, touched }) =>  {
                  return (
                    <Form className="Voucher__form" noValidate>
                      <WrappedField
                        className="Voucher__field"
                        name="code"
                        label="Kod rabatowy"
                        type="code"
                        error={ errors.code }
                        isTouched={ touched.code }
                      />
                      
                      <Button type="submit" className="Voucher__submit">Zrealizuj kod</Button>
                    </Form>);
                    } }
              </Formik>
            </div>
          </section>
        </article>
      </>
    );
  }
}

export default withRouter(withNotification(Voucher));
