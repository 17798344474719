/* eslint-disable */
import React from 'react';

function PlayButtonIcon({ ...props }) {
  return (
    <svg viewBox="0 0 102 102" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <defs>
        <path d="M46.0599979 64.0875013l18.2264075-11.3915047c.9366731-.5854207 1.2214194-1.8193214.6359988-2.7559945-.1609615-.2575382-.3784605-.4750373-.6359988-.6359987L46.0599979 37.9124987c-.9366731-.5854207-2.1705738-.3006744-2.7559945.6359987C43.1053389 38.8663606 43 39.2336558 43 39.6084953v22.7830094c0 1.1045695.8954305 2 2 2 .3748394 0 .7421347-.1053389 1.0599979-.3040034z" id="b"/>
        <filter x="-65.2%" y="-35.5%" width="230.5%" height="208.3%" filterUnits="objectBoundingBox" id="a">
          <feOffset dy="5" in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0" in="shadowBlurOuter1"/>
        </filter>
        <path d="M51 11c22.1 0 40 17.9 40 40S73.1 91 51 91 11 73.1 11 51s17.9-40 40-40zm0 77c20.39625 0 37-16.60375 37-37S71.39625 14 51 14 14 30.60375 14 51s16.60375 37 37 37z" id="d"/>
        <filter x="-18.1%" y="-11.9%" width="136.2%" height="136.2%" filterUnits="objectBoundingBox" id="c">
          <feOffset dy="5" in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0" in="shadowBlurOuter1"/>
        </filter>
      </defs>
      <g fillRule="nonzero" fill="none">
        <ellipse fill="#FFF" transform="matrix(-1 0 0 1 101.98288 0)" cx="50.9914405" cy="50.9914405" rx="50.189463" ry="50.2075949"/>
        <use fill="#000" filter="url(#a)" xlinkHref="#b"/>
        <use fill="#00C5B8" xlinkHref="#b"/>
        <g>
          <use fill="#000" filter="url(#c)" xlinkHref="#d"/>
          <use fill="#E8EBF0" xlinkHref="#d"/>
        </g>
      </g>
    </svg>
  );
};

export default PlayButtonIcon;
