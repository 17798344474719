import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const propTypes = {
  press: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    logos: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      image_url: PropTypes.string.isRequired,
    })),
  }).isRequired,
};

function Press({ press }) {
  return (
    <section className="Press">
      <div className="Press__container">
        <h2 className="Press__heading">{ press.heading }</h2>

        <ul className="Press__list">
          { (press.logos).map((logo, i) => (
            <a 
              className="Press__item"
              key={ i } 
              href={ `http://${logo.name}` }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="Press__logo"
                src={ logo.image_url }
                alt={ logo.name }
              />
            </a>
          )) }
        </ul>
      </div>
    </section>
  );
}

Press.propTypes = propTypes;

export default Press;
