import React from 'react';
import Select from 'react-select';
import cn from 'classnames';

import clientManagerInstance from '../../../providers/clientProvider';
import getApiPath from '../../../utils/getApiPath';
import getOptions from '../../../utils/getOptions';

import Button from '../../Button';

import Logo from '../../svg/Logo';
import CloseIcon from '../../svg/CloseIcon';

import './style.scss';

class TherapyFilters extends React.Component {
  constructor(props) {
    super(props);

    this.client = props.client || clientManagerInstance.client;

    this.state = {
      cities: [],
      voivodeships: [],
      activeCity: null,
      activeVoivodeship: null,
    };
  }

  componentDidMount() {
    const { filtersState } = this.props;

    this.setState({
      activeCity: filtersState.activeCity,
      activeVoivodeship: filtersState.activeVoivodeship,
    });

    this.getFilters();
  }

  getFilters = async () => {
    try {
      const res = await this.client.get(getApiPath('HOSPITALS_FILTER'));

      this.setState({
        cities: res.data.cities,
        voivodeships: res.data.voivodeships,
      });
    } catch (error) {
      this.setState({
        error: error.response ? error.response.statusText : error,
      });
    }
  }

  getCitiesByVoivodeship = async (activeVoivodeship) => {
    const params = {
      'voivodeship': activeVoivodeship ? activeVoivodeship.value : null,
    };

    try {
      const res = await this.client.post(getApiPath('HOSPITALS_FILTER_CITES'), params);

      this.setState({
        cities: res.data,
      });
    } catch (e) {
      this.setState({
        error: e.response ? e.response.statusText : e,
      });
    }
  };

  onVoivodeshipsChange = (selected) => {
    const { activeCity } = this.state;

    if(selected){
      this.props.onSelectChange({ type: 'voivodeships', value: selected.value });
    } else {
      this.clearSelect('voivodeships');
    }

    activeCity && this.clearSelect('city');

    this.setState({
      activeVoivodeship: selected,
      activeCity: null,
    });

    this.getCitiesByVoivodeship( selected );
  };

  onCitiesChange = (selected) => {
    if(selected){
      this.props.onSelectChange({ type: 'city', value: selected.value });
    } else {
      this.clearSelect('city');
    }

    this.setState({
      activeCity: selected,
    });
  };

  clearSelect = (currentType) => {
    this.props.onSelectChange({ type: currentType, value: null });
  };

  render() {
    const { cities, voivodeships, activeVoivodeship, activeCity } = this.state;

    const selectedVoivodeship = this.props.filtersState.activeVoivodeship ?
      {
        label: this.props.filtersState.activeVoivodeship,
        value: this.props.filtersState.activeVoivodeship,
      }: activeVoivodeship;

    const selectedCity = this.props.filtersState.activeCity ?
      {
        label: this.props.filtersState.activeCity,
        value: this.props.filtersState.activeCity,
      }: activeCity;


    return (
      <>
        <div className="TherapyFilters__header">
          <CloseIcon className="TherapyFilters__close" onClick={ this.props.toggleFilter }/>
          <Logo className="TherapyFilters__logo" />
        </div>

        <div className={ cn('TherapyFilters__group', 'TherapyFilters__voivodeships') }>
          <h3 className="TherapyFilters__label TherapyFilters__label--select">Województwo</h3>
          <Select
            className={ 'TherapyFilters__select' }
            classNamePrefix={ 'TherapyFilters' }
            components={ { IndicatorSeparator: () => null } }
            value={ selectedVoivodeship }
            defaultValue={ selectedVoivodeship && { value: selectedVoivodeship, label: selectedVoivodeship } }
            isClearable={ true }
            onChange={ this.onVoivodeshipsChange }
            options={ getOptions(voivodeships) }
            placeholder="Wybierz województwo"
          />
        </div>

        <div className={ cn('TherapyFilters__group', 'TherapyFilters__cities') }>
          <h3 className="TherapyFilters__label TherapyFilters__label--select">Miasto</h3>
          <Select
            className={ 'TherapyFilters__select' }
            classNamePrefix={ 'TherapyFilters' }
            components={ { IndicatorSeparator: () => null } }
            value={ selectedCity }
            defaultValue={ selectedCity && { value: selectedCity, label: selectedCity } }
            isClearable={ true }
            onChange={ this.onCitiesChange }
            options={ getOptions(cities) }
            placeholder="Wybierz miasto"
            label="Miasto"
          />
        </div>

        <div className="TherapyFilters__apply">
          <Button
            className="TherapyFilters__submit"
            onClick={ this.props.toggleFilter }
          >
            Zastosuj filtry { this.state.activeFiltersCount > 0 && `(${this.state.activeFiltersCount})` }
          </Button>
        </div>
      </>
    );
  }
}


export default TherapyFilters;
