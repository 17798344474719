/* eslint-disable */
const notificationsContents = {
  'sign_up_success': 'Konto Mednavi zostało utworzone pomyślnie.\
    Aby dokończyć proces rejestracji kliknij w link aktywacyjny, który wysłaliśmy na podany przez Ciebie adres e-mail.',
  'sessions_not_confirmed': 'Konto Mednavi nie zostało jeszcze potwierdzone. Zweryfikuj email.',
  'sessions_bad_credentials': 'Niepoprawne dane logowania.',
  'registration_email_already_taken': 'Użytkownik o podanym adresie email już istnieje.',
  'registration_password_too_short': 'Hasło nie powinno być krótsze niż 6 znaków.',
  'registration_password_too_long': 'Hasło nie powinno być dłuższe niż 128 znaków.',
  'registration_password_confirmation_not_match': 'Hasła różnią się.',
  'registration_email_invalid': 'Adres email jest niepoprawny.',
  'registration_email_blank': 'Adres email jest wymagany.',
  'registration_terms_not_accepted': 'Musisz zaakceptować Politykę prywatności oraz regulamin.',
  'registration_password_invalid': 'Hasło nieprawidłowe.',
  'registration_password_updated': 'Hasło zaktualizowane pomyślnie.',
  'processing_terms_not_accepted': 'Musisz zaakceptować zgodę na przetwarzanie Twoich danych osobowych.',
  'reset_password_mail_sent': 'Link z zmianą hasła został wysłany na Twoją skrzynkę pocztową.',
  'reset_password': 'Twoje hasło zostało pomyślnie zmienione.',
  'wrong_token': 'Nieprawidłowy token.',
  'invoiceForm_client_company_name': 'Imię i nazwisko / nazwa firmy jest wymagane.',
  'invoiceForm_client_street': 'Adres jest wymagany.',
  'invoiceForm_client_city': 'Miasto jest wymagane.',
  'invoiceForm_client_post_code': 'Błędny kod pocztowy.',
  'invoiceForm_client_tax_code': 'Format NIPu jest niepoprawny.',
  'invoiceForm_payment_transaction_id': 'Numer id transakcji nie został znaleziony.',
  'invoiceForm_infakt_creator': 'Wystąpił błąd przy tworzeniu faktury.',
  'invoiceForm_infakt_sender': 'Wystąpił błąd przy wysyłaniu faktury.',
  'invoiceForm_success': 'Faktura została utworzona i wysłana na Twoją skrzynkę pocztową.',
  'invoice_send': 'Faktura została wysłana na Twoją skrzynkę pocztową.',
  'sth_wrong': 'Coś poszło nie tak.',
  'not_authenticated': 'Musisz się zalogować, aby móc korzystać z biblioteki.',
  'diagram_favourited': 'Instrukcja Obsługi Choroby została dodana do Twojej biblioteki.',
  'diagram_unfavourited': 'Instrukcja Obsługi Choroby została usunięta z Twojej biblioteki.',
  'diagram_already_favourited': 'Instrukcja Obsługi Choroby znajduje się już w Twojej bibliotece.',
  'hospital_favourited': 'Placówka została dodana do Twojej biblioteki.',
  'hospital_unfavourited': 'Placówka została usunięta z Twojej biblioteki.',
  'hospital_already_favourited': 'Placówka znajduje się już w Twojej bibliotece.',
  'article_favourited': 'Artykuł został dodany do Twojej biblioteki.',
  'article_unfavourited': 'Artykuł został usunięty z Twojej biblioteki.',
  'article_already_favourited': 'Artykuł znajduje się już w Twojej bibliotece.',
  'no_mutations': 'Ścieżki leczenia już wkrótce.',
  'diagram_link_copied': 'Link do diagramu został skopiowany do schowka.',
  'story_link_copied': 'Link do historii został skopiowany do schowka.',
  'discount_invalid': 'Kod jest niepoprawny.',
  'subscription_prolonged': 'Twoja subskrybcja została przedłużona.',
  'withdrawal_terms_not_accepted': 'Musisz zaakceptować zgodę na wykonanie usługu w pełni przed upływem terminu.',
  'digital_content_terms_not_accepted': 'Musisz zaakceptować zgodę na dostarczenie treści cyfrowych.',
  'condition_not_accepted': 'Przynajmniej jedna ze zgód nie została zaakceptowana.',
  'p24_failed': 'Wystąpił problem z bramką płatności. Spróbuj później lub skontaktuj się z nami.',
  'p24_success': 'Twoje konto ma status Mednavi premium.',
};

export default function getNotificationContent(signature) {
  return notificationsContents[signature];
}
