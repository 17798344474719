import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Hammer from 'react-hammerjs';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import './style.scss';

import getRoutePath from '../../../utils/getRoutePath';
import getNotificationContent from '../../../utils/getNotificationContent';
import ReactTooltip from 'react-tooltip';

import PrintIcon from '../../svg/PrintIcon';
import ZoomOutIcon from '../../svg/ZoomOutIcon';
import FacebookIcon from '../../svg/FacebookShareIcon';
import InstagramIcon from '../../svg/InstagramShareIcon';
import LetterIcon from '../../svg/LetterShareIcon';
import Button from '../../Button';
import Select from 'react-select';

import Diagram from '../../Diagram';
import DiagramPreview from '../../DiagramPreview';
import withNotification from '../../_enhancers/withNotification';
import { withRouter } from 'react-router';

const propTypes = {
  slug: PropTypes.string,
  category: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  ioch: PropTypes.object,
};

class MobileIOCh extends Component {
  static propTypes = propTypes;

  constructor(props){
    super(props);

    const state = {
      activeColumn: null,
      zoomedOut: true,
      previewOpen: true,
      activeCategory: null,
      isDescriptionVisible: false,
    };

    this.state = state;
  }

  componentDidMount(){
    window.addEventListener('touchstart', this.touchStart);

    document.addEventListener('touchstart', (e) => {
      e.touches.length >= 2 ? this.setState({ hammerPinchEnabled: true })
        : this.setState({ hammerPinchEnabled: false });
    });

    document.addEventListener('touchend', (e) => {
      e.touches.length >= 2 ? this.setState({ hammerPinchEnabled: true })
        : this.setState({ hammerPinchEnabled: false });
    });
  }

  componentWillUnmount(){
    window.removeEventListener('touchstart', this.touchStart);

    document.removeEventListener('touchstart', (e) => {
      e.touches.length >= 2 ? this.setState({ hammerPinchEnabled: true })
        : this.setState({ hammerPinchEnabled: false });
    });

    document.removeEventListener('touchend', (e) => {
      e.touches.length >= 2 ? this.setState({ hammerPinchEnabled: true })
        : this.setState({ hammerPinchEnabled: false });
    });
  }

  touchStart(e){
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  }

  setActiveColumn = (id) => {
    this.setState({ activeColumn: id, zoomedOut: false });
  }

  setActiveCategory = (category) => {
    this.setState({ activeCategory: category });
  }

  getOptions = () => {
    const availableColumns = this.props.ioch.nodes.map(node => node.depth_index);

    return availableColumns.map(value => {
      const option = {
        value: value,
        label: `Etap ${ value + 1 }`,
      };

      return option;
    });
  }

  handleColumnChange = (column) => {
    this.setState({ activeColumn: column.value });
  }

  handleHover = () => {
    this.setState(prevState => ({ isHovered: !prevState.isHovered }));
  }

  onZoomOut = () => {
    this.setState({ zoomedOut: true, activeColumn: null });
  }

  handleZoomOut = () => {
    this.state.zoomedOut
      ? this.props.history.push(getRoutePath('CATEGORIES', 
        [(this.props.location.state && this.props.location.state.categorySlug) || this.state.activeCategory || null]))
      : this.onZoomOut();
  }

  togglePreviewOpen = () => {
    this.setState({ previewOpen: !this.state.previewOpen });
  }

  toggleDescriptionVisibility = () => {
    this.setState(prevState => ({ isDescriptionVisible: !prevState.isDescriptionVisible }));
  };

  renderDiagramContent = () => {
    const { category, slug, name, description, ioch, mobileIoch, onStoreDiagram } = this.props;
    const { previewOpen, isDescriptionVisible } = this.state;
    const isFirst = this.state.activeColumn === 0;
    const isLast = this.state.activeColumn === ioch.nodes.length - 1;
    const options = this.getOptions();
    const url = window.location.href;
    const descSuffix = isDescriptionVisible ? 'Ukryj opis' : 'Pokaż opis';
    const descContent = description ?
      (isDescriptionVisible ? description : description.substring(0, 100) + '...' ) : '';

    const facebookUrl = `https://www.facebook.com/sharer.php?u=${url}`;
    const twitterUrl = `https://twitter.com/share?url=${url}&text=${name}`;

    return (
      <div className={ `MobileIOCh ${this.state.activeColumn === null ? 'MobileIOCh--upper' : ''}` }>
        { this.state.activeColumn !== null && <div className="MobileIOCh__top-nav">
          <div className="MobileIOCh__nav-header">
            <div
              onClick={ this.handleZoomOut }
              onKeyPress={ this.handleZoomOut }
              role="menuitem"
              tabIndex={ 0 }
              className="MobileIOCh__back"
            >
              Wróć
            </div>
            <div
              onClick={ this.togglePreviewOpen }
              onKeyPress={ this.togglePreviewOpen }
              role="menuitem"
              tabIndex={ 0 }
              className="MobileIOCh__preview-link"
            >
              { previewOpen ? 'Ukryj podgląd diagramu' : 'Pokaż podgląd diagramu' }
            </div>
          </div>
          { previewOpen && <div
            className="MobileIOCh__diagram-preview"
            onClick={ () => this.setState({ isHovered: false }) }
            onKeyPress={ () => this.setState({ isHovered: false }) }
            role="menuitem"
            tabIndex={ 0 }
          >
            <DiagramPreview
            ioch={ mobileIoch }
            activeColumn={ this.state.activeColumn }
          />
          </div> }
          <Select
          className={ 'MobileIOChSelect__select' }
          classNamePrefix={ 'MobileIOChSelect' }
          value={ options.filter(option => option.value === this.state.activeColumn) }
          isSearchable={ false }
          isClearable={ false }
          onChange={ this.handleColumnChange }
          options={ options }
        /></div> }

        <div className="MobileIOCh__wrapper">
          <section className="MobileIOCH__heading">
            { this.state.activeColumn === null &&
              <div
                onClick={ this.handleZoomOut }
                onKeyPress={ this.handleZoomOut }
                role="menuitem"
                tabIndex={ 0 }
                className="MobileIOCh__back"
              >
                Wróć
              </div> }
            { this.state.activeColumn === null && <h1 className="MobileIOCh__name">
              { name }
            </h1> }

            { this.state.activeColumn === null && <div className="MobileIOCh__description">
              <div dangerouslySetInnerHTML={ { __html: descContent } } />
              <span 
                className="IOCh__description-link" 
                role="menuitem"
                tabIndex={ 0 }
                onClick={ this.toggleDescriptionVisibility }
                onKeyPress={ this.toggleDescriptionVisibility }
                >
                { descSuffix }
              </span>
            </div> }
          </section>
        </div>

        <div
          onClick={ () => this.setState({ isHovered: false,
            zoomedOut: false, ...(this.state.zoomedOut && { activeColumn: 0 }) }) }
          onKeyPress={ () => this.setState({ isHovered: false }) }
          id="iochDiagram"
          role="menuitem"
          tabIndex={ 0 }
        >
          <Diagram
            name={ name }
            ioch={ ioch }
            category={ category }
            slug={ slug }
            onZoomOut={ this.onZoomOut }
            zoomedOut={ this.state.zoomedOut }
            storeDiagram={ onStoreDiagram }
            activeColumn={ this.state.activeColumn }
            setActiveColumn={ this.setActiveColumn }
            activeCategory={ this.props.activeCategory }
            setActiveCategory={ this.setActiveCategory }
            print={ ref => this.printDiagram = ref }
          />
        </div>

        <div className="MobileIOCh__adnotation">
          Schemat leczenia powstał na podstawie wytycznych polskich
          i europejskich ekspertów. Lekarz proponując Ci sposób leczenia
          uwzględni także Twoją indywidualną sytuację, m.in. historię
          zachorowań w rodzinie i przewlekłe choroby, które wymagają stałego leczenia.
        </div>

        { this.state.activeColumn !== null ? <div className="MobileIOCh__actions">
          <Button
            className={ `MobileIOCh__column-button MobileIOCh__column-button--previous 
              ${isFirst ? 'MobileIOCh__column-button--hidden' : ''}` }
            onClick={ () =>
              this.setState({ isHovered: false, activeColumn: this.state.activeColumn - 1 })
            }
          >
            { `Etap ${ this.state.activeColumn }` }
          </Button>
          <Button
            className="MobileIOCh__button"
            icon
            onClick={ this.printDiagram }
          >
            <PrintIcon/>
          </Button>
          <div className={ `MobileIOCh__sharing ${!this.state.isHovered ? 'MobileIOCh__sharing--hidden' : ''}` }>
            <Button
              className="MobileIOCh__button"
              icon
              href={ twitterUrl }
              target="_blank"
              onClick={ this.handleHover }
            >
              <InstagramIcon className="IOCh__icon" data-tip="Udostępnij na Twitterze" />
              <ReactTooltip place="top" type="light" />
            </Button>
            <Button
              className="MobileIOCh__button"
              href={ facebookUrl }
              target="_blank"
              onClick={ this.handleHover }
              icon
            >
              <FacebookIcon className="MobileIOCh__icon" data-tip="Udostępnij na Facebooku" />
            </Button>
            <CopyToClipboard text={ window.location.href }>
              <Button
              className="MobileIOCh__button"
              icon
              onClick={ () => {
                this.props.toggleNotification(getNotificationContent('diagram_link_copied'));
                this.handleHover();
              } }
            >
                <LetterIcon className="IOCh__icon" data-tip="Skopiuj link" />
              </Button>
            </CopyToClipboard>
          </div>
          <Button
              className="MobileIOCh__button MobileIOCh__button--margined"
              onClick={ this.handleHover }
              icon
            >
            <ZoomOutIcon/>
          </Button>
          <Button
            className={ `MobileIOCh__column-button MobileIOCh__column-button--next
              ${isLast ? 'MobileIOCh__column-button--hidden' : ''}` }
            onClick={ () => this.setState({ isHovered: false, activeColumn: this.state.activeColumn + 1 }) }
          >
            { `Etap ${ this.state.activeColumn + 2 }` }
          </Button>
        </div> : <Button
          className="MobileIOCh__open-diagram-button"
          onClick={ () => this.setState({ zoomedOut: false, activeColumn: 0 }) }
        >
          Otwórz diagram
        </Button> }
      </div>
    );
  }

  render() {
    return (
      <Hammer options={ { touchAction: this.state.hammerPinchEnabled ? 'none' : 'auto' } }>
        { this.renderDiagramContent() }
      </Hammer>
    );
  }
}

export default withRouter(withNotification(MobileIOCh), true);
