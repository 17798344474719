import React from 'react';

function HamburgerIcon({ ...props }) {
  return (
    <svg width="18" height="12" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <path d="M0 12h18v-2H0v2zm0-5h18V5H0v2zm0-7v2h18V0H0z" fill="#00C5B8" fillRule="nonzero"/>
    </svg>
  );
};

export default HamburgerIcon;
