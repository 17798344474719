import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import './style.scss';

import withContent from '../../_enhancers/withContent';
import getApiPath from '../../../utils/getApiPath';
import getRoutePath from '../../../utils/getRoutePath';
import ArticlesCarousel from '../../_landingComponents/ArticlesCarousel';

const propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      articles: PropTypes.arrayOf(PropTypes.shape({
        slug: PropTypes.string.isRequired,
        image_url: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      })),
    })).isRequired,
  }).isRequired,
};

function Articles({ content }) {
  const { categories, heading, subheading, meta } = content;

  return (
    <>
      <Helmet>
        <title>Artykuły</title>
        <meta name="description" content={ meta.description } />

        <meta property="og:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | Artykuły` } />
        <meta property="og:description" content={ meta.description } />
        <meta property="og:image" content={ meta.image_url } />
        <meta property="og:image:secure_url" content={ meta.image_url } />
        <meta property="og:image:width" content="600px" />
        <meta property="og:image:height" content="314px" />

        <meta name="twitter:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | Artykuły` } />
        <meta name="twitter:description" content={ meta.description } />
        <meta name="twitter:image" content={ meta.image_url } />
      </Helmet>

      <section className="Articles">

        <div className="Articles__container">
          <h1 className="Articles__heading">
            { heading }
          </h1>

          <h3 className="Articles__subheading">
            { subheading }
          </h3>

          <div className="Articles__categories">
            { categories.map(category => (
              <section
                className="Articles__category"
                key={ category.slug }
              >
                <h2 className="Articles__category-name">
                  { category.name }
                  <Link
                    className="Articles__category-link"
                    to={ getRoutePath('ARTICLES', category.slug) }
                  >
                    Zobacz więcej
                  </Link>
                </h2>
                <ArticlesCarousel articles={ category.articles }/>
              </section>
            )) }
          </div>
        </div>

      </section>
    </>
  );
}

Articles.propTypes = propTypes;

export { Articles };
export default withContent(Articles, getApiPath('CONTENT_ARTICLES'));
