import React from 'react';
import { withRouter } from 'react-router';
import { NavLink, Link } from 'react-router-dom';
import classnames from 'classnames';

import './style.scss';

import getRoutePath from '../../utils/getRoutePath';
import containsRoute from '../../utils/containsRoute';
import clientManagerInstance from '../../providers/clientProvider';
import getApiPath from '../../utils/getApiPath';

import Dropdown from '../Dropdown';
import Logo from '../svg/Logo';
import CloseIcon from '../svg/CloseIcon';
import HamburgerIcon from '../svg/HamburgerIcon';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.client = props.client || clientManagerInstance.client;

    this.state = {
      isOpen: false,
      isSticky: false,
      anyTips: false,
      error: null,
    };

    this.links = [
      { routeName: 'HOME', label: 'Strona główna', exact: true },
      { routeName: 'UPSIDE_DOWN', label: '#swiatdogorynogami', highlighted: true },
      { routeName: 'ABOUT', label: 'O nas' },
      { routeName: 'CATEGORIES', label: 'Jak się leczyć' },
      { routeName: 'HOSPITALS', label: 'Gdzie się leczyć' },
      { routeName: 'STORIES', label: 'Historie pacjentów' },
    ];

    this.appRoutes = [
      getRoutePath('HOSPITALS'),
      getRoutePath('HOSPITAL'),
      getRoutePath('PROFILE'),
      getRoutePath('PROFILE_PASSWORD'),
      getRoutePath('PROFILE_TRANSACTIONS'),
      getRoutePath('INVOICE_FORM'),
      getRoutePath('CATEGORIES'),
      getRoutePath('THERAPY', []),
    ];
  }

  componentDidMount() {
    window.addEventListener('scroll', this.stickNavigation);

    this.setTipsCount();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.stickNavigation);
  }

  setTipsCount = async () => {
    try {
      const res = await this.client.get(getApiPath('CONTENT_TIPS'));
      const tipsCount = res.data.content ?
        res.data.content.categories.map(category => category.articles.length).reduce((a, b) => a + b, 0) : 0;

      this.setState({ anyTips: tipsCount > 0 });
    } catch (e) {
      this.setState({
        error: e.response ? e.response.statusText : e,
      });
    }
  }

  stickNavigation = () => {
    this.setState({
      isSticky: window.pageYOffset > 0,
    });
  }

  toggleMenu = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  }

  closeMenu = () => {
    this.setState({ isOpen: false });
  }

  renderLink = ({ routeName, label, exact, highlighted }) => (
    <NavLink
      key={ routeName }
      to={ getRoutePath(routeName) }
      exact={ exact }
      onClick={ this.toggleMenu }
      className={ `Navbar__link ${highlighted ? 'Navbar__link--highlighted' : ''}` }
      activeClassName={ `Navbar__link--active ${routeName === 'HOME' ? 'Navbar__link--active-home' : ''}` }
    >
      { label }
    </NavLink>
  );

  signedInUserActions = (signOut) => (
    <div className="User">
      <NavLink
        to={ getRoutePath('PROFILE') }
        onClick={ this.toggleMenu }
        className={ classnames('Navbar__link', 'User__link') }
        activeClassName="Navbar__link--active"
      >
        Twoje konto
      </NavLink>

      <Link
        to={ getRoutePath('HOME') }
        onClick={ () => {
          signOut();
          this.toggleMenu();
        } }
        className={ classnames('Navbar__link', 'User__link', 'User__sign-out') }
      >
        Wyloguj
      </Link>
    </div>
  )

  renderLinks() {
    const { isOpen } = this.state;

    const menuClasses = classnames('Navbar__menu', { 'Navbar__menu--open': isOpen });

    return (
      <div className={ menuClasses }>
        <div className="Navbar__links">
          { this.links.map(this.renderLink) }

          <Dropdown title="Baza wiedzy" className={ classnames('User__dropdown', 'Navbar__link') }>
            <div className="User">
              <NavLink
                to={ getRoutePath('ARTICLES') }
                onClick={ this.toggleMenu }
                className={ classnames('Navbar__link', 'User__link') }
                style={ { paddingLeft: '24px' } }
                activeClassName="Navbar__link--active"
              >
                Odpowiedzi ekspertów
              </NavLink>
              <NavLink
                to={ {
                  pathname: getRoutePath('RANKS'),
                  categorySlug: null,
                } }
                onClick={ this.toggleMenu }
                className={ classnames('Navbar__link', 'User__link') }
                style={ { paddingLeft: '24px' } }
                activeClassName="Navbar__link--active"
              >
                Statystyki szpitali
              </NavLink>
              { this.state.anyTips && <NavLink
                to={ {
                  pathname: getRoutePath('TIPS'),
                  categorySlug: null,
                } }
                onClick={ this.toggleMenu }
                className={ classnames('Navbar__link', 'User__link') }
                style={ { paddingLeft: '24px' } }
                activeClassName="Navbar__link--active"
              >
                Przewodniki
              </NavLink> }
            </div>
          </Dropdown>


          <div className="User__links">
            <NavLink
              to={ getRoutePath('ARTICLES') }
              onClick={ this.toggleMenu }
              className={ classnames('Navbar__link', 'User__link') }
              activeClassName="Navbar__link--active"
            >
              Odpowiedzi ekspertów
            </NavLink>
            <NavLink
              to={ {
                pathname: getRoutePath('RANKS'),
                categorySlug: null,
              } }
              onClick={ this.toggleMenu }
              className={ classnames('Navbar__link', 'User__link') }
              activeClassName="Navbar__link--active"
            >
              Statystyki szpitali
            </NavLink>
            { this.state.anyTips && <NavLink
              to={ {
                pathname: getRoutePath('TIPS'),
                categorySlug: null,
              } }
              onClick={ this.toggleMenu }
              className={ classnames('Navbar__link', 'User__link') }
              activeClassName="Navbar__link--active"
            >
              Przewodniki
              </NavLink> }
          </div>
        </div>
      </div>
    );
  }

  renderPlain = () => (
    <nav className={ classnames(
      'Navbar',
      'Navbar--transparent',
      'Navbar--plain',
    ) }>
      <div className="Navbar__controls">
        <Link to={ getRoutePath('HOME') } className="Navbar__logo-wrapper"
          onClick={ this.closeMenu } title="Mednavi">
          <Logo className="Navbar__logo Navbar__logo--blue" id="appLogo" />
        </Link>
      </div>
    </nav>
  );

  render() {
    const { isSticky, isOpen } = this.state;
    const { location: { pathname } } = this.props;
    const isAppRoute = containsRoute(this.appRoutes, pathname);
    const isSpecialRoute = containsRoute([getRoutePath('HOME'), getRoutePath('PREMIUM')], pathname);
    const navbarClasses = classnames(
      'Navbar',
      { 'Navbar--transparent': !isSticky && isSpecialRoute }
    );

    const pathsToHideLinks = [
      getRoutePath('SIGN_UP'),
      getRoutePath('SIGN_IN'),
      getRoutePath('FORGET_PASSWORD'),
      getRoutePath('PASSWORD_RESET'),
    ];

    const isPlain = containsRoute(pathsToHideLinks, pathname);

    if (isPlain) {
      return this.renderPlain();
    }

    return (
      <nav className={ navbarClasses }>
        <div className="Navbar__controls">
          <div className="Navbar__trigger">
            { isOpen ?
              <CloseIcon onClick={ this.toggleMenu } className="Navbar__trigger-close" /> :
              <HamburgerIcon onClick={ this.toggleMenu } />
            }
          </div>


          <Link to={ getRoutePath('HOME') } className="Navbar__logo-wrapper" onClick={ this.closeMenu }>
            <Logo className="Navbar__logo" />
          </Link>
        </div>

        { this.renderLinks() }


        { !isAppRoute && (
          document.getElementsByClassName('Modules__title').length ? <div
            onClick={ () => {
              document.getElementsByClassName('Modules__title')[0].scrollIntoView({ block: 'center' });
            } }
            onKeyPress={ () => {
              document.getElementsByClassName('Modules__title')[0].scrollIntoView({ block: 'center' });
            } }
            className="Navbar__shortcut"
            role="menu"
            tabIndex={ 0 }
          >
            Jak i gdzie się leczyć
          </div> : <Link to={ getRoutePath('HOME') } className="Navbar__shortcut" onClick={ this.closeMenu }>
              Jak i gdzie się leczyć
          </Link>
        ) }
      </nav>
    );
  }
}

export { Navbar };
export default withRouter(Navbar);
