/* eslint-disable */
import React from 'react';

function LetterIcon({ ...props }) {
  return (
    <svg height="20" viewBox="0 0 24 20" width="24" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <g fill="#20c5d8">
        <path d="m13.4 12.6c-.4110358.2814251-.9023299.4217949-1.4.4-.4976701.0217949-.9889642-.1185749-1.4-.4l-10.6-5.7v10.1c0 1.6568542 1.34314575 3 3 3h18c1.6568542 0 3-1.3431458 3-3v-10.1z"/><path d="m21 0h-18c-1.65685425 0-3 1.34314575-3 3v1c-.00194327.36673226.18759692.7079046.5.9l11 6c.1535417.0808367.3271782.115564.5.1.1728218.015564.3464583-.0191633.5-.1l11-6c.3124031-.1920954.5019433-.53326774.5-.9v-1c0-1.65685425-1.3431458-3-3-3z"/>
      </g>
    </svg>
  );
}

export default LetterIcon;