import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import getRoutePath from '../../../../utils/getRoutePath';

import './style.scss';

function MutationTile({ slug, name, image_url, hovered_image_url, category, landing, heigh }) {
  return (
    <Link
      to={ { pathname: getRoutePath('IOCh', [category, slug]), state: { categorySlug: category } } }
      className={ cn('CategoryTile', landing && 'CategoryTile--landing', heigh && 'CategoryTile__card--heigh') }
    >
      <div
        className={ cn('CategoryTile__card', landing && 'CategoryTile__card--landing',
          heigh && 'CategoryTile__card--heigh') }
        style={ { backgroundImage: `url('${image_url}')` } }
      >
        { name }
      </div>

      <div
        className={ cn('CategoryTile__card', 'CategoryTile__card-secondary', landing && 'CategoryTile__card--landing') }
        style={ { backgroundImage: `url('${hovered_image_url}')` } }
      >
        <p className={ cn('CategoryTile__title', landing && 'CategoryTile__title--landing') }>{ name }</p>
      </div>
    </Link>
  );
}

export default MutationTile;
