import React from 'react';
import { Link } from 'react-router-dom';
import getRoutePath from '../../../utils/getRoutePath';
import removeTrailingLetters from '../../../utils/removeTrailingLetters';
import Button from '../../Button';
import './style.scss';

function Rank(data) {
  return (
    <section className="Rank">
      <h3 className="Rank__subheading">{ data.header }</h3>
      <h5 className="Rank__heading">
        { data.subheader }
      </h5>
      <div className="Rank__tiles-wrapper">
        { data.cancer_kinds.map(rank => {
          return (
            <div className="Rank__tile">
              <h3 className="Rank__tile-name">{ rank.name }</h3>
              { rank.ranks.map((el, idx) => {
                return (
                  <div className="Rank__tile-content">
                    <div className="Rank__tile-hospital">

                      <Link
                        to={ {
                          pathname: getRoutePath('HOSPITAL', el.hospital.id),
                          state: {
                            prevPath: getRoutePath('RANKS'),
                            categorySlug: rank.slug,
                          },
                        } }
                        className="Rank__tile-hospital-link"
                      >
                        <div
                          dangerouslySetInnerHTML={ {
                            __html: removeTrailingLetters(el.hospital.name),
                          } }
                        />
                      </Link>
                    </div>
                    <div className="Rank__tile-value-wrapper">
                      <h5 className="Rank__tile-value">{ el.count }</h5>
                    </div>
                  </div>
                );
              }) }
              <Button dark to={ {
                pathname: getRoutePath('RANKS'),
                state: {
                  categorySlug: rank.slug,
                },
              } }
                className="Rank__button">
                Zobacz więcej
              </Button>
            </div>
          );
        }) }
      </div>
      <Button dark to={ {
        pathname: getRoutePath('RANKS'),
        state: {
          categorySlug: null,
        },
      } }
        className="Rank__more-button">
        Zobacz inne rodzaje nowotworów
      </Button>
    </section>
  );
}

export default Rank;
