import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import './style.scss';

import NotFound from '../NotFound';

import clientManagerInstance from '../../../providers/clientProvider';
import getRoutePath from '../../../utils/getRoutePath';
import getApiPath from '../../../utils/getApiPath';
import ReactTooltip from 'react-tooltip';

import PrintIcon from '../../svg/PrintIcon';
import ZoomOutIcon from '../../svg/ZoomOutIcon';
import FacebookIcon from '../../svg/FacebookShareIcon';
import InstagramIcon from '../../svg/InstagramShareIcon';
import LetterIcon from '../../svg/LetterShareIcon';
import Accordion from '../../Accordion';
import Button from '../../Button';
import Loader from '../../Loader';

import Diagram from '../../Diagram';
import MobileIOCh from '../MobileIOCh';
import withNotification from '../../_enhancers/withNotification';
import getNotificationContent from '../../../utils/getNotificationContent';
import { withRouter } from 'react-router';

const propTypes = {
  slug: PropTypes.string,
  category: PropTypes.string,
};

class IOCh extends Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);
    this.client = props.client || clientManagerInstance.client;

    const state = {
      diagram: null,
      name: null,
      description: null,
      slug: null,
      ioch: null,
      mobileIoch: null,
      imageUrl: null,
      error: null,
      isDescriptionVisible: false,
      activeColumn: null,
      isLoading: false,
      isHovered: false,
      zoomedOut: false,
      activeCategory: null,
    };

    this.state = state;
    this.handleHover = this.handleHover.bind(this);
  }

  componentDidMount() {
    const { category, slug } = this.props;

    this.getIOCh(category, slug);
  }

  setActiveColumn = (id) => {
    this.setState({ activeColumn: id });
  }


  getIOCh = async (category, slug) => {
    try {
      const res = await this.client.get(getApiPath('IOCh', [category, slug]));

      this.setState({
        name: res.data.name,
        description: res.data.description,
        slug: res.data.slug,
        ioch: res.data.diagram,
        mobileIoch: res.data.diagram,
        imageUrl: res.data.image_url,
      });
    } catch (error) {
      this.setState({
        error: error.response ? error.response.statusText : error,
      });
    }
  }

  storeDiagram = (diagram) => {
    this.setState({
      diagram: diagram,
    });
  }

  toggleDescriptionVisibility = () => {
    this.setState(prevState => ({ isDescriptionVisible: !prevState.isDescriptionVisible }));
  };

  handleHover = () => {
    this.setState(prevState => ({ isHovered: !prevState.isHovered }));
  }

  onZoomOut = () => {
    this.setState({ zoomedOut: false });
  }

  handleZoomOut = () => {
    this.state.activeColumn === null ?
      this.props.history.push(getRoutePath('CATEGORIES',
        [(this.props.location.state && this.props.location.state.categorySlug) || this.state.activeCategory || null])) :
      this.setState({ zoomedOut: true, activeColumn: null });
  }

  setActiveCategory = (category) => {
    this.setState({ activeCategory: category });
  }

  render() {
    const { name, description, ioch, mobileIoch, error, isDescriptionVisible } = this.state;
    const { category, slug } = this.props;
    const isFirst = this.state.activeColumn === 0;
    const isMobile = window.innerWidth < 768;
    const url = window.location.href;
    const descSuffix = isDescriptionVisible ? 'Ukryj opis' : 'Pokaż opis';
    const descContent = description ?
      (isDescriptionVisible ? description : description.substring(0, 100) + '...') : '';

    const facebookUrl = `https://www.facebook.com/sharer.php?u=${url}`;
    const twitterUrl = `https://twitter.com/share?url=${url}&text=${name}`;

    if (ioch) {
      return (
        <>
          <Helmet>
            <title>{ name.split('.')[1] }</title>
            <meta name="description" content={ description.replace(/<\/?[^>]+(>|$)/g, '') } />
            <meta name="canonical" content={ url } />

            <meta name="og:title" content={ `Mednavi | ${name.split('.')[1]}` } />
            <meta name="og:url" content={ url } />
            <meta property="og:type" content="article" />
            <meta name="og:description" content={ description.replace(/<\/?[^>]+(>|$)/g, '') } />
            <meta name="og:canonical" content={ url } />
            <meta name="og:image" content={ this.state.imageUrl } />
            <meta name="og:image:secure_url" content={ this.state.imageUrl } />
            <meta property="og:image:width" content="600px" />
            <meta property="og:image:height" content="314px" />
          </Helmet>

          { isMobile && <MobileIOCh
            category={ category }
            slug={ slug }
            name={ name }
            description={ description }
            ioch={ ioch }
            mobileIoch={ mobileIoch }
            onStoreDiagram={ this.storeDiagram }
            activeCategory={ this.props.location.state && this.props.location.state.categorySlug }
          /> }

          <div className="IOCh">
            <div className="IOCh__wrapper IOCh__wrapper--margined">
              <section className="IOCH__heading">
                <div
                  onClick={ this.handleZoomOut }
                  onKeyPress={ this.handleZoomOut }
                  role="menuitem"
                  tabIndex={ 0 }
                  className="IOCh__back"
                >
                  Wróć
                </div>

                <div
                  className="IOCh__description"
                  onClick={ () => this.setState({ isHovered: false }) }
                  onKeyPress={ () => this.setState({ isHovered: false }) }
                  role="menuitem" tabIndex={ 0 }
                >
                  <Accordion title={ name } className="IOCh__accordion" open>
                    <div
                      dangerouslySetInnerHTML={ { __html: descContent } }
                    />
                    <div
                      className="IOCh__description-link"
                      role="menuitem"
                      tabIndex={ 0 }
                      onClick={ this.toggleDescriptionVisibility }
                      onKeyPress={ this.toggleDescriptionVisibility }
                    >
                      { descSuffix }
                    </div>
                  </Accordion>
                </div>
              </section>

              <div className="IOCh__actions">
                <Button
                  className="IOCh__button"
                  icon
                  title="Drukuj PDF"
                  onClick={ () => {
                    this.setState({ isHovered: false });
                    this.printDiagram();
                  } }
                >
                  <PrintIcon />
                </Button>
                <div className="IOCh__social-media">
                  <div className={ `IOCh__sharing ${!this.state.isHovered ? 'IOCh__sharing--hidden' : ''}` }>
                    <Button
                      className="MobileIOCh__button"
                      icon
                      href={ twitterUrl }
                      target="_blank"
                      onClick={ this.handleHover }
                    >
                      <InstagramIcon className="IOCh__icon" data-tip="Udostępnij na Twitterze" />
                      <ReactTooltip place="top" type="light" />
                    </Button>
                    <Button
                      className="MobileIOCh__button"
                      href={ facebookUrl }
                      target="_blank"
                      onClick={ this.handleHover }
                      icon
                    >
                      <FacebookIcon className="IOCh__icon" data-tip="Udostępnij na Facebooku" />
                    </Button>
                    <CopyToClipboard text={ window.location.href }>
                      <Button
                        className="MobileIOCh__button"
                        icon
                        onClick={ () => {
                          this.props.toggleNotification(getNotificationContent('diagram_link_copied'));
                          this.handleHover();
                        } }
                      >
                        <LetterIcon className="IOCh__icon" data-tip="Skopiuj link" />
                      </Button>
                    </CopyToClipboard>
                  </div>
                  <Button
                    className="IOCh__button"
                    onClick={ this.handleHover }
                    icon
                    title="Udostępnij"
                  >
                    <ZoomOutIcon />
                  </Button>
                </div>
              </div>
            </div>

            <div
              className="IOCh__diagram"
              onClick={ () => this.setState({ isHovered: false }) }
              onKeyPress={ () => this.setState({ isHovered: false }) }
              role="menuitem"
              tabIndex={ 0 }
            >
              { !isMobile && <Diagram
                name={ name }
                ioch={ ioch }
                id="iochDiagram"
                category={ category }
                slug={ slug }
                onZoomOut={ this.onZoomOut }
                zoomedOut={ this.state.zoomedOut }
                activeColumn={ this.state.activeColumn }
                setActiveColumn={ this.setActiveColumn }
                activeCategory={ this.props.location.state && this.props.location.state.categorySlug }
                setActiveCategory={ this.setActiveCategory }
                storeDiagram={ this.storeDiagram }
                print={ ref => this.printDiagram = ref }
              /> }
            </div>

            <div className="IOCh__adnotation">
              Schemat leczenia powstał na podstawie wytycznych polskich
              i europejskich ekspertów. Lekarz proponując Ci sposób leczenia
              uwzględni także Twoją indywidualną sytuację, m.in. historię zachorowań
              w rodzinie i przewlekłe choroby, które wymagają stałego leczenia.
            </div>

            { this.state.activeColumn !== null && <nav className="IOCh__topbar">
              <Button
                secondary
                className={ `${isFirst ? 'MobileIOCh__column-button--hidden' : ''}` }
                onClick={ () => this.setState({ activeColumn: this.state.activeColumn - 1 }) }
              >
                Poprzedni etap
              </Button>
              <Button
                className={ `${this.state.activeColumn === ioch.nodes.length - 1 ?
                  'MobileIOCh__column-button--hidden' : ''}` }
                onClick={ () => this.setState({ activeColumn: this.state.activeColumn + 1 }) }
              >
                Następny etap
              </Button>
            </nav> }
          </div>
          <img style={ { display: 'none' } } src="#" alt="Diagram print preview" id="diagram-print" />
        </>
      );
    }

    if (error) {
      return <NotFound />;
    }

    return <Loader isStrech />;
  }
}

export default withRouter(withNotification(IOCh));
