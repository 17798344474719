import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

import removeTrailingLetters from '../../../utils/removeTrailingLetters';

const propTypes = {
  people: PropTypes.arrayOf(PropTypes.shape({
    avatar_url: PropTypes.string.isRequired,
    full_name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired, //richText
  })).isRequired,
};

function Team({ people }) {
  return (
    <div className="Team">
      { people.map((person, i) => (
        <figure className="Team__member" key={ i }>
          <img
            className={ `Team__member-avatar ${i % 2 && 'Team__member-avatar--reverse'}` }
            src={ person.avatar_url }
            alt={ person.full_name }
          />

          <figcaption className={ i % 2 ? 'Team__member-personel--reverse' : 'Team__member-personel' }>
            <h3 className="Team__member-name">{ person.full_name }</h3>

            <h4 className="Team__member-position"> { person.position }</h4>

            <div
              className="Team__member-text"
              dangerouslySetInnerHTML={ { __html: removeTrailingLetters(person.text) } }
            />
          </figcaption>
        </figure>
    )) }
    </div>
  );
}

Team.propTypes = propTypes;

export default Team;
