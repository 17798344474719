import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import './style.scss';

import tooltipIcon from '../../assets/images/svg/tooltipIcon.svg';

const propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

function Checkbox({ id, name, checked, onChange, label, tooltip }) {
  return (
    <label htmlFor={ id } className="Checkbox">
      <input
        type="checkbox"
        name={ name }
        id={ id }
        checked={ checked }
        onChange={ onChange }
        className="Checkbox__input"
      />

      <span className="Checkbox__label">{ label }</span>

      { tooltip && (
        <>
          <img
            src={ tooltipIcon }
            alt={ tooltip }
            data-tip={ `${ tooltip }` }
            title={ tooltip }
            className="Checkbox__tooltip"
          />

          <ReactTooltip place="right" type="light" />
        </>
      ) }
    </label>
  );
}

Checkbox.propTypes = propTypes;

export default Checkbox;
