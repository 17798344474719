import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import { findDOMNode } from 'react-dom';
import { withRouter } from 'react-router-dom';
import Slider from 'rc-slider';

import './style.scss';
import 'rc-slider/assets/index.css';

import Button from '../../Button';
import PlayButtonIcon from '../../svg/PlayButtonIcon';
import PauseButtonIcon from '../../svg/PauseButtonIcon';
import FullscreenIcon from '../../svg/FullscreenIcon';

const propTypes = {
  hero: PropTypes.shape({
    title: PropTypes.string.isRequired,
    title2: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    subtitle2: PropTypes.string.isRequired,
    link_label: PropTypes.string.isRequired,
    background_image_urls: PropTypes.shape({
      small: PropTypes.string.isRequired,
      medium: PropTypes.string.isRequired,
      large: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

class Hero extends React.Component {
  constructor() {
    super();

    this.isMobile = window.innerWidth < 992;

    this.defaultUrl = 'https://youtu.be/YAVCRXhQc7w';

    this.state = {
      isActive: false,
      played: 0,
      playing: !this.isMobile,
      fullscreen: false,
      controls: this.isMobile ? 1 : 0,
      url: this.defaultUrl,
    };
  }

  componentDidMount() {
    if (screenfull.enabled) {
      screenfull.on('change', () => {
        if (!screenfull.isFullscreen) {
          this.setState({
            isActive: false,
          }, () => this.hideControls());
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (this.props.location.state && this.props.location.state.categories) {
        document.getElementsByClassName('Categories--landing')[0]
          && document.getElementsByClassName('Categories--landing')[0].scrollIntoView();
      }
    }
  }

  componentWillUnmount() {
    if (screenfull.enabled) {
      screenfull.off('change');
    }
  }

  onClick = () => {
    document.getElementsByClassName('Categories--landing')[0].scrollIntoView();
  }

  toggleActive = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive,
      playing: !prevState.isActive,
    }), () => {
      if (this.state.playing) {
        this.player.seekTo(0);
      }
    });
  }

  onSeekChange = value => {
    this.setState({ played: value },
      () => this.player.seekTo(value));
  }

  playingState = ({ played }) => {
    this.setState({ played });
  }

  onPause = () => {
    this.setState({
      playing: false,
      ...(!screenfull.isFullscreen && { isActive: false }),
    });
  }

  onClickFullscreen = () => {
    if (screenfull.enabled) {
      screenfull.request(findDOMNode(this.player));
      this.showControls();
    }
  }

  showControls = () => {
    this.setState({
      controls: 1,
      url: null,
    }, () => this.loadUrl());
  }

  hideControls = () => {
    this.setState({
      controls: 0,
      url: null,
    }, () => this.loadUrl());
  }

  loadUrl = () => {
    this.setState({
      url: this.defaultUrl,
    });
  }

  ref = player => {
    this.player = player;
  }

  render() {
    const { title, title2, subtitle, subtitle2, link_label } = this.props;
    const { isActive, played, playing } = this.state;

    const PlayButton = isActive ? PauseButtonIcon : PlayButtonIcon;

    return (
      <section className={ `Hero ${!isActive && 'Hero--inactive'}` }>
        <div className={ `Hero__container ${isActive && 'Hero__container--active'}` }>
          <section className="Hero__content">
            <h1 className="Hero__title">
              { title } <br />
              { title2 }
            </h1>

            <h2 className="Hero__subtitle">
              <span className="Hero__subtitle--light">{ subtitle }</span> <br />
              { subtitle2 }
            </h2>

            <Button
              large
              className="Hero__button"
              onClick={ this.onClick }
            >
              { link_label }
            </Button>
          </section>
        </div>

        <div className="Hero__wrapper">
          <ReactPlayer
            ref={ this.ref }
            url={ this.state.url }
            playing={ playing }
            config={ {
              youtube: {
                playerVars: {
                  enablejsapi: 1,
                  playsinline: 0,
                  controls: this.state.controls,
                  fs: 0,
                },
              },
            } }
            onProgress={ this.playingState }
            onPause={ this.onPause }
            muted={ !isActive }
            width="100%"
            height="100%"
          />
        </div>

        <div
          className={ cn('Hero__play-button', { 'Hero__play-button--hidden': this.isMobile && playing }) }
        >
          <PlayButton onClick={ this.toggleActive } />
        </div>

        <div className="Hero__controls">
          <FullscreenIcon onClick={ this.onClickFullscreen } className="Hero__fullscreen" />

          <Slider
            className="Hero__slider"
            trackStyle={ { backgroundColor: '#00c5b8' } }
            handleStyle={ { borderColor: '#00c5b8' } }
            min={ 0 }
            max={ 1 }
            step={ 0.01 }
            value={ played }
            onChange={ this.onSeekChange }
            onSeekMouseUp={ this.onSeekMouseUp }
            onSeekMouseDown={ this.onSeekMouseDown }
          />
        </div>
      </section>
    );
  }
}

Hero.propTypes = propTypes;

export default withRouter(Hero);
