import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import { withRouter } from 'react-router';

import './styles.scss';

import clientManagerInstance from '../../providers/clientProvider';

import getApiPath from '../../utils/getApiPath';

import Button from '../Button';
import getRoutePath from '../../utils/getRoutePath';

const propTypes = {
  onSearch: PropTypes.func,
};


class CancerAutocomplete extends Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);
    this.client = props.client || clientManagerInstance.client;

    const state = {
      query: '',
      result: [],
      error: null,
    };

    this.state = state;
  }

  componentDidUpdate(prevProps) {
    if(prevProps.activeQuery !== this.props.activeQuery) {
      this.setState({
        query: this.props.activeQuery,
      });
    }
  }

  getList = async (event, search) => {
    this.setState({
      query: search,
      result: [],
    });

    try {
      const params = { query: search, category_id: this.props.category };
      const res = await this.client.post(getApiPath('CATEGORIES_AUTOCOMPLETE'), params);

      this.setState({
        result: res.data,
      });

      return res.data;
    } catch (e) {
      this.setState({
        error: e.response ? e.response.statusText : e,
      });
    }
  }

  redirectToItem = (item) => {
    const { history } = this.props;
    const url = item.type === 'mutation' ?
      getRoutePath('IOCh', [item.parent_slug, item.slug]) : getRoutePath('CATEGORIES', item.slug);
    history.push({ pathname: url, state: { categorySlug: this.props.category } });
  }

  onInputChange = async (event, search) => {
    const resultItem = this.state.result.filter(res => res.name === search)[0];

    resultItem ? this.redirectToItem(resultItem) : this.setState({
      query: search,
    }, () => this.onSubmitSearch());
  }

  onInputClear = () => {
    this.setState({
      query: '',
      result: [],
    });

    this.props.onSearch();
  }

  onSubmitSearch = e => {
    e && e.preventDefault();
    const { query } = this.state;
    this.props.onSearch(query);
  }

  renderList = (items) => {
    if (items.length) {
      return (
        <ul className="AutocompleteSearch__list">
          { items }
        </ul>
      );
    } else {
      return <></>;
    }
  }

  renderItem = (item, isHighlighted) => {
    return (
      <li key={ item }
          className={ `AutocompleteSearch__item ${isHighlighted ? 'AutocompleteSearch__item--highlighted' : ''}` }>
        { item }
      </li>
    );
  }

  render() {
    const inputProps = {
      placeholder: 'Wyszukaj instrukcję...',
      className: 'AutocompleteSearch__input',
    };

    const wrapperProps = {
      className: 'AutocompleteSearch__wrapper',
      style: {},
    };

    return (
      <form className="AutocompleteSearch" onSubmit={ this.onSubmitSearch }>
        <Autocomplete
          inputProps={ inputProps }
          wrapperProps={ wrapperProps }
          items={ this.state.result.map(result => result.name) }
          value={ this.state.query }
          getItemValue={ (item) => item }
          onChange={ this.getList }
          onSelect={ (this.onInputChange) }
          renderMenu={ this.renderList }
          renderItem={ this.renderItem }
          autoHighlight={ false }
        />

        <Button
          large
          className="AutocompleteSearch__button"
          type="submit"
        >
          Szukaj
        </Button>
      </form>
    );
  }
}

export default withRouter(CancerAutocomplete);
