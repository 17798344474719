import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

import getRoutePath from '../../../utils/getRoutePath';
import CancerAutocomplete from '../../CancerAutocomplete';
import getNotificationContent from '../../../utils/getNotificationContent';
import CategoryTile from '../../_pages/Categories/CategoryTile';
import Loader from '../../Loader';

const propTypes = {
  modules: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired, //richtext
    link_label: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
  })),
};

function MutationCategories({ categories, isLoading, toggleNotification, getCategories }) {
  const handleInactiveTile = (e) => {
    e.preventDefault();
    toggleNotification(getNotificationContent('no_mutations'));
  };

    if (categories) {
      return (
        <section className="Categories Categories--landing">
          <div className="Categories__container Categories__container--padding">
            <div className="Categories__header">
              <h3 className="Categories__subheading">
              Znajdź Instrukcję Obsługi Choroby
              </h3>

              <h1 className="Categories__heading">
              Jak się leczyć
              </h1>
            </div>

            <CancerAutocomplete
            onSearch={ getCategories }
          />

            <div className="Categories__list">
              { isLoading ? (
                <Loader/>
          ):(
            categories.slice(0, 7).map(category => (
              <CategoryTile
                key={ category.slug }
                landing
                category={ category.slug }
                link_to= { getRoutePath('CATEGORIES', category.slug) }
                handleInactiveTile= { handleInactiveTile }
                inactive= { category.mutations_count === 0 }
                { ...category }
              />
            ))
          ) }

              <CategoryTile
                key="categories-list"
                landing
                category="Zobacz wszystkie"
                name="Zobacz wszystkie"
                link_to= { getRoutePath('CATEGORIES') }
                inactive= { false }
              />
            </div>

          </div>
        </section>);
    } else return (<Loader />);
}

MutationCategories.propTypes = propTypes;

export default MutationCategories;
