// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/svg/arrow-down.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://use.typekit.net/oob4opl.css);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".Accordion__trigger {\n  font-family: aktiv-grotesk-extended, sans-serif; }\n\n.Accordion {\n  padding: 16px 0;\n  font-size: 14px;\n  border-bottom: 1px solid #e3e9ed; }\n  .Accordion__trigger {\n    position: relative;\n    display: flex;\n    align-items: center;\n    padding-right: 24px;\n    font-weight: 700;\n    line-height: 18px;\n    color: #001066;\n    cursor: pointer; }\n    .Accordion__trigger::after {\n      position: absolute;\n      right: 0;\n      display: inline-flex;\n      justify-content: center;\n      width: 24px;\n      content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n      transition: transform 0.2s ease; }\n    .Accordion__trigger--open {\n      color: #31c8d1; }\n      .Accordion__trigger--open::after {\n        transform: rotate(180deg); }\n  .Accordion__content {\n    padding-top: 12px;\n    line-height: 22px;\n    color: #677491; }\n", ""]);
// Exports
module.exports = exports;
