// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/svg/arrow-down.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://use.typekit.net/oob4opl.css);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".HospitalList__figcaption {\n  font-family: aktiv-grotesk-extended, sans-serif; }\n\n.HospitalList__pagination {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 28px; }\n\n.HospitalList__page {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 40px;\n  height: 40px;\n  text-align: center;\n  cursor: pointer;\n  background: #fff;\n  border: 1px solid #e3e9ed;\n  border-right: 0; }\n  .HospitalList__page:nth-child(2) {\n    border-bottom-left-radius: 4px;\n    border-top-left-radius: 4px; }\n  .HospitalList__page:nth-last-child(2) {\n    border-right: 1px solid #e3e9ed;\n    border-top-right-radius: 4px;\n    border-bottom-right-radius: 4px; }\n  .HospitalList__page--prev, .HospitalList__page--next {\n    border: 1px solid #e3e9ed;\n    border-radius: 4px; }\n  .HospitalList__page--prev {\n    margin-right: 12px; }\n  .HospitalList__page--next {\n    margin-left: 12px; }\n  .HospitalList__page--active {\n    background: #fafafb; }\n\n.HospitalList__link {\n  width: 100%;\n  font-size: 14px;\n  line-height: 40px;\n  color: #00c5b8; }\n  .HospitalList__link:hover, .HospitalList__link:active, .HospitalList__link:focus {\n    color: #00c5b8; }\n  .HospitalList__link--prev, .HospitalList__link--next {\n    display: flex;\n    align-items: center;\n    flex: 1 1;\n    justify-content: center;\n    height: 100%; }\n    .HospitalList__link--prev::before, .HospitalList__link--next::before {\n      content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); }\n  .HospitalList__link--prev::before {\n    transform: rotate(90deg); }\n  .HospitalList__link--next::before {\n    transform: rotate(-90deg); }\n\n.HospitalList__icon {\n  margin-bottom: 8px; }\n\n.HospitalList__figcaption {\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 24px;\n  color: #31c8d1; }\n\n.HospitalList--empty {\n  max-width: 580px;\n  margin: auto;\n  text-align: center; }\n", ""]);
// Exports
module.exports = exports;
