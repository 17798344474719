import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Group, Rect } from 'react-konva';

const propTypes = {
  id: PropTypes.string,
  x: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};


class DiagramColumn extends Component {
  static propTypes = propTypes;

  componentDidMount() {
    this.setChildrenPosition();
  }

  componentDidUpdate (prevProps) {
    if(prevProps.activeNode !== this.props.activeNode) {
      this.setChildrenPosition();
    }
  }

  getChildrenHeight = () => {
    const children = this.previewColumn.getChildren();

    const childrenHeight = children.reduce((acc, child) => {
      const height = child.getAttr('height');

      acc += height;
      return acc;
    }, 0);

    return childrenHeight;
  }

  setChildrenPosition = () => {
    const children = this.previewColumn.getChildren();
    children.reduce((acc, child, index) => (this.setChildPosition(children.length, acc, child, index)), 0);
  }

  setChildPosition = (amount ,acc, child, index) => {
    const { height, width, updateNodePosition, depthIndex, includedNodes } = this.props;
    const { id } = child.getAttrs();

    const childrenHeight = this.getChildrenHeight();
    const availableSpace = height - childrenHeight;
    const spacing = availableSpace / (amount + 1 );

    const childWidth = child.getAttr('width');
    const childHeight = child.getAttr('height');
    const childIndex = includedNodes.findIndex(el => { return(el.id === id || `preview-${el.id}` === id); });

    const childPositionX = (width - childWidth) / 2;
    const childPositionY = spacing * (index + 1) + acc;

    child.setAttr('y', childPositionY );
    child.setAttr('x', childPositionX );

    acc += childHeight;

    updateNodePosition && updateNodePosition({
      id: id,
      x: child.getAbsolutePosition().x,
      y: child.getAbsolutePosition().y,
      width: childWidth,
      height: childHeight,
      depthIndex: depthIndex,
      isFirst: childIndex === 0,
      isLast: childIndex === includedNodes.length - 1,
    });
    return acc;
  }

  render() {
    const { x, id, children } = this.props;

    return (
      <Group
        id={ `preview-overlay-${id}` }
        width={ 361 }
        height={ this.props.height - 20 }
        ref={ group => this.previewOverlayGroup = group }
      >
        { this.props.isActive && <Rect
          x={ x + 51 }
          y={ 0 }
          width={ 360 }
          height={ this.props.height - 4 }
          cornerRadius={ 10 }
          fill="rgba(214,50,48, 0.14)"
          stroke="#d63230"
          strokeWidth={ 4 }
          preventDefault={ false }
        /> }

        <Group
          id={ id }
          x={ x }
          ref={ node => {
            this.previewColumn = node;
          } }
        >
          { children }
        </Group>
      </Group>
    );
  }
}

export default DiagramColumn;
