import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './style.scss';

import CookiesNotice from '../CookiesNotice';

import getRoutePath from '../../utils/getRoutePath';
import getApiPath from '../../utils/getApiPath';
import withContent from '../_enhancers/withContent';
import ScrollToTop from '../ScrollToTop';

import Navbar from '../Navbar';
import Footer from '../Footer';

import Loader from '../Loader';

import Home from '../_pages/Home';
import About from '../_pages/About';
import Articles from '../_pages/Articles';
import Tips from '../_pages/Tips';
import Faq from '../_pages/Faq';
import Stories from '../_pages/Stories';
import Story from '../_pages/Story';
import Contact from '../_pages/Contact';
import Policy from '../_pages/Policy';
import PrivacyPolicy from '../_pages/PrivacyPolicy';
import Article from '../_pages/Article';
import Tip from '../_pages/Tip';
import Category from '../_pages/Category';
import ArticleCategory from '../_pages/ArticleCategory';
import TipCategory from '../_pages/TipCategory';
import Hospitals from '../_pages/Hospitals';
import Hospital from '../_pages/Hospital';
import IOCh from '../_pages/IOCh';
import Categories from '../_pages/Categories';
import Voucher from '../_pages/Voucher';
import NotFound from '../_pages/NotFound';
import Therapy from '../Therapy';
import UpsideDown from '../_pages/UpsideDown';
import Ranks from '../_pages/Ranks';

class App extends Component {
  constructor(props) {
    super(props);

    const state = {
      isLoaded: false,
    };

    this.state = state;
  }

  componentDidMount () {
    this.setState({
      isLoaded: true,
    });
  }

  render() {
    if(!this.state.isLoaded) {
      return <Loader isCover />;
    }

    return (
      <div className="App">
        <Helmet
          titleTemplate={ `${ process.env.REACT_APP_PAGE_TITLE } |  %s` }
          defaultTitle="Mednavi - Twoja medyczna nawigacja"
        >
        </Helmet>

        <Router>
          <>
            <Navbar />

            <main>
              <ScrollToTop>
                <Switch>
                  <Route path={ getRoutePath('HOME') } exact component={ Home } />

                  <Route path={ getRoutePath('VOUCHER') } exact component={ Voucher } />

                  <Route path={ getRoutePath('ABOUT') } exact component={ About } />

                  <Route path={ getRoutePath('STORIES') } exact component={ Stories } />

                  <Route
                        path={ getRoutePath('STORY', ':slug') }
                        render={ props => <Story slug={ props.match.params.slug } /> }
                      />

                  <Route path={ getRoutePath('ARTICLES') } exact component={ Articles } />

                  <Route path={ getRoutePath('TIPS') } exact component={ Tips } />

                  <Route path={ getRoutePath('ARTICLES', ':slug') } component={ ArticleCategory } />
                  <Route path={ getRoutePath('TIPS', ':slug') } component={ TipCategory } />

                  <Route
                        path={ getRoutePath('ARTICLE', ':slug') }
                        render={ props => <Article slug={ props.match.params.slug } /> }
                      />

                  <Route
                        path={ getRoutePath('TIP', ':slug') }
                        render={ props => <Tip slug={ props.match.params.slug } /> }
                      />

                  <Route path={ getRoutePath('CONTACT') } exact component={ Contact }/>

                  <Route path={ getRoutePath('RANKS') } exact component={ Ranks } />

                  <Route
                        path={ getRoutePath('TERMS') }
                        exact
                        component={ withContent(Policy, getApiPath('CONTENT_TERMS')) }
                      />

                  <Route
                        path={ getRoutePath('PRIVACY_POLICY') }
                        exact
                        component={ withContent(PrivacyPolicy, getApiPath('CONTENT_PRIVACY_POLICY')) }
                      />

                  <Route path={ getRoutePath('FAQ') } exact component={ Faq } />

                  <Route path={ getRoutePath('HOSPITALS') } exact component={ Hospitals }/>

                  <Route
                      path={ getRoutePath('HOSPITAL', ':slug') }
                      render={ props => <Hospital
                        slug={ props.match.params.slug }
                      /> }
                    />

                  <Route path={ getRoutePath('CATEGORIES') } exact component={ Categories } />

                  <Route
                      path={ getRoutePath('CATEGORIES', ':slug') }
                      render={ props => <Category slug={ props.match.params.slug }/> }
                    />

                  <Route
                      path={ getRoutePath('THERAPY', [':category', ':mutation', ':therapy']) }
                      render={ props =>
                        <Therapy
                          category={ props.match.params.category }
                          mutation={ props.match.params.mutation }
                          therapy={ props.match.params.therapy }
                        />
                      }
                    />

                  <Route
                    path={ getRoutePath('IOCh', [':categorySlug', ':slug']) }
                    render={ props =>
                      <IOCh
                        category={ props.match.params.categorySlug }
                        slug={ props.match.params.slug }
                      />
                    }
                    />

                  <Route path={ getRoutePath('UPSIDE_DOWN') } component={ UpsideDown } />

                  <Route component={ NotFound } />
                </Switch>
                <ToastContainer />

              </ScrollToTop>
            </main>

            <Footer />

            <CookiesNotice />
          </>
        </Router>
      </div>
    );
  }
}

export default App;
