import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import cn from 'classnames';

import './style.scss';

import HospitalList from '../../HospitalList';
import RefineSearch from '../../RefineSearch';
import AutocompleteSearch from '../../AutocompleteSearch';

class Hospitals extends Component {

  constructor(props) {
    super(props);

    const state = {
      isLoading: true,
      activeFilters: [],
      activeVoivodeship: null,
      activeCity: null,
      activeQuery: null,
      isFilterVisible: false,
      activePage: null,
      latitude: null,
      longitude: null,
    };

    this.state = state;
  }

  componentDidMount() {
    const { location } = this.props;

    if (location.state && location.state.filters) {
      this.setFilters(location.state.filters);
    }

    this.setState({
      isLoading: false,
    });

    navigator.geolocation && navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        this.setState({
          latitude: coords.latitude,
          longitude: coords.longitude,
        });
      },
      () => { },
      {
        timeout: 5000,
      }
    );
  }

  setFilters = async (filters) => {
    await this.setState({
      activeFilters: filters.activeFilters,
      activeVoivodeship: filters.activeVoivodeship,
      activeCity: filters.activeCity,
      activeQuery: filters.activeQuery,
      isFilterVisible: filters.isFilterVisible,
      activePage: filters.activePage,
    });
  }

  setActivePage = (page) => {
    this.setState({
      activePage: page,
    });
  }

  onFilterChange = (filter) => {
    const id = filter.target.id;
    const checked = filter.target.checked;
    const inArray = this.state.activeFilters.includes(id);

    if (checked && !inArray) {
      this.setState(prevState => ({
        activeFilters: [...prevState.activeFilters, id],
      }));
    } else if (!checked && inArray) {
      this.setState({
        activeFilters: this.state.activeFilters.filter((activeFilter) => {
          return activeFilter !== id;
        }),
      });
    }
    this.setActivePage();
  }

  onSelectChange = (region) => {
    region.type === 'city' && this.setState({ activeCity: region.value });
    region.type === 'voivodeships' && this.setState({ activeVoivodeship: region.value });
    this.setActivePage();
  }

  onSearch = (query) => {
    this.setState({
      activeQuery: query,
    });
  }

  toggleFilter = () => {
    this.setState({
      isFilterVisible: !this.state.isFilterVisible,
    });
  }

  render() {
    const filtersClassName = cn(
      'Hospitals__filter',
      { 'Hospitals__filter--visible': this.state.isFilterVisible }
    );

    return (
      <>
        <Helmet>
          <title>Gdzie się leczyć</title>
        </Helmet>

        <section className="Hospitals">
          <div className="Hospitals__container">
            <section className="Hospitals__headers">
              <h3 className="Hospitals__heading">Gdzie się leczyć</h3>
              <h1 className="Hospitals__header">Znajdź placówkę medyczną</h1>
            </section>

            <div className="Hospitals__search">
              <AutocompleteSearch onSearch={ this.onSearch } activeQuery={ this.state.activeQuery } />
            </div>

            <aside className={ filtersClassName }>
              <RefineSearch
                onFilterChange={ this.onFilterChange }
                onSelectChange={ this.onSelectChange }
                toggleFilter={ this.toggleFilter }
                filtersState={ this.state }
              />
            </aside>

            <div className="Hospitals__list">
              <HospitalList
                filters={ this.state }
                setActivePage={ this.setActivePage }
                toggleFilter={ this.toggleFilter }
                />
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(Hospitals);
