import React, { Component } from 'react';
import { Stage, Layer } from 'react-konva';
import { withRouter } from 'react-router';

import cn from 'classnames';

import './style.scss';

import getStripString from '../../utils/getStripString';

import DiagramRoot from './DiagramRoot';
import DiagramNode from './DiagramNode';
import DiagramNodeGroup from './DiagramNodeGroup';
import DiagramNodeVariants from './DiagramNodeVariants';
import DiagramColumn from './DiagramColumn';
import DiagramConnections from './DiagramConnections';
import Loader from '../Loader';

import jsPDF from 'jspdf';
import { fontPath, logoPath } from '../_pages/IOCh/assetsPaths.json';

class Diagram extends Component {

  constructor(props) {
    super(props);

    const state = {
      isStored: false,
      nodes: {},
      edges: [],
      connections: [],
      activeNode: null,
      activeColumn: null,
      previewScale: null,
      scale: 1,
      initialDiagramWidth: 0,
      offsetX: 0,
      returnFlag: false,
      isLoading: true,
      isPrinting: false,
    };

    this.state = state;

    this.columnWidth = props.ioch.width || 750;
    this.diagramWidth = props.ioch.nodes.length * this.columnWidth;
  }

  componentDidMount() {
    const { location } = this.props;

    this.setState({
      initialDiagramWidth: this.diagram.width(),
    });

    this.props.print(() => {
      this.setState({ isLoading: true }, () => setTimeout(this.printDiagram, 0));
    });

    ((location.state && !location.state.activeNode) || !location.state) && this.preparePrintImage();

    this.zoomOutStage();
  }

  componentDidUpdate(prevProps, prevState) {
    const { ioch, location } = this.props;
    const { returnFlag } = this.state;

    if (prevState.nodes !== this.state.nodes) {
      this.createConnections(ioch.edges);
      (location.state && location.state.activeNode) && this.handleBackLink();
    }

    if (prevProps.activeColumn !== this.props.activeColumn) {
      if (!returnFlag) {
        this.scrollToColumn(this.props.activeColumn);
      } else {
        this.setState({ returnFlag: false });
      }
    }

    if (prevProps.zoomedOut !== this.props.zoomedOut && this.props.zoomedOut) {
      Promise.resolve(this.setState({ activeNode: null })).then(() => this.zoomOutStage());
    }

    if ((prevProps.location.state && prevProps.location.state.activeCategory)
      !== (this.props.location.state && this.props.location.state.activeCategory)) {
      this.props.setActiveCategory(prevProps.location.state.activeCategory);
    }
  }

  componentWillUnmount() {
    this.diagram.setAttr('width', 0);
    this.diagram.setAttr('height', 0);
  }

  preparePrintImage = async () => {
    this.setState({ isPrinting: true });

    await Promise.resolve(this.createConnections(this.props.ioch.edges))
      .then(() => {
        this.diagram.toDataURL({
          callback: image => {
            document.querySelector('#diagram-print').src = image;
          },
          mimetype: 'image/jpeg',
          quality: 1,
          pixelRatio: this.diagramWidth / this.diagram.width(),
        });
      });
    this.setState({ isPrinting: false });
  }

  connectNodes = (nodeStart, nodeFinish, edge) => {
    const connection = this.getStartXCoord(nodeStart, nodeFinish, edge);

    this.setState(prevState => ({
      connections: [...prevState.connections, connection],
    }));
  }

  nodesMultiplierValue = (initialValue, edge) => (
    (initialValue - ((edge.childrenCount - 1) * 12 + (edge.childrenCount - 1) * 8)) / 2 + edge.childIndex * 20
  )

  printDiagram = () => {
    const input = document.querySelector('#diagram-print');
    const width = input.naturalWidth;
    const height = input.naturalHeight;

    const scaleX = 800 / width;
    const scaleY = 500 / height;
    const scale = scaleY < scaleX ? scaleY : scaleX;

    const logo = logoPath;

    const scaledWidth = width * scale;
    const scaledHeight = height * scale;

    const startPointX = (842 - scaledWidth) / 2;
    const startPointY = (595 - scaledHeight) / 2;

    const pdf = new jsPDF('landscape', 'pt', 'a4', false);
    const font = fontPath;

    pdf.setProperties({ title: `${this.props.slug}.pdf` });
    pdf.addFileToVFS('Lato-Regular-normal.ttf', font);
    pdf.addFont('Lato-Regular-normal.ttf', 'Lato-Regular', 'normal');
    pdf.setFont('Lato-Regular');
    pdf.setFontSize(20);
    pdf.text(this.props.name, 30, 50);
    pdf.addImage(input.src, 'JPEG', startPointX - 10, startPointY + 8, scaledWidth, scaledHeight, '', 'FAST');
    pdf.addImage(logo, 'JPEG', 650, 520, 160, 53);
    pdf.save(`${this.props.slug}.pdf`);
    pdf.output('dataurlnewwindow', `${this.props.slug}.pdf`);

    this.setState({ isLoading: false });
  }

  getStartXCoord = (nodeStart, nodeFinish, edge) => {
    switch (edge.startPlace) {
      case 'top': return this.getTopCoords(nodeStart, nodeFinish, edge);
      case 'right': return this.getRightCoords(nodeStart, nodeFinish, edge);
      case 'bottom': return this.getBottomCoords(nodeStart, nodeFinish, edge);
      default: return this.getRightCoords(nodeStart, nodeFinish, edge);
    }
  }

  getTopCoords = (nodeStart, nodeFinish, edge) => {
    const x1 = nodeStart.x + (nodeStart.width + ((edge.childrenCount + edge.parentsCount) * 12
      + (edge.childrenCount - 1) * 8)) / 2 + edge.childIndex * 20;

    const y2 = {
      top: edge.y2 || Math.min(nodeFinish.y, nodeStart.y) - 28 * (edge.childIndex + 1),
      left: edge.y2 || nodeFinish.y + nodeFinish.height / 2,
      bottom: edge.y2 || nodeFinish.y + nodeFinish.height + 28 * (edge.childIndex + 1),
    };

    const y3 = {
      top: edge.y3 || Math.min(nodeFinish.y, nodeStart.y) - 28 * (edge.childIndex + 1),
      left: edge.y3 || nodeFinish.y + nodeFinish.height / 2,
      bottom: edge.y3 || nodeFinish.y + nodeFinish.height + 28 * (edge.childIndex + 1),
    };

    const y4 = {
      top: edge.y4 || Math.min(nodeFinish.y, nodeStart.y) - 28 * (edge.childIndex + 1),
      left: edge.y4 || nodeFinish.y + nodeFinish.height / 2,
      bottom: edge.y4 || nodeFinish.y + nodeFinish.height + 28 * (edge.childIndex + 1),
    };

    const y6 = {
      top: nodeFinish.y - 8,
      left: nodeFinish.y + (nodeFinish.height - ((edge.parentsCount - 1) * 12
        + (edge.parentsCount - 1) * 8)) / 2 + edge.parentIndex * 20,
      bottom: nodeFinish.y + nodeFinish.height + 12,
    };

    const x4 = {
      top: edge.x4 || nodeFinish.x + nodeFinish.width / 2 - 20 * (edge.parentIndex),
      left: edge.x4 || nodeFinish.x - 12,
      bottom: edge.x4 || nodeFinish.x + nodeFinish.width / 2,
    };

    const x6 = {
      top: nodeFinish.x + nodeFinish.width / 2 - 20 * (edge.parentIndex),
      left: nodeFinish.x - 12,
      bottom: nodeFinish.x + nodeFinish.width / 2,
    };

    return (
      {
        type: 'verticalTop',
        id: edge.id,
        x1: x1,
        y1: nodeStart.y - 12,
        x2: x1,
        y2: y2[edge.endPlace],
        x3: x4[edge.endPlace],
        y3: y3[edge.endPlace],
        x4: x4[edge.endPlace],
        y4: y4[edge.endPlace],
        x5: x6[edge.endPlace],
        y5: y4[edge.endPlace],
        x6: x6[edge.endPlace],
        y6: y6[edge.endPlace],
      }
    );
  }

  getRightCoords = (nodeStart, nodeFinish, edge) => {
    const nodeFinishCenterX = (nodeFinish.x + nodeFinish.width / 2);
    const nodeStartCenterX = (nodeStart.x + nodeStart.width / 2);
    const nodeFinishY = (nodeFinish.height - ((edge.parentsCount - 1) * 12
      + (edge.parentsCount - 1) * 8)) / 2 + edge.parentIndex * 20;

    const x1 = nodeStart.x + nodeStart.width;
    const y1 = nodeStart.y + (nodeStart.height - ((edge.childrenCount - 1) * 12
      + (edge.childrenCount - 1) * 8)) / 2 + edge.childIndex * 20;

    const regular = nodeFinishCenterX - (nodeFinishCenterX - nodeStartCenterX) / 1.5
      + ((edge.index + 1) * (edge.parentIn + 1) / edge.columnCount) * 50;
    const irregular = nodeFinishCenterX - (nodeFinishCenterX - nodeStartCenterX) / 1.5
      + ((edge.index + 1) * (edge.parentIn + 1) / edge.columnCount) * 50 - (edge.index - 1);
    const x2 = edge.x2 || (nodeFinish.y + nodeFinish.height / 2 <= nodeStart.y ? regular : irregular);

    const y2 = {
      top: edge.y2 || Math.min(nodeFinish.y, nodeStart.y) - 28 * (edge.childIndex + 1),
      left: edge.y2 || y1,
      bottom: edge.y2 || nodeFinish.y + nodeFinish.height + 28 * (edge.childIndex + 1),
    };

    const y3 = {
      top: edge.y3 || Math.min(nodeFinish.y, nodeStart.y) - 28 * (edge.childIndex + 1),
      left: edge.y3 || nodeFinish.y + nodeFinishY,
      bottom: edge.y3 || nodeFinish.y + nodeFinish.height + 28 * (edge.childIndex + 1),
    };

    const y4 = {
      top: edge.y4 || Math.min(nodeFinish.y, nodeStart.y) - 28 * (edge.childIndex + 1),
      left: edge.y4 || nodeFinish.y + nodeFinishY,
      bottom: edge.y4 || nodeFinish.y + nodeFinish.height + 28 * (edge.childIndex + 1),
    };

    const y6 = {
      top: nodeFinish.y - 8,
      left: nodeFinish.y + nodeFinishY,
      bottom: nodeFinish.y + nodeFinish.height + 12,
    };

    const x3 = {
      top: edge.x3 || nodeFinish.x + nodeFinish.width / 2 - 20 * (edge.parentIndex),
      left: edge.x3 || x2,
      bottom: edge.x3 || nodeFinish.x + nodeFinish.width / 2,
    };

    const x4 = {
      top: edge.x4 || nodeFinish.x + nodeFinish.width / 2 - 20 * (edge.parentIndex),
      left: edge.x4 || nodeFinish.x - 9,
      bottom: edge.x4 || nodeFinish.x + nodeFinish.width / 2,
    };

    const x6 = {
      top: nodeFinish.x + nodeFinish.width / 2 - 20 * (edge.parentIndex),
      left: nodeFinish.x - 9,
      bottom: nodeFinish.x + nodeFinish.width / 2,
    };

    const x5 = {
      top: edge.x5 || x6[edge.endPlace] - (x6[edge.endPlace] - x4[edge.endPlace]) / 2,
      left: edge.x5 || x6[edge.endPlace] - (x6[edge.endPlace] - x4[edge.endPlace]) / 2,
      bottom: edge.x5 || x6[edge.endPlace] - (x6[edge.endPlace] - x4[edge.endPlace]) / 2,
    };

    return (
      {
        type: 'horizontal',
        id: edge.id,
        x1: x1,
        y1: y1,
        x2: x2,
        y2: y2[edge.endPlace],
        x3: x3[edge.endPlace],
        y3: y3[edge.endPlace],
        x4: x4[edge.endPlace],
        y4: y4[edge.endPlace],
        x5: x5[edge.endPlace],
        y5: y6[edge.endPlace],
        x6: x6[edge.endPlace],
        y6: y6[edge.endPlace],
      }
    );
  }

  getBottomCoords = (nodeStart, nodeFinish, edge) => {
    const x1 = nodeStart.x + (nodeStart.width + ((edge.childrenCount + edge.parentsCount)
      * 12 + (edge.childrenCount - 1) * 8)) / 2 + edge.childIndex * 20;

    const y2 = {
      top: edge.y2 || nodeStart.y + nodeStart.height + 20,
      left: edge.y2 || nodeFinish.y + nodeFinish.height / 2 + 12 * (edge.parentIndex),
      bottom: edge.y2 || nodeFinish.y + nodeFinish.height + 28 * (edge.childIndex + 1),
    };

    const y3 = {
      top: edge.y3 || nodeStart.y + nodeStart.height + 20,
      left: edge.y3 || nodeFinish.y + nodeFinish.height / 2 + 12 * (edge.parentIndex),
      bottom: edge.y3 || nodeFinish.y + nodeFinish.height + 28 * (edge.childIndex + 1),
    };

    const y4 = {
      top: edge.y4 || nodeStart.y + nodeStart.height + 20,
      left: edge.y4 || nodeFinish.y + nodeFinish.height / 2 + 12 * (edge.parentIndex),
      bottom: edge.y4 || nodeFinish.y + nodeFinish.height + 28 * (edge.childIndex + 1),
    };

    const y6 = {
      top: nodeFinish.y - 8,
      left: nodeFinish.y + nodeFinish.height / 2 + 12 * (edge.parentIndex),
      bottom: nodeFinish.y + nodeFinish.height + 12,
    };

    const x3 = {
      top: edge.x3 || nodeFinish.x + nodeFinish.width / 2 - 20 * (edge.parentIndex),
      left: edge.x3 || x1 + 20 * (edge.parentIndex),
      bottom: edge.x3 || nodeFinish.x + nodeFinish.width / 2,
    };

    const x4 = {
      top: edge.x4 || nodeFinish.x + nodeFinish.width / 2 - 20 * (edge.parentIndex),
      left: edge.x4 || x1 + 20 * (edge.parentIndex),
      bottom: edge.x4 || nodeFinish.x + nodeFinish.width / 2,
    };

    const x6 = {
      top: nodeFinish.x + nodeFinish.width / 2 - 20 * (edge.parentIndex),
      left: nodeFinish.x - 8,
      bottom: nodeFinish.x + nodeFinish.width / 2 - 20 * (edge.parentIndex),
    };

    return (
      {
        type: 'verticalBottom',
        id: edge.id,
        x1: x1,
        y1: nodeStart.y + nodeStart.height + 12,
        x2: x1,
        y2: y2[edge.endPlace],
        x3: x3[edge.endPlace],
        y3: y3[edge.endPlace],
        x4: x4[edge.endPlace],
        y4: y4[edge.endPlace],
        x5: x6[edge.endPlace],
        y5: y4[edge.endPlace],
        x6: x6[edge.endPlace],
        y6: y6[edge.endPlace],
      }
    );
  }

  createConnections = async (edges) => {
    const { nodes, isStored } = this.state;

    await edges.map((edge) => {
      const sourceNode = nodes[edge.source];
      const targetNode = nodes[edge.target];

      if (sourceNode && targetNode) {
        return sourceNode && targetNode && this.connectNodes(sourceNode, targetNode, edge);
      } else return null;
    });

    if (!isStored) {
      this.props.storeDiagram(this.diagram.toDataURL());
      this.setState({
        isStored: true,
      });
    }
  }

  updateNodePosition = (node) => {
    const { id } = node;

    this.setState(prevState => ({
      nodes: {
        ...prevState.nodes,
        [id]: {
          x: node.x,
          y: node.y,
          height: node.height,
          width: node.width,
          depthIndex: node.depthIndex,
          isFirst: node.isFirst,
          isLast: node.isLast,
        },
      },
      connections: [],
    }));
  }

  getIOChHeight = () => {
    const { ioch } = this.props;
    const columnNodes = ioch.nodes.map(({ included_nodes }) => {
      return included_nodes.reduce((acc, { therapies }) => {
        therapies.length > 0 ? therapies.map((therapy) =>
          therapy.variants.length ? acc = acc + therapy.variants.length * 200 : acc = acc + 350)
          : acc = acc + 200;
        return acc;
      }, 0);
    });

    return Math.max(...columnNodes);
  }

  getPrevievIOChHeight = () => {
    const { ioch } = this.props;
    const columnNodes = ioch.nodes.map(({ included_nodes }) => {
      return included_nodes.reduce((acc, { therapies }) => {
        therapies.length > 0 ? therapies.map((therapy) =>
          therapy.variants.length ? acc = acc + therapy.variants.length * 200 : acc = acc + 350)
          : acc = acc + 200;
        return acc;
      }, 0);
    });

    return Math.max(...columnNodes);
  }

  zoomOutStage = () => {
    const { initialDiagramWidth } = this.state;

    initialDiagramWidth && this.diagram.setAttr('width', initialDiagramWidth);
    this.diagram.offsetX(0);

    const scaleX = window.innerWidth / this.diagram.attrs.width;
    const scaleY = window.innerHeight / this.diagram.attrs.height;

    const scale = (scaleX < 1 && scaleX) || (scaleY < 1 && scaleY) || 1;

    this.setState({
      scale: scale,
      previewScale: this.getIOChHeight() / this.getPrevievIOChHeight(),
      isLoading: false,
    });

    this.props.onZoomOut();
  }

  setActiveNode = (id, column) => {
    this.props.setActiveColumn(column);
    this.setState({
      scale: 1,
      activeNode: id,
      activeColumn: column,
    });
  }

  scrollToColumn = (id) => {
    this.setState({
      scale: 1,
    }, () => {
      if (this.props.ioch.nodes[id || this.props.activeColumn
        || (this.props.location.state && this.props.location.state.activeColumn)]) {
        this.scrollToNode(this.props.ioch.nodes[id || this.props.activeColumn
          || this.props.location.state.activeColumn].included_nodes[0].id);
      } else {
        this.scrollToNode(this.props.ioch.nodes[0].included_nodes[0].id);
      }
    });
  }

  scrollToNode = (id) => {
    this.diagram.setAttr('width', window.innerWidth);

    const node = this.diagram.find(node => {
      return node.getId() === id;
    })[0];

    const childPosition = node.getAbsolutePosition().y;
    const descriptionHeight = document.querySelector('.IOCh__description').offsetHeight;
    const topOffset = this.props.offset
      || document.querySelector('.Diagram').offsetTop - (descriptionHeight === 0 ? 50 : descriptionHeight);

    const columnParent = node.getAncestors().filter(ancestor => /col-.*$/.test(ancestor.getId()));
    const columnPosition = columnParent[0].attrs.x;

    const scroll = columnPosition - window.innerWidth / 2 + this.columnWidth / 2;

    this.setState({ offsetX: scroll });
    this.diagram.offsetX(scroll);

    topOffset && window.scrollTo({
      top: childPosition - topOffset,
      duration: .5,
      behavior: 'smooth',
    });
  }

  handleBackLink = async () => {
    const { location } = this.props;

    await this.preparePrintImage();

    if (location.state && location.state.activeNode) {
      this.setState({ scale: 1 }, () => {
        const activeNode = location.state.activeNode;
        const activeColumn = location.state.activeColumn;

        this.setState({
          returnFlag: true,
        }, () => {

          this.setActiveNode(activeNode);
          this.scrollToNode(activeNode);
          activeColumn && this.props.setActiveColumn(activeColumn);

          this.props.history.replace({ state: '' });
        });
      });
    }
  }

  renderRootNode = (id, node) => {
    return (
      <DiagramRoot
        key={ id }
        id={ id }
        name={ node.name }
        setActiveNode={ this.setActiveNode }
        scrollToNode={ this.scrollToNode }
        description={ node.description }
      />
    );
  }

  renderNode = (id, slug, name, description, hospitals, isWrapped, column) => {
    return (
      <DiagramNode
        key={ id }
        id={ id }
        slug={ slug }
        category={ this.props.category }
        mutation={ this.props.slug }
        name={ name }
        column={ column }
        description={ getStripString(description || '') }
        hospitals={ hospitals }
        isWrapped={ isWrapped }
        activeNode={ this.state.activeNode }
        activeColumn={ this.props.activeColumn }
        activeCategory={ this.props.activeCategory }
        setActiveNode={ this.setActiveNode }
        scrollToNode={ this.scrollToNode }
      />
    );
  }

  renderVariants = (id, name, children, column) => {
    return (
      <DiagramNodeVariants
        id={ id }
        key={ id }
        name={ name }
        activeNode={ this.state.activeNode }
        setActiveNode={ this.setActiveNode }
        activeColumn={ column }
        scrollToNode={ this.scrollToNode }
      >
        { children.map(({ id, slug, name, description, hospitals_count }) =>
          this.renderNode(id, slug, name, description, hospitals_count, true, column))
        }
      </DiagramNodeVariants>
    );
  }

  renderBlock = (id, therapy, column) => {
    const { name, slug, variants, description, hospitals_count } = therapy;

    if (therapy.variants.length) {
      return (
        this.renderVariants(
          id,
          name,
          variants,
          column
        )
      );
    }

    return this.renderNode(
      id,
      slug,
      name,
      description,
      hospitals_count,
      false,
      column
    );
  }

  renderColumn = (nodes) => {
    return (
      nodes.map(({ therapies }, index) => {
        const { depth_index, id } = nodes[0];
        const node = therapies[0];

        if (depth_index === 0) {
          return this.renderRootNode(id, node);
        }

        if (therapies.length > 1) {
          return (
            <DiagramNodeGroup
              id={ nodes[index].id }
              key={ nodes[index].id }
              activeNode={ this.state.activeNode }
            >
              { therapies.map((therapy) => (
                therapy && this.renderBlock(therapy.id, therapy, depth_index)
              )) }
            </DiagramNodeGroup>

          );
        }

        if (node) {
          return this.renderBlock(nodes[index].id, node, depth_index);
        }

        return null;
      })
    );
  }

  render() {
    const { connections, scale, activeNode, offsetX, isLoading } = this.state;
    const { ioch } = this.props;

    const depth = ioch.nodes.length;
    const diagramWidth = ioch.width ? depth * ioch.width : depth * 750;
    const diagramHeight = this.getIOChHeight() + 160;

    const diagramClass = cn([
      'Diagram',
      { 'Diagram--scrollable': activeNode || scale === 1 },
    ]);

    return (
      <>
        { isLoading && <Loader isCover className="Diagram__loader" /> }
        <Stage
          width={ diagramWidth * scale }
          height={ diagramHeight * scale }
          className={ diagramClass }
          ref={ stage => this.diagram = stage }
          scaleX={ scale }
          scaleY={ scale }
        >
          <Layer>
            { ioch.nodes.map((node, index) => (
              <DiagramColumn
                x={ diagramWidth / depth * index }
                y={ 0 }
                height={ diagramHeight - 80 }
                width={ diagramWidth / depth }
                updateNodePosition={ this.updateNodePosition }
                depthIndex={ node.depth_index }
                includedNodes={ node.included_nodes }
                id={ `col-${index}` }
                key={ index }
                activeNode={ activeNode }
                offsetX={ offsetX }
              >
                { this.renderColumn(node.included_nodes) }
              </DiagramColumn>
            )) }

            { connections.length > 0 &&
              <DiagramConnections
                connections={ connections }
                isPrinting={ this.state.isPrinting }
              /> }
          </Layer>
        </Stage>
      </>
    );
  }
}

export default withRouter(Diagram);
