import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './style.scss';

import getApiPath from '../../../utils/getApiPath';
import removeTrailingLetters from '../../../utils/removeTrailingLetters';
import ZoomOutIcon from '../../svg/ZoomOutIcon';
import FacebookIcon from '../../svg/FacebookShareIcon';
import InstagramIcon from '../../svg/InstagramShareIcon';
import ReactTooltip from 'react-tooltip';
import LetterIcon from '../../svg/LetterShareIcon';
import getStripString from '../../../utils/getStripString';

import Button from '../../Button';
import clientManagerInstance from '../../../providers/clientProvider';
import withNotification from '../../_enhancers/withNotification';
import Loader from '../../Loader';
import getNotificationContent from '../../../utils/getNotificationContent';
import getRoutePath from '../../../utils/getRoutePath';

class Tip extends Component {
  constructor(props) {
    super(props);
    this.client = props.client || clientManagerInstance.client;

    const state = {
      isLoading: false,
      article: null,
      moreTips: null,
      isHovered: false,
      error: null,
    };

    this.state = state;
  }

  componentDidMount() {
    this.getTip(this.props.slug);
  }

  componentDidUpdate(prevProps) {
    if (this.props.slug !== prevProps.slug) {
      this.setState({ isLoading: true });
      this.getTip(this.props.slug);
    }
  }

  getTip = async (slug) => {
    try {
      const res = await this.client.get(getApiPath('CONTENT_TIP', slug));

      this.setState({
        article: res.data.content.article,
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        error: e.response ? e.response.statusText : e,
      });
    }
  }

  handleHover = () => {
    this.setState(prevState => ({ isHovered: !prevState.isHovered }));
  }

  renderTip = () => {
    const { article } = this.state;
    const { location } = this.props;
    const backLink = location.state ? location.state.prevPath : getRoutePath('TIPS');
    const url = window.location.href;
    const firstParagraphRegexp = article.text.match(/<div>([^<]*)<\/div>/);
    const firstParagraph = firstParagraphRegexp ? firstParagraphRegexp[0] : '';
    const restContent = article.text.replace(firstParagraph, '');

    const facebookUrl = `https://www.facebook.com/sharer.php?u=${url}`;
    const twitterUrl = `https://twitter.com/share?url=${url}&text=${article.title}`;

    return (
      <>
        <Helmet>
          <title>{ article.title }</title>
          <meta name="description" content={ getStripString(article.text) } />

          <meta property="og:title" content={ `${article.title}` } />
          <meta property="og:type" content="article" />
          <meta property="og:description" content={ getStripString(article.text) } />
          <meta property="og:image" content={ article.image_url } />
          <meta property="og:image:secure_url" content={ article.image_url } />
          <meta property="og:image:width" content="600px" />
          <meta property="og:image:height" content="314px" />

          <meta name="twitter:title" content={ `${article.title}` } />
          <meta name="twitter:description" content={ getStripString(article.text) } />
          <meta name="twitter:image" content={ article.image_url } />
          <script type="application/ld+json">
            { `
            {
              "@context": "http://schema.org",
              "@type": "Tip",
              "articleBody": "${getStripString(article.text)}",
              "name": "${article.title}",
              "headline": "${article.title}",
              "image": "${article.image_url}",
              "author": {
                "@type": "Person",
                "name": "${article.author_full_name}",
                "jobTitle": "${article.author_position}"
              }
            }
          ` }
          </script>
        </Helmet>

        <article className="Tip">
          <section className="Tip__container">
            <div className="Tip__nav-actions">
              <Link
                to={ { pathname: backLink } }
                className="Tip__link"
              >
                Wróć
              </Link>

              <div className="Tip__social-media">
                <div className={ `Tip__sharing ${!this.state.isHovered ? 'Tip__sharing--hidden' : ''}` }>
                  <Button
                    className="Tip__button"
                    icon
                    href={ twitterUrl }
                    target="_blank"
                    onClick={ this.handleHover }
                  >
                    <InstagramIcon className="Tip__icon" data-tip="Udostępnij na Twitterze" />
                    <ReactTooltip place="top" type="light" />
                  </Button>
                  <Button
                    className="Tip__button"
                    href={ facebookUrl }
                    target="_blank"
                    onClick={ this.handleHover }
                    icon
                  >
                    <FacebookIcon className="Tip__icon" data-tip="Udostępnij na Facebooku" />
                  </Button>
                  <CopyToClipboard text={ window.location.href }>
                    <Button
                      className="Tip__button"
                      icon
                      onClick={ () => {
                        this.props.toggleNotification(getNotificationContent('diagram_link_copied'));
                        this.handleHover();
                      } }
                    >
                      <LetterIcon className="Tip__icon" data-tip="Skopiuj link" />
                    </Button>
                  </CopyToClipboard>
                </div>
                <Button
                  className="Tip__button Tip__button--margined"
                  onClick={ this.handleHover }
                  icon
                >
                  <ZoomOutIcon />
                </Button>
              </div>
            </div>

            <div className="Tip__header">
              <h1 className="Tip__title">{ article.title }</h1>
              { article.author_full_name &&
                <div className="Article__author Article__author--wide">
                  <h5 className="Article__heading">odpowiada:</h5>
                  <div>
                    <h4 className="Article__bio-name" style={ { textAlign: 'center' } }>
                      { article.author_full_name }
                    </h4>
                    <h3 className="Article__bio-position">{ article.author_position }</h3>
                  </div>
                </div>
              }
            </div>

            <div
              className="Tip__text"
              dangerouslySetInnerHTML={ { __html: firstParagraph && removeTrailingLetters(firstParagraph) } }
            />

            <div className="Tip__image">
              <div className="Tip__image-wrapper" style={ { backgroundImage: `url(${article.image_url})` } } />
            </div>

            <div
              className="Tip__text"
              dangerouslySetInnerHTML={ { __html: removeTrailingLetters(restContent) } }
            />

            { article.suffix && (
              <div className="Story__annotation">
                <img
                  className="Story__annotation-image"
                  src={ article.source_image_url }
                  alt=""
                />
                <div
                  className="Story__annotation-text"
                  dangerouslySetInnerHTML={ { __html: removeTrailingLetters(article.suffix) } }
                />
              </div>
            ) }
          </section>

          <Button
            className="Tip__button Tip__button--main"
            large
            to={ getRoutePath('CATEGORIES') }
          >
            Zobacz jak się leczyć
          </Button>
        </article>
      </>
    );
  }

  render() {
    const { article, isLoading } = this.state;

    return (
      article && !isLoading ? this.renderTip() : <Loader />
    );
  }
}

export default withRouter(withNotification(Tip));
