import React from 'react';
import ContentProviderInstance from '../../../providers/contentProvider';

import Loader from '../../Loader';

export default function withContent(
  WrappedComponent,
  apiPath,
  ErrorComponent = null,
  ContentProvider = ContentProviderInstance
) {
  return class extends React.Component {
    mounted = true;

    state = {
      content: undefined,
      error: null,
      isLoading: false,
    };

    componentDidMount() {
      this.getData();
      if(window.location.search.includes('token')) {
        window.dataLayer.push({ 'event': 'register_free_activate' });
      }
    }

    componentWillUnmount() {
      this.mounted = false;
    }

    getData = async () => {
      this.setState({ isLoading: true });

      try {
        const details = await ContentProvider.getContent(apiPath);

        if(this.mounted) {
          this.setState({
            content: {
              meta: {
                description: '',
                image_url: '',
              },
              ...details,
            },
            error: null,
            isLoading: false,
          });
        }
      } catch (error) {
        if(this.mounted) {
          this.setState({
            error,
            content: undefined,
            isLoading: false,
          });
        }
      }
    };

    render() {
      const { error, content, isLoading } = this.state;

      if(isLoading) {
        return (<Loader isStrech/>);
      }

      if(content){
        return <WrappedComponent content={ content } { ...this.props }/>;
      }

      if(error && ErrorComponent){
        return <ErrorComponent error={ error } />;
      }
      return null;
    }
  };
}
