import Dexie from 'dexie';
import { camelCase, kebabCase } from 'lodash';

class StorageProvider {
  constructor (storage) {
    this.storage = storage || this.generateStorage();
  }

  generateStorage() {
    const db = new Dexie('db');

    db.version(1).stores({ // increase version number whenever changing db structure
      content: 'url, updated_at, details',
      authHeaders: ' accessToken, uid, client',
      ioch: 'url',
    });

    return db;
  }

  getContentItem = async (key, val) => {
    const item = await this.storage.content.where(key).equals(val).first();
    return item;
  };

  putContentItem = async (item) => {
    this.storage.content.put(item);
  };

  getAuthItem = async () => {
    const existingHeaders = await this.storage.authHeaders.toArray();
    const freshHeaders = this.freshHeaders();

    const authHeaders = Object.keys(freshHeaders).length > 0 ? [freshHeaders]: existingHeaders;
    
    if(!authHeaders.length) {
      return {};
    }

    return Object.entries(authHeaders[0]).reduce((acc, [key, val]) => {
      acc[kebabCase(key)] = val;
      return acc;
    }, {});
  };

  freshHeaders() {
    const headers = this.getAllUrlParams(window.location.search);
    if(headers['uid']) {
      headers['uid'] = decodeURIComponent(headers['uid']);
    }

    return headers;
  }

  putAuthItem = async (authHeaders) => {
    await this.storage.authHeaders.clear();

    const authHeadersCamelCased = Object.entries(authHeaders).reduce((acc, [key, val]) => {
      acc[camelCase(key)] = val;

      return acc;
    }, {});

    await this.storage.authHeaders.put(authHeadersCamelCased);
  };

  clearAuthItem = async () => {
    await this.storage.authHeaders.clear();
  }

  putFavoritedIOCh = async (url) => {
    await this.clearFavoritedIOCh();
    this.storage.ioch.put(url);
  }

  clearFavoritedIOCh = async () => {
    this.storage.ioch.clear();
  }

  getAllUrlParams(url) {
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);
    var obj = {};
    if (queryString) {
      queryString = queryString.split('#')[0];
      var arr = queryString.split('&');
      for (var i = 0; i < arr.length; i++) {
        var a = arr[i].split('=');
        var paramName = a[0];
        var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

        paramName = paramName.toLowerCase();

        if (paramName.match(/\[(\d+)?\]$/)) {
          var key = paramName.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];

          if (paramName.match(/\[\d+\]$/)) {
            var index = /\[(\d+)\]/.exec(paramName)[1];
            obj[key][index] = paramValue;
          } else {
            obj[key].push(paramValue);
          }
        } else {
          if (!obj[paramName]) {
            obj[paramName] = paramValue;
          } else if (obj[paramName] && typeof obj[paramName] === 'string'){
            obj[paramName] = [obj[paramName]];
            obj[paramName].push(paramValue);
          } else {
            obj[paramName].push(paramValue);
          }
        }
      }
    }

    return obj;
  }
}

export { StorageProvider };

export default new StorageProvider();
