import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './style.scss';

import clientManagerInstance from '../../../providers/clientProvider';
import getApiPath from '../../../utils/getApiPath';
import getRoutePath from '../../../utils/getRoutePath';
import getStripString from '../../../utils/getStripString';
import removeTrailingLetters from '../../../utils/removeTrailingLetters';
import cookie from '../../../utils/cookieHelper';
import Loader from '../../Loader';
import Button from '../../Button';
import MapWrapper from '../../MapWrapper';
import placeholder from '../../../assets/images/jpg/hospital-placeholder.jpg';
import withNotification from '../../_enhancers/withNotification';

const propTypes = {
  slug: PropTypes.string.isRequired,
};

class Hospital extends Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);
    this.client = props.client || clientManagerInstance.client;

    const state = {
      isLoading: true,
      hospital: {},
      error: null,
    };

    this.state = state;
  }

  componentDidMount() {
    const { state } = this.props.location;

    this.getHospital(this.props.slug);

    if (cookie.get('isAuthenticated') === 'false') {
      cookie.set('userPath', getRoutePath('HOSPITAL', this.props.slug));
    }

    if (state && state.categorySlug) {
      cookie.set('categorySlug', state.categorySlug);
    }
  }

  getHospital = async (slug) => {
    try {
      const res = await this.client.get(getApiPath('HOSPITAL', slug));

      this.setState({
        hospital: res.data,
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        error: e.response ? e.response.statusText : e,
      });
    }
  }

  getBackLink = categorySlug => {
    const { location } = this.props;
    console.log(location.state && location.state.filters);

    return <Link
      to={ {
        pathname: getRoutePath('RANKS'),
        state: { categorySlug, filters: location.state && location.state.filters },
      } }
      className="Hospital__link"
    >
      Wróć
    </Link>;
  }

  render() {
    const { error, isLoading, hospital } = this.state;
    const { location } = this.props;

    const img = hospital.photo_url ? hospital.photo_url : placeholder;
    const backLink = location.state ? location.state.prevPath : getRoutePath('HOSPITALS');
    const filters = location.state && location.state.filters;
    const activeNode = location.state && location.state.activeNode;
    const activeColumn = location.state && location.state.activeColumn;
    const categorySlug = location.state && location.state.categorySlug;

    if (isLoading) {
      return (
        <section className="Hospital">
          <Loader />
        </section>
      );
    }

    if (error) {
      return <p>{ error }</p>;
    }

    return (
      <>
        <Helmet>
          <title>{ getStripString(hospital.name) }</title>
          <meta name="description" content={ getStripString(hospital.description) } />

          <meta
            property="og:title"
            content={ `${process.env.REACT_APP_PAGE_TITLE} | ${getStripString(hospital.name)}` }
          />
          <meta property="og:type" content="website" />
          <meta property="og:description" content={ getStripString(hospital.description) } />
          <meta property="og:image" content={ hospital.photo_url } />
          <meta property="og:image:secure_url" content={ hospital.photo_url } />
          <meta property="og:image:width" content="600px" />
          <meta property="og:image:height" content="314px" />

          <meta
            name="twitter:title"
            content={ `${process.env.REACT_APP_PAGE_TITLE} | ${getStripString(hospital.name)}` }
          />
          <meta name="twitter:description" content={ getStripString(hospital.description) } />
          <meta name="twitter:image" content={ hospital.photo_url } />

          <script type="application/ld+json">
            { `
              {
                "@context": "http://schema.org",
                "@type": "MedicalClinic",
                "address": "${hospital.address} ${hospital.address2}",
                "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": "${hospital.latitude}",
                  "longitude": "${hospital.longitude}"
                },
                "photo": "${hospital.photo_url}",
                "name": "${hospital.name}",
                "url": "${hospital.url}",
                "telephone": "${hospital.phone}"
              }
            ` }
          </script>
        </Helmet>


        <section className="Hospital">
          <div className="Hospital__container">
            <div className="Hospital__nav-actions">
              { cookie.get('categorySlug') ? this.getBackLink(cookie.get('categorySlug')) :
              <Link
                  to={ {
                    pathname: backLink,
                    state: { filters, activeNode, activeColumn, categorySlug },
                  } }
                  className="Hospital__link"
                >
                  Wróć
              </Link> }
            </div>

            <div className="Hospital__wrapper">
              <section className="Hospital__information">
                <img src={ img } alt={ hospital.slug } className="Hospital__image" />

                <div className="Hospital__information-wrapper">
                  <h3 className="Hospital__heading">Dane kontaktowe</h3>

                  <section className="Hospital__address">
                    { hospital.address }<br />
                    { hospital.address2 }
                  </section>

                  <section className="Hospital__phone">
                    <span>{ hospital.phone }</span>
                  </section>

                  <section className="Hospital__links">
                    <Button
                      href={ hospital.url }
                      target="_blank"
                      secondary
                      className="Hospital__links-button"
                    >
                      Strona www
                    </Button>
                    <Button
                      href={ `tel:${hospital.phone}` }
                      className="Hospital__links-button"
                    >
                      Telefon
                    </Button>
                  </section>
                </div>
              </section>

              <section className="Hospital__header">
                <h1 className="Hospital__name">{ getStripString(hospital.name) }</h1>

                <p className="Hospital__updated">
                  Data aktualizacji: { new Date(hospital.updated_at).toLocaleDateString(
                    'pl-pl',
                    {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    }
                  )
                  }
                </p>
              </section>

              <section className="Hospital__details">
                <div
                  className="Hospital__description"
                  dangerouslySetInnerHTML={ { __html: removeTrailingLetters(hospital.description) } }
                />

                <div className="Hospital__map">
                  <MapWrapper lat={ hospital.latitude } lng={ hospital.longitude } />
                </div>
              </section>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(withNotification(Hospital));
