import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

import LinkItem from './LinkItem';
import SocialItem from './SocialItem';

const propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
  social: PropTypes.bool,
};

const defaultProps = {
  social: false,
};

function FooterSection({ title, items, social }) {
  const navClasses = classnames({ 'Footer__social': social });
  const itemClasses = classnames('Footer__item', { 'Footer__social-item': social });

  return (
    <nav className="Footer__section">
      <h3 className="Footer__title">{ title }</h3>
      <ul className={ navClasses }>
        { items.map(item => (
          <li className={ itemClasses } key={ item.name }>
            { social ? <SocialItem item={ item } /> : <LinkItem item={ item } /> }
          </li>
        )) }
      </ul>
    </nav>
  );
}

FooterSection.propTypes = propTypes;
FooterSection.defaultProps = defaultProps;

export default FooterSection;
