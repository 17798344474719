const apiPaths = {
  CONTENT_TIMESTAMPS: () => 'api/content/timestamps',
  CURRENT_USER: () => '/api/content/current_user',
  CONTENT_HOME: () => 'api/content/home',
  CONTENT_PREMIUM: () => 'api/content/premium',
  CONTENT_ABOUT: () => 'api/content/about',
  CONTENT_TERMS: () => 'api/content/terms',
  CONTENT_PRIVACY_POLICY: () => 'api/content/privacy_policy',
  CONTENT_ARTICLES: () => 'api/content/articles',
  CONTENT_TIPS: () => 'api/content/tips',
  CONTENT_RANKS: () => 'api/content/ranks',
  CONTENT_ARTICLES_CATEGORIES: slug => `api/content/article_category/${slug}`,
  CONTENT_TIPS_CATEGORIES: slug => `api/content/tips_category/${slug}`,
  CONTENT_ARTICLE: slug => `api/content/articles/${slug}`,
  CONTENT_TIP: slug => `api/content/tips/${slug}`,
  CONTENT_FAQ: () => 'api/content/faq',
  CONTENT_STORIES: () => 'api/content/stories',
  CONTENT_STORY: slug => `api/content/stories/${slug}`,
  CONTENT_CONTACT: () => 'api/content/contact',
  HOSPITALS: () => 'api/hospitals',
  HOSPITALS_FILTER: () => 'api/hospitals/available_filters',
  HOSPITALS_FILTER_CITES: () => 'api/hospitals/cities_by_voivodeship',
  HOSPITALS_AUTOCOMPLETE: () => 'api/hospitals/autocomplete',
  HOSPITAL: slug => `api/hospitals/${slug}`,
  CATEGORIES: slug => `api/cancer_kinds/${slug}`,
  CATEGORIES_AUTOCOMPLETE: () => 'api/cancer_kinds/autocomplete',
  IOCh: ([slugs]) => `api/cancer_kinds/${slugs[0]}/cancer_mutations/${slugs[1]}`,
  THERAPY: ([slugs]) => `api/cancer_kinds/${slugs[0]}/cancer_mutations/${slugs[1]}/therapies/${slugs[2]}`,
};

export default function getApiPath(apiPathName, ...rest) {
  return apiPaths[apiPathName](rest);
}
