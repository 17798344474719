import React from 'react';
import { Link } from 'react-router-dom';

import './style.scss';

import cn from 'classnames';
import { truncate } from 'lodash';

import getRoutePath from '../../../../utils/getRoutePath';
import getStripString from '../../../../utils/getStripString';

import placeholder from '../../../../assets/images/jpg/hospital-placeholder.jpg';

function RankItem({ hospital, categorySlug }) {
  const { photo_url, name, address, address2, description, bcu, slug } = hospital;
  const className = cn('RankItem', bcu && 'RankItem--highlighted');
  const img = photo_url ? photo_url : placeholder;

  return (
    <li className={ className } key={ hospital.id }>
      <img src={ img } alt={ getStripString(name) } className="RankItem__image"/>

      <div className="RankItem__info">
        <Link
          to={ {
            pathname: getRoutePath('HOSPITAL', slug),
            state: {
              prevPath: getRoutePath('RANKS'),
              categorySlug: categorySlug,
            },
          } }
          className="RankItem__heading-link"
        >
          <h3 className="RankItem__name">{ getStripString(name) }</h3>
        </Link>

        <p className="RankItem__address">{ address }, { address2 }</p>

        <p className="RankItem__description">
          <span className="RankItem__text">
            { truncate(getStripString(description), {
              'length': window.innerWidth > 992 ? 75 : 110,
              'separator': ' ',
              'omission': '...',
            }) }
          </span>
        </p>
      </div>
    </li>
  );
}

export default RankItem;
