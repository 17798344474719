const routes = {
  HOME: () => '/',
  PREMIUM: () => '/premium',
  VOUCHER: () => '/voucher',
  ABOUT: () => '/o-nas',
  STORIES: () => '/historie-pacjentow',
  STORY: slug => `/historia/${slug}`,
  ARTICLES: slug => `/artykuly/${slug}`,
  TIPS: slug => `/przewodniki/${slug}`,
  RANKS: () => '/statystyki-zabiegow',
  ARTICLE: slug => `/artykul/${slug}`,
  TIP: slug => `/przewodnik/${slug}`,
  FAQ: () => '/faq',
  CONTACT: () => '/kontakt',
  TERMS: () => '/regulamin',
  PRIVACY_POLICY: () => '/polityka-prywatnosci',
  HOSPITALS: () => '/gdzie-sie-leczyc',
  HOSPITAL: slug => `/placowka/${slug}`,
  CATEGORIES: slug => `/instrukcje/${slug}`,
  IOCh: ([slugs])=> `/instrukcja/${slugs[0]}/${slugs[1]}`,
  THERAPY: ([slugs])=> `/instrukcja/${slugs[0]}/${slugs[1]}/${slugs[2]}`,
  SIGN_IN: () => '/logowanie',
  SIGN_UP: () => '/rejestracja',
  SIGN_UP_SUCCESS: () => '/sukces',
  FORGET_PASSWORD: () => '/przypomnij-haslo',
  PASSWORD_RESET: token => `/reset-hasla/${token}`,
  PROFILE: () => '/profil',
  PROFILE_PASSWORD: () => '/profil/haslo',
  PROFILE_SUBSCRIPTION: () => '/profil/subskrypcja',
  PROFILE_LIBRARY: () => '/profil/biblioteka',
  PROFILE_TRANSACTIONS: () => '/profil/transakcje',
  INVOICE_FORM: () => '/faktura',
  UPSIDE_DOWN: () => '/swiatdogorynogami',
};

export default function getRoutePath(routeName, ...rest) {
  return routes[routeName](rest);
}
