/* eslint-disable */

import React from 'react';

function ZoomOutIcon({ ...props }) {
  return (
    <svg height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg" { ...props }>
      <path d="m23.3333333 14 1.7888889 1.7888889-2.2477778 2.2322222 1.1044445 1.1044445 2.2322222-2.2477778 1.7888889 1.7888889v-4.6666667zm-9.3333333 4.6666667 1.7888889-1.7888889 2.2322222 2.2477778 1.1044445-1.1044445-2.2477778-2.2322222 1.7888889-1.7888889h-4.6666667zm4.6666667 9.3333333-1.7888889-1.7888889 2.2477778-2.2322222-1.1044445-1.1044445-2.2322222 2.2477778-1.7888889-1.7888889v4.6666667zm9.3333333-4.6666667-1.7888889 1.7888889-2.2322222-2.2477778-1.1044445 1.1044445 2.2477778 2.2322222-1.7888889 1.7888889h4.6666667z" fill="#00c5b8" transform="translate(-14 -14)"/>
    </svg>
  );
}

export default ZoomOutIcon;
