import React from 'react';

import './style.scss';

import getRoutePath from '../../../utils/getRoutePath';
import Button from '../../Button';

function NotFound() {
  return (
    <div className="NotFound">
      <div className="NotFound__wrapper">
        <h1 className="NotFound__code">404</h1>

        <h2 className="NotFound__title">Nie znaleziono strony</h2>

        { /* <h3 className="NotFound__text">
          Donec fringilla lorem a ligula vestibulum, id pretium justo commodo.
          Duis interdum ipsum ornare tellus ullamcorper egestas.
        </h3> */ }

        <Button large className="NotFound__return" to={ getRoutePath('HOME') }>Powrót do strony głównej</Button>
      </div>
    </div>
  );
}

export default NotFound;
