import React from 'react';

function RightArrow({ className }) {
  return (
    <svg width="8" height="12" xmlns="http://www.w3.org/2000/svg" className={ className }>
      <path d="M.205 10.59L4.532 6 .205 1.41 1.537 0l5.668 6-5.668 6z" fill="currentColor" fillRule="nonzero"/>
    </svg>
  );
}

export default RightArrow;
