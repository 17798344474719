import React from 'react';
import { withRouter } from 'react-router';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import Modal from 'react-modal';
import clientManagerInstance from '../../providers/clientProvider';
import Button from '../Button';
import CloseIcon from '../svg/CloseIcon';
import WrappedCheckbox from '../WrappedCheckbox';
import getApiPath from '../../utils/getApiPath';
import getNotificationContent from '../../utils/getNotificationContent';
import withNotification from '../_enhancers/withNotification';
import './style.scss';

const formSchema = Yup.object().shape({
  withdrawal_accepted: Yup.boolean()
    .required()
    .oneOf([true], getNotificationContent('withdrawal_terms_not_accepted')),
  digital_content_accepted: Yup.boolean()
    .required()
    .oneOf([true], getNotificationContent('digital_content_terms_not_accepted')),
  plan_group: Yup.string().required(),
});

const initialValues = {
  withdrawal_accepted: false,
  digital_content_accepted: false,
  plan_group: '113',
};

const modalStyles = {
  content : {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#7FE2DD',
    border: 'none',
    boxShadow: '0 4px 8px 0 #6e7699',
    margin: 0,
    width: '90%',
    maxHeight: '90%',
  },
  overlay: {
    zIndex: 1000,
  },
};

const banks = [
  { id: '113', name: 'Alior Bank SA', img: 'https://secure.tpay.com/_/g/113.png?4' },
  { id: '102', name: 'Bank Pekao SA', img: 'https://secure.tpay.com/_/g/102.png?4' },
  { id: '108', name: 'PKO Bank Polski', img: 'https://secure.tpay.com/_/g/108.png?4' },
  { id: '110', name: 'Inteligo', img: 'https://secure.tpay.com/_/g/110.png?4' },
  { id: '160', name: 'mBank', img: 'https://secure.tpay.com/_/g/160.png?4' },
  { id: '111', name: 'ING Bank Śląski SA', img: 'https://secure.tpay.com/_/g/111.png?4' },
  { id: '114', name: 'Bank Millennium SA', img: 'https://secure.tpay.com/_/g/114.png?4' },
  { id: '115', name: 'Santander Bank Polska SA', img: 'https://secure.tpay.com/_/g/115.png?4' },
  { id: '131', name: 'Eurobank', img: 'https://secure.tpay.com/_/g/131.png?4' },
  { id: '132', name: 'Citibank Handlowy SA', img: 'https://secure.tpay.com/_/g/132.png?4' },
  { id: '116', name: 'Credit Agricole Polska SA', img: 'https://secure.tpay.com/_/g/116.png?4' },
  { id: '119', name: 'Getin Bank SA', img: 'https://secure.tpay.com/_/g/119.png?4' },
  { id: '112', name: 'T-Mobile Usługi Bankowe', img: 'https://secure.tpay.com/_/g/112.png?4' },
  { id: '124', name: 'Bank Pocztowy SA', img: 'https://secure.tpay.com/_/g/124.png?4' },
  { id: '125', name: 'Bank Ochrony Środowiska SA', img: 'https://secure.tpay.com/_/g/125.png?4' },
  { id: '128', name: 'Idea Bank', img: 'https://secure.tpay.com/_/g/128.png?4' },
  { id: '135', name: 'Banki Spółdzielcze', img: 'https://secure.tpay.com/_/g/135.png?4' },
  { id: '133', name: 'Bank Gospodarki Żywnościowej SA', img: 'https://secure.tpay.com/_/g/133.png?4' },
  { id: '159', name: 'Neo Bank', img: 'https://secure.tpay.com/_/g/159.png?4' },
  { id: '139', name: 'Raiffeisen Bank Polska SA', img: 'https://secure.tpay.com/_/g/139.png?4' },
  { id: '130', name: 'Nest Bank', img: 'https://secure.tpay.com/_/g/130.png?4' },
  { id: '145', name: 'Plus Bank SA', img: 'https://secure.tpay.com/_/g/145.png?4' },
  { id: '137', name: 'Volkswagen Bank', img: 'https://secure.tpay.com/_/g/137.png?4' },
  { id: '109', name: 'Alior Raty', img: 'https://secure.tpay.com/_/g/109.png?4' },
  { id: '148', name: 'Euro Payment', img: 'https://secure.tpay.com/_/g/148.png?4' },
];

class PaymentModal extends React.Component {
  state = {
    isLoading: false,
  };

  subscribe = async (values) => {
    const { toggleNotification, location, activePlanId } = this.props;
    const returnPath = (location.state && location.state.prevPath) || location.pathname;
    const truncatedReturnPath = returnPath.replace(/^\/+/g, '');

    const data = {
      plan_id: activePlanId,
      return_path: truncatedReturnPath,
      ...values,
    };

    try {
      this.setState({ isLoading: true });
      const res = await clientManagerInstance.client.post(getApiPath('PAYMENT_REGISTRATION'), data);
      if (data.plan_id === 38) {
        window.dataLayer.push({ 'event': 'register_paid_12m' });
      } else {
        window.dataLayer.push({ 'event': 'register_paid_3m' });
      };
      window.location.href = res.data.payment_url;
    } catch (error) {
      this.setState({ isLoading: false });
      toggleNotification(getNotificationContent('p24_failed'), 'error');
    }
  };

  render() {
    const { modalIsOpen } = this.props;

    return (
      <Modal
        isOpen={ modalIsOpen }
        style={ modalStyles }
        ariaHideApp={ false }
      >
        <Formik
          validationSchema={ formSchema }
          onSubmit={ this.subscribe }
          initialValues={ initialValues }
        >
          { ({ errors, values, setFieldTouched, setFieldValue }) =>  {
            return (
              <Form className="form" noValidate>
                <CloseIcon className="PaymentModal__close-icon" onClick={ this.props.onModalClose } />
                <h2 className="PaymentModal__buy">
                  Wybierz kanał płatności i zaznacz potrzebne zgody poniżej:
                </h2>

                <h5 className="PaymentModal__prefix">
                  PŁATNOŚĆ KARTĄ:
                </h5>
                <div className="PaymentModal__plan-groups PaymentModal__plan-groups__short">
                  <div className={ `PaymentModal__plan-group PaymentModal__plan-group--longer
                    ${values.plan_group === '103' ? 'PaymentModal__plan-group--checked' : ''}` }>
                    <label htmlFor={ '103' }>
                      <input id={ '103' } type="radio" value={ '103' } key={ '103' } 
                        checked={ values.plan_group === '103' } onChange={ e => setFieldValue('plan_group', '103') } />
                      <img src={ 'https://secure.tpay.com/_/g/103.png?4' } alt="Karta" />
                    </label>
                  </div>
                </div>

                <h5 className="PaymentModal__prefix">
                  PŁATNOŚĆ PRZELEWEM / BLIKiem:
                </h5>
                <div className="PaymentModal__plan-groups">
                  { banks.map(bank => (
                    <div className={ `PaymentModal__plan-group
                      ${values.plan_group === bank['id'] ? 'PaymentModal__plan-group--checked' : ''}` } key={ bank.id }>
                      <label htmlFor={ bank['id'] }>
                        <input id={ bank['id'] } type="radio" value={ bank['id'] } key={ bank['id'] }
                          checked={ values.plan_group === bank['id'] }
                          onChange={ e => setFieldValue('plan_group', e.target.value) } />
                        <img src={ bank['img'] } alt={ bank['name'] } />
                      </label>
                    </div>
                )) }
                  <div className="PaymentModal__plan-group PaymentModal__plan-group--hidden"></div>
                  <div className="PaymentModal__plan-group PaymentModal__plan-group--hidden"></div>
                </div>

                <WrappedCheckbox
                  type="checkbox"
                  name="withdrawal_accepted"
                  id="withdrawal_accepted"
                  error={ errors.withdrawal_accepted }
                  checked={ values.withdrawal_accepted }
                  setFieldTouched={ setFieldTouched }
                >
                  <h5 className="PaymentModal__prefix PaymentModal__prefix--no-margin">
                    Wyrażam zgodę na wykonanie Usługi w pełni przed upływem terminu
                    na odstąpienie od Umowy. Oświadczam że zostałam/łem poinformowana/ny,
                    że w takim przypadku nie przysługuje mi prawo odstąpienia od Umowy.
                  </h5>
                </WrappedCheckbox>

                <WrappedCheckbox
                  type="checkbox"
                  name="digital_content_accepted"
                  id="digital_content_accepted"
                  error={ errors.digital_content_accepted }
                  checked={ values.digital_content_accepted }
                  setFieldTouched={ setFieldTouched }
                >
                  <h5 className="PaymentModal__prefix PaymentModal__prefix--no-margin">
                    Wyrażam zgodę na dostarczenie mi treści cyfrowych, które nie są
                    zapisane na nośniku materialnym przed upływem terminu na odstąpienie
                    od Umowy. Oświadczam że zostałam/łem poinformowana/ny, że w takim
                    przypadku nie przysługuje mi prawo odstąpienia od Umowy.
                  </h5>
                </WrappedCheckbox>

                <div className="PaymentModal__pay-wrapper">
                  <Button
                  dark
                  loading={ this.state.isLoading }
                  className="PaymentModal__pay"
                  type="submit"
                  >
                  Kupuję i płacę
                  </Button>
                </div>
              </Form>
            );
          } }
        </Formik>
      </Modal>
    );
  }
}

export default withNotification(withRouter(PaymentModal));
