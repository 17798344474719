import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import classnames from 'classnames';

import './style.scss';

const propTypes = {
  error: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  children: PropTypes.node.isRequired,
  tooltip: PropTypes.string,
  setFieldTouched: PropTypes.func.isRequired,
};

class WrappedCheckbox extends React.Component {
  handleTouch = () => {
    this.props.setFieldTouched(this.props.name, true);
  }

  render() {
    const { id, name, checked, children, tooltip, error, setFieldTouched, ...rest } = this.props;
    const wrappedCheckboxClasses = classnames(
      'WrappedCheckbox__box',
      {
        'WrappedCheckbox__box--checked': checked,
        'WrappedCheckbox__box--invalid': error,
      },
    );

    return (
      <label htmlFor={ name } className="WrappedCheckbox">
        <Field type="checkbox" id={ id } name={ name } { ...rest } hidden />

        <div className={ wrappedCheckboxClasses } />

        <span>{ children }</span>

        { tooltip && (
          <span className="WrappedCheckbox__tooltip" title={ tooltip }>i</span>
        ) }
      </label>
    );
  }
}

WrappedCheckbox.propTypes = propTypes;

export default WrappedCheckbox;
