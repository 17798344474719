import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './style.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '../../Button';
import removeTrailingLetters from '../../../utils/removeTrailingLetters';
import getRoutePath from '../../../utils/getRoutePath';
import copyIcon from '../../../assets/images/svg/copy.svg';
import { toast } from 'react-toastify';

function UpsideDownTabs() {
  const showToastConfirmation = () => {
    toast.info(<span>Link został skopiowany do schowka.</span>, { className: 'Home__notification' });
  };

  const scrollToInfo = () => {
    document.getElementsByClassName('UpsideDownMission__content')[0].scrollIntoView(true);
    window.scrollBy(0, -120);
  };

  return (
    <>
      <section className="UpsideDownTabs">
        <Tabs defaultIndex={ window.innerWidth < 992 ? 3 : 0 }>
          <TabList className="UpsideDownTabs__tab-list">
            <Tab
              className="Button UpsideDownTabs__tab"
              selectedClassName="UpsideDownTabs__tab--selected"
            >
              Mam raka
            </Tab>
            <Tab
              className="Button UpsideDownTabs__tab"
              selectedClassName="UpsideDownTabs__tab--selected"
            >
              Znam osobę chorą na raka
            </Tab>
            <Tab className="Button UpsideDownTabs__tab"
              selectedClassName="Button UpsideDownTabs__tab"
              onClick={ () => {
                document.getElementsByClassName('UpsideDownMission__content')[0]
                .scrollIntoView(true); window.scrollBy(0, window.innerWidth < 992 ? -180 : -140); }
              }
            >
              Po prostu chcę pomóc
            </Tab>
          </TabList>

          <TabPanel>
            <div className="UpsideDownTabs__content-area">
              <div className="UpsideDownTabs__content-area-panels">
                <h5 className="UpsideDownTabs__content-panel-title">Mam raka</h5>
                <div>
                  <p
                    className="UpsideDownTabs__content-area-panels-content"
                    dangerouslySetInnerHTML={ {
                      __html: removeTrailingLetters(
                        'Poznaj wszystkie metody leczenia i miejsca w Polsce, które oferują daną terapię.'
                      ),
                    } } />

                </div>
                <div>
                  <p
                    className="UpsideDownTabs__content-area-panels-content"
                    dangerouslySetInnerHTML={ {
                      __html: removeTrailingLetters(
                        'Dowiedz się, jakie mogą być dalsze etapy leczenia Twojej' +
                        ' choroby i czy są one refundowane w Polsce.'
                      ),
                    } } />

                </div>
              </div>

              <div className="UpsideDownTabs__content-panel-wrapper">
                <div className="UpsideDownTabs__content-panel UpsideDownTabs__content-panel--narrow">
                  <h5>Zarejestruj się za darmo w Mednavi</h5>
                  <Button
                    dark
                    className="UpsideDownTabs__button UpsideDownTabs__button--narrow"
                    to={ {
                      pathname: getRoutePath('HOME'),
                      state: {
                        categories: true,
                      },
                    } }
                  >
                    Zarejestruj się i poznaj metody leczenia
                  </Button>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="UpsideDownTabs__content-area">
              <div className="UpsideDownTabs__content-area-panels">
                <div>
                  <h5 className="UpsideDownTabs__content-panel-title">Znam osobę chorą na raka</h5>
                  <p
                    className="UpsideDownTabs__content-area-panels-content"
                    dangerouslySetInnerHTML={ {
                      __html: removeTrailingLetters(
                        'Dowiedz się - z pomocą przejrzystego schematu - jakimi metodami' +
                        ' leczy się nowotwór osoby, o którą się martwisz.'
                      ),
                    } } />

                  <div className="UpsideDownTabs__content-panel">
                    <h5>Zarejestruj się za darmo w Mednavi</h5>
                    <Button
                      dark
                      className="UpsideDownTabs__button"
                      to={ {
                        pathname: getRoutePath('HOME'),
                        state: {
                          categories: true,
                        },
                      } }
                    >
                      Zarejestruj się<br />i poznaj metody leczenia
                    </Button>
                  </div>
                </div>
                <div>
                  <p
                    className="UpsideDownTabs__content-area-panels-content"
                    dangerouslySetInnerHTML={ {
                      __html: removeTrailingLetters(
                        'Zobacz, jakie terapie są w Polsce dostępne bezpłatnie' +
                        ' i jakie szpitale się w nich specjalizują.'
                      ),
                    } } />

                  <div className="UpsideDownTabs__content-panel">
                    <h5 dangerouslySetInnerHTML={ {
                      __html: removeTrailingLetters(
                        'Prześlij jej link do Mednavi, by dowiedziała się więcej'
                      ),
                    } } />

                    <div className="UpsideDownTabs__send-link">
                      <CopyToClipboard text="https://mednavi.pl">
                        <div className="UpsideDownTabs__fake-input" style={ { cursor: 'pointer' } }
                          onKeyDown={ showToastConfirmation }
                          onClick={ showToastConfirmation }
                          role="menuitem"
                          tabIndex={ 0 }
                          >
                          <h5 className="UpsideDownTabs__fake-input-heading">https://mednavi.pl</h5>
                        </div>
                      </CopyToClipboard>

                      <CopyToClipboard text="https://mednavi.pl" onClic={ scrollToInfo }>
                        <img src={ copyIcon } alt="Skopiuj do schowka" className="UpsideDownTabs__send-link-icon"/>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>

              <div className="UpsideDownTabs__button-area">
                <Button
                  dark
                  className="UpsideDownTabs__button-reg"
                  onClick={ scrollToInfo }
                >
                  Lub dołącz do naszej akcji
                </Button>
              </div>
            </div>
          </TabPanel>
        </Tabs>
      </section>
    </>
  );
}

export default UpsideDownTabs;
