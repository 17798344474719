import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import './style.scss';

import removeTrailingLetters from '../../../utils/removeTrailingLetters';

const propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired, //richtext
  }).isRequired,
};

function Policy({ content }) {
  const { title, subtitle, text } = content;

  return (
    <div className="Policy">
      <Helmet>
        <title>{ title }</title>
      </Helmet>

      <section className="Policy__content-wrapper">
        <div className="Policy__header-wrapper">
          <h1 className="Policy__heading">{ title }</h1>
          <h3 className="Policy__subheading">{ subtitle }</h3>
        </div>

        <div className="Policy__content" dangerouslySetInnerHTML={ { __html: removeTrailingLetters(text) } }/>
      </section>
    </div>
  );
}

Policy.propTypes = propTypes;

export default Policy;
