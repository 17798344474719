import React from 'react';
import classnames from 'classnames';

import './style.scss';

import removeTrailingLetters from '../../../utils/removeTrailingLetters';

import CloseIcon from '../../svg/CloseIcon';

export default function withNotification(
  WrappedComponent
) {
  return class extends React.Component {
    mounted = true;

    state = {
      showPopup: false,
      notificationMsg: '',
      type: '',
      closedByUser: false,
    };

    componentWillUnmount() {
      this.mounted = false;
    }

    showNotification = (notificationMsg: '', type: '', onClose: null) => {
      this.onClose = onClose;
      if(this.mounted) {
        this.setState(({
          showPopup: true,
          notificationMsg,
          type,
        }), () => {
          setTimeout(() => {
            (!this.state.closedByUser && this.state.showPopup)
              ? this.hideNotification()
              : this.setState({ closedByUser: false });
          }, 8000);
        });
      }
    }

    hideNotification = (closedByUser: false) => {
      this.setState({
        showPopup: false,
        closedByUser,
      });
      this.onClose && this.onClose();
    }

    render() {
      const { showPopup, notificationMsg, type } = this.state;

      const error = type === 'error';

      const notificationClasses = classnames(
        'Notification',
        {
          'Notification--show': showPopup,
          'Notification--error': error,
        }
      );


      return (
        <>
          <div className={ notificationClasses }>
            <div
              className="Notification__msg"
              dangerouslySetInnerHTML={ { __html: removeTrailingLetters(notificationMsg) } }
            />
            <div className="Notification__button">
              <CloseIcon
                className={ classnames('Notification__close', { 'Notification__close--light': error }) }
                onClick={ () => this.hideNotification(true) }
              />
            </div>
          </div>
          <WrappedComponent toggleNotification={ this.showNotification } { ...this.props } />
        </>
      );
    }
  };
}
