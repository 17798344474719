import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { map, filter } from 'lodash/collection';

import './style.scss';

import withContent from '../../_enhancers/withContent';
import getApiPath from '../../../utils/getApiPath';
import removeTrailingLetters from '../../../utils/removeTrailingLetters';
import getStripString from '../../../utils/getStripString';

import Accordion from '../../Accordion';
import FaqSearch from '../../_landingComponents/FaqSearch';

const propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    search_placeholder: PropTypes.string.isRequired,
    search_button_label: PropTypes.string.isRequired,
    sections: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({
        question: PropTypes.string.isRequired,
        answer: PropTypes.string.isRequired, //richText
      })),
    })),
  }).isRequired,
};

class Faq extends Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    this.state = {
      sections: props.content.sections,
    };
  }

  onSearch = (query) => {
    const { sections } = this.props.content;

    const filteredSections = map(sections, (section) => {
      let res = filter(section.items, function(item) {
        return item.question.toLowerCase().match(query);
      });

      return { title : section.title, items: res };
    });

    this.setState({
      sections: query ? filteredSections : sections,
    });
  }

  render() {
    const { title, meta } = this.props.content;
    const { sections } = this.state;

    return (
      <div className="Faq">
        <Helmet>
          <title>FAQ - Najczęściej Zadawane Pytania</title>

          <meta name="description" content={ meta.description } />

          <meta property="og:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | FAQ` } />
          <meta property="og:description" content={ meta.description } />
          <meta property="og:image" content={ meta.image_url } />
          <meta property="og:image:secure_url" content={ meta.image_url } />
          <meta property="og:image:width" content="600px" />
          <meta property="og:image:height" content="314px" />

          <meta name="twitter:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | FAQ` } />
          <meta name="twitter:description" content={ meta.description } />
          <meta name="twitter:image" content={ meta.image_url } />

          <script type="application/ld+json">
            { `
              {
                "@context": "http://schema.org",
                "@type": "FAQPage",
                "@graph": [${
                  sections.map(section => (
                    section.items.map(item => (`
                      {
                        "@type": "Question",
                        "text": "${item.question}",
                        "acceptedAnswer": {
                          "@type": "Answer",
                          "text": "${getStripString(item.answer)}"
                        }
                      }
                    `))
                  ))
                }]}
            ` }
          </script>
        </Helmet>

        <FaqSearch title={ title } onSearch={ this.onSearch }/>

        <div className="Faq__container">
          { sections.map(faq => (
            <section key={ faq.title } className="Faq__section">
              <h2 className="Faq__title">{ faq.title }</h2>

              { faq.items.map(item => (
                <Accordion title={ item.question } key={ item.question }>
                  <div dangerouslySetInnerHTML={ { __html: removeTrailingLetters(item.answer) } }/>
                </Accordion>
              )) }

              { !faq.items.length && (
                <span>brak wyników</span>
              ) }
            </section>
          )) }
        </div>
      </div>

    );
  }
}

export { Faq };
export default withContent(Faq, getApiPath('CONTENT_FAQ'));
