import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import './style.scss';

import Loader from '../Loader';

const propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  href: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.bool,
  large: PropTypes.bool,
  secondary: PropTypes.bool,
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

const defaultProps = {
  children: null,
  onClick: null,
  href: null,
  title: '',
  text: false,
  large: false,
  secondary: false,
  dark: false,
  disabled: false,
  loading: false,
};

function Button({ href, to, className, children, text, large, secondary, dark,
  disabled, loading, icon, title, ...props }) {
  const variantsClasses = {
    'Button--text': text,
    'Button--large': large,
    'Button--secondary': secondary,
    'Button--dark': dark,
    'Button--disabled': disabled,
    'Button--icon': icon,
  };
  const classes = cn(className, 'Button', variantsClasses);
  const elementTitle = title || children;

  if (to) {
    return <Link { ...props } className={ classes } to={ to } title={ elementTitle }>{ children }</Link>;
  }

  if (href) {
    return <a { ...props } className={ classes } href={ href } title={ elementTitle }>{ children }</a>;
  }

  return <button type="button" { ...props } className={ classes } disabled={ disabled } title={ elementTitle }>
    <span className={ cn({ 'Button__content--hidden': loading }) }>{ children }</span>
    { loading && <Loader isInline /> }
  </button>;
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
