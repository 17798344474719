import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const propTypes = {
  council: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    people: PropTypes.arrayOf(PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      full_name: PropTypes.string.isRequired,
      specialization: PropTypes.string.isRequired,
    })).isRequired,
  }),
};

function Council({ council }) {
  return (
    <section className="Council">
      <div className="Council__container">
        <h2 className="Council__heading">{ council.heading }</h2>
        <h3 className="Council__subheading">{ council.subheading }</h3>

        <div className="Council__wrapper">
          { council.people.map((councilor, i) => (
            <figure className="Council__councilor" key={ i }>
              <img src={ councilor.avatar_url } alt="" className="Council__councilor-avatar"/>
              <figcaption className="Council__councilor-bio">
                <h3 className="Council__councilor-name">{ councilor.full_name }</h3>
                <h4 className="Council__councilor-position">{ councilor.specialization }</h4>
              </figcaption>
            </figure>
          )) }
        </div>
      </div>
    </section>
  );
}

Council.propTypes = propTypes;

export default Council;
