/* eslint-disable */
import React from 'react';

function InstagramIcon({ ...props }) {
  return (
    <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" { ...props } >
      <g fill="none" fillRule="evenodd">
        <path d="M.5 16c0 8.56 6.94 15.5 15.5 15.5 8.56 0 15.5-6.94 15.5-15.5C31.5 7.44 24.56.5 16 .5 7.44.5.5 7.44.5 16z" stroke="currentColor"/>
        <path d="M23.58 10.757c.197.507.333 1.088.37 1.942.044.853.05 1.125.05 3.301s-.012 2.448-.05 3.301c-.037.847-.173 1.435-.37 1.942a3.91 3.91 0 0 1-.922 1.415 3.91 3.91 0 0 1-1.415.922c-.507.197-1.088.333-1.942.37-.853.044-1.125.05-3.301.05s-2.448-.012-3.301-.05c-.847-.037-1.435-.173-1.942-.37a3.91 3.91 0 0 1-1.415-.922 3.91 3.91 0 0 1-.922-1.415c-.197-.507-.333-1.088-.37-1.942C8.005 18.448 8 18.176 8 16s.012-2.448.05-3.301c.037-.847.173-1.435.37-1.942a3.91 3.91 0 0 1 .922-1.415 3.91 3.91 0 0 1 1.415-.922c.507-.197 1.088-.333 1.942-.37C13.552 8.005 13.824 8 16 8s2.448.012 3.301.05c.847.037 1.435.173 1.942.37.525.204.97.476 1.415.922.446.445.718.89.922 1.415zm-1.07 8.476c.037-.84.05-1.1.05-3.233s-.013-2.386-.05-3.233c-.037-.78-.167-1.2-.278-1.484a2.505 2.505 0 0 0-.6-.921 2.478 2.478 0 0 0-.921-.6c-.278-.111-.705-.241-1.484-.278-.84-.044-1.1-.05-3.233-.05s-2.387.013-3.234.05c-.779.037-1.199.167-1.483.278a2.505 2.505 0 0 0-.922.6c-.278.278-.457.55-.6.92-.11.28-.24.706-.277 1.485-.044.84-.05 1.1-.05 3.233s.012 2.386.05 3.233c.037.78.167 1.2.278 1.484.148.377.321.643.6.921.278.279.55.458.92.6.279.111.705.241 1.484.278.841.044 1.095.05 3.234.05 2.139 0 2.386-.013 3.233-.05.78-.037 1.2-.167 1.484-.278.377-.148.643-.321.921-.6.278-.278.458-.55.6-.92.111-.28.241-.706.278-1.485zM16 12c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4zm0 6.598A2.603 2.603 0 0 0 18.598 16 2.6 2.6 0 1 0 16 18.598zM20 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" fill="currentColor" fillRule="nonzero"/>
      </g>
    </svg>
  );
}

export default InstagramIcon;
