import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import dataProcessingTable from '../../../assets/images/png/dataProcessingTable.png';
import rodoTable from '../../../assets/images/png/rodoTable.png';
import cookies1 from '../../../assets/images/png/cookies1.png';
import cookies2 from '../../../assets/images/png/cookies2.png';

import './style.scss';

const propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired, //richtext
  }).isRequired,
};

function PrivacyPolicy({ content }) {
  const { title } = content;

  return (
    <div className="Policy">
      <Helmet>
        <title>{ title }</title>
      </Helmet>


      <section className="Policy__content-wrapper">
        <div className="Policy__header-wrapper">
          <h1 className="Policy__heading">{ title }</h1>
          <h3 className="Policy__subheading">Polityka prywatności i plików cookies</h3>
        </div>

        <div className="Policy__content">
          <div>
            Poniżej znajdziesz informacje na temat przetwarzania danych osobowych oraz wykorzystywania
            plików cookies w aplikacji Mednavi (<a href="http://www.mednavi.pl/"><strong>www.mednavi.pl</strong></a>).
          </div>
          <br/>
          <div><strong>Kto jest administratorem Twoich danych osobowych</strong></div>
          <div>Administratorem Twoich danych osobowych jest Mednavi sp. z o.o.<br/>Nasza siedziba mieści się w Warszawie, email: biuro@mednavi.pl</div>
          <br/>
          <div><strong>Jak się z nami skontaktować</strong></div>
          <div>Mednavi wyznaczyła inspektora ochrony danych, który chętnie pomoże Ci we wszystkich sprawach związanych z ochroną danych osobowych i odpowie na ewentualne pytania. Funkcję inspektora ochrony danych pełni w Mednavi  Anna Żmijewska . Kontakt z inspektorem jest możliwy pod adresem e-mail: iod@mednavi.pl</div>
          <br/>
          <div><strong>Po co przetwarzamy Twoje dane osobowe</strong></div>
          <div>Krótko mówiąc, abyś mógł/mogła korzystać z aplikacji Mednavi.&nbsp;</div>
          <div>Chcesz wiedzieć więcej? Szczegóły poniżej.</div>
          <br/>
          <div><strong>Jakie dane osobowe przetwarzamy</strong></div>
          <div>Wszystko zależy od celu zebrania i przetwarzania danych osobowych, a także od tego, czy korzystasz z aplikacji w roli zarejestrowanego użytkownika (tj. takiego, który ma konto użytkownika w aplikacji Mednavi).&nbsp;</div>
          <div>Aby w pełni korzystać z funkcjonalności przewidzianych dla zarejestrowanego użytkownika, niezbędne jest wyrażenie zgody na przetwarzanie tzw. szczególnych kategorii danych (należą do nich np. dane dotyczące zdrowia). Oczywiście nie masz obowiązku wyrażania takiej zgody. Jeśli nie zgodzisz się na przetwarzanie szczególnych kategorii danych, nadal będziesz mógł korzystać z Mednavi na warunkach użytkownika niezalogowanego.</div>
          <br/>
          <div>Poniżej znajdziesz tabelę, w której wskazujemy, jakie dane przetwarzamy, w jakim celu to robimy oraz jaka podstawa prawna nam to umożliwia.</div>
          <br/>
          <img src={ dataProcessingTable } alt="" />
          <br/>
          <div><strong>Czy musisz podawać swoje dane osobowe</strong></div>
          <div>Jako użytkownik aplikacji nie masz obowiązku podawania Mednavi swoich danych, jednak bez tego nie będziemy w stanie zapewnić Ci wszystkich funkcjonalności aplikacji. Podanie danych osobowych jest niezbędne w celu zarejestrowania Twojego konta i udostępniania wielu funkcjonalności aplikacji, otrzymywania informacji o produktach i nowościach w formie newslettera czy prezentowania spersonalizowanych reklam i informacji. Jesteś zobowiązany podać nam dane, kiedy taka konieczność wynika z przepisów prawa, np. prawa podatkowego.</div>
          <br/>
          <div><strong>Czy dane osobowe są przekazywane innym podmiotom</strong></div>
          <div>Tak, z zastrzeżeniem, że są to podmioty przetwarzające dane w naszym imieniu na podstawie umów powierzenia (np. firma świadcząca dla nas usługi hostingowe, biuro księgowe), a także podmioty, którym zgodnie z prawem jesteśmy zobowiązani lub uprawnieni udostępnić Twoje dane.</div>
          <br/>
          <div>W związku z korzystaniem przez nas z narzędzi takich jak Google Analytics, Google Adwords, Facebook, Instagram, czy Youtube przekazujemy dane do Stanów Zjednoczonych Ameryki Północnej. Dzieje się to na podstawie decyzji wykonawczej (UE) 2016/1250 przyjętej na mocy dyrektywy 95/46/WE Parlamentu Europejskiego i Rady, w sprawie adekwatności ochrony zapewnianej przez Tarczę Prywatności UE-USA (notyfikowana jako dokument nr C (2016) 4176). Możesz uzyskać od nas kopię danych przekazywanych do państwa trzeciego.</div>
          <br/>
          <div><strong>Jakie prawa przysługują użytkownikom aplikacji Mednavi</strong></div>
          <div>W związku z przetwarzaniem danych osobowych na gruncie RODO przysługuje Ci wiele uprawnień. Ich podsumowanie i wyjaśnienie znajdziesz poniżej.</div>
          <br/>
          <img src={ rodoTable } alt="RODO" />
          <br/>
          <div><strong>Jak skorzystać z powyższych uprawnień</strong></div>
          <div>Wystarczy napisać do naszego inspektora ochrony danych - adres e-mail podano powyżej.</div>
          <br/>
          <div><strong>Jak długo przechowujemy dane osobowe</strong></div>
          <div>Dane osobowe użytkowników aplikacji przechowujemy nie dłużej, niż jest to potrzebne z punktu widzenia celu, dla którego zebraliśmy określone dane.</div>
          <br/>
          <div>Dane, które są konieczne do zapewnienia Ci korzystania z aplikacji, przechowujemy tak długo, jak z niej korzystasz (zaś w przypadku danych osobowych dotyczących zdrowia - do czasu wycofania zgody na takie przetwarzanie) lub do czasu upływu okresu przedawnienia roszczeń (podstawowy termin to 6 lat). 
            <br/>Dane osobowe wykorzystywane do przesyłania drogą mailową wiadomości o aktualnościach dotyczących funkcjonowania aplikacji przechowujemy do czasu utraty przydatności danych osobowych, chyba że wcześniej cofniesz zgodę na otrzymywanie ww. treści lub wniesiesz sprzeciw wobec przetwarzania.
            <br/>Czas przechowywania danych, które przetwarzamy w ramach wykonywania obowiązków prawnych z zakresu prawa podatkowego i ustawy o rachunkowości, wynika z tych przepisów. Natomiast w przypadku spełniania obowiązków prawnych wynikających z RODO (w zakresie realizacji Twoich praw i w związku z tym archiwizowania Twoich żądań lub zapytań), dane przechowujemy do czasu upływu terminów przedawnienia.
          </div>
          <br/>
          <div><strong>Pliki cookies</strong></div>
          <div>Pliki cookies (czyli ciasteczka) to niewielkie pliki, które wysyłane są przez serwer WWW, a następnie zapisywane, zwykle na twardym dysku urządzenia użytkownika, jeśli pozwalają na to ustawienia przeglądarki internetowej.</div>
          <br/>
          <div><strong>Czy strona internetowa Mednavi korzysta z ciasteczek</strong></div>
          <div>Tak. Twoja przeglądarka internetowa domyślnie dopuszcza wykorzystywanie cookies w Twoim urządzeniu, dlatego przy pierwszej wizycie prosimy o wyrażenie zgody na użycie cookies. Jeżeli jednak nie życzysz sobie wykorzystania cookies przy przeglądaniu strony internetowej, co do zasady możesz zmienić ustawienia w swojej przeglądarce internetowej: całkowicie blokować automatyczną obsługę plików cookies lub żądać powiadomienia o każdorazowym zamieszczeniu cookies w urządzeniu. Ustawienia można zmienić w dowolnej chwili, jednakże usunięcie lub zablokowanie plików cookies może utrudnić korzystanie ze strony internetowej. 
Pamiętaj ponadto, że niektóre ciasteczka są niezbędne do działania strony internetowej oraz aplikacji i nie można ich wyłączyć.  
          </div>
          <br/>
          <div><strong>Z jakich ciasteczek korzysta Mednavi i w jakim celu to robi</strong></div>
          <div>Poniżej znajdziesz tabelę z zestawieniem narzędzi i ciasteczek, które wykorzystuje Mednavi w ramach swojej aplikacji i strony internetowej:</div>
          <br/>
          <img src={ cookies1 } alt="cookies" />
          <img src={ cookies2 } alt="cookies" />
          <br/>
          <div><strong>Na jakiej podstawie prawnej Mednavi używa wymienionych narzędzi i ciasteczek</strong></div>
          <div>Podstawą prawną do używania przez nas cookies niezbędnych (wskazanych powyżej pod literą A oraz plików z serwisu YouTube) jest nasz prawnie uzasadniony interes, tj. prawo do prowadzenia Strony Internetowej wspierającej nasz biznes, oraz konieczność zachowania jej bezpieczeństwa. Podstawą prawną do używania przez nas pozostałych cookies jest Twoja zgoda.
W związku z przetwarzaniem przez Nas Twoich danych osobowych za pomocą plików cookies, w każdej chwili przysługują Ci prawa, o których piszemy powyżej.
Używanie przez nas plików cookies, w szczególności do celów reklamy behawioralnej, może wiązać się z tzw. profilowaniem. Oznacza to, że My lub firmy marketingowe, z których usług korzystamy, możemy tworzyć profil użytkownika na bazie zgromadzonych informacji (np. adres e-mail, historia zamówień, rodzaj urządzenia, stosowana technologia, częstotliwość wizyt) i na tej podstawie formułować prognozy zakupowe na przyszłość. Pozwala nam to podejmować decyzje, jakie treści (w tym reklamy) wyświetlać na naszej i innych stronach internetowych.
          </div>
          <br/>
          <div><strong>Zmiany w polityce prywatności</strong></div>
          <div>Polityka obowiązuje od dnia 11 maja 2020 roku. Informacje o jakiejkolwiek zmianie polityki zostaną udostępnione drogą mailową lub na naszej stronie internetowej.</div>
          <br/>
          <br/>
          <br/>
          <div><strong>KLAUZULE INFORMACYJNE DLA INNYCH OSÓB NIŻ UŻYTKOWNICY APLIKACJI</strong></div>
          <br/>
          <div><strong>A. Informacje dla osób do kontaktu w ramach umowy z kontrahentem lub pracowników instytucji i urzędów</strong></div>
          <br/>
          <div>
        Zgodnie z obowiązkiem wynikającym z RODO informujemy, że: 
            <br/>1. Administratorem Twoich danych osobowych jest Mednavi sp. z o.o., zwana dalej „Administratorem”. Nasza siedziba mieści się w Warszawie. Można się z nami skontaktować pod adresem e-mail: biuro@mednavi.pl
            <br/> 2. Funkcję inspektora ochrony danych pełni w Mednavi  Anna Żmijewska  Kontakt z inspektorem jest możliwy pod adresem e-mail: iod@mednavi.pl.
            <br/>3. Twoje dane uzyskaliśmy w związku z umową lub inną sprawą, która dotyczy podmiotu zatrudniającego Cię. Otrzymaliśmy te dane na potrzeby kontaktu za Twoim pośrednictwem albo bezpośrednio od Ciebie, albo pośrednio od podmiotu zatrudniającego Cię.
            <br/>4. Przetwarzamy takie Twoje dane osobowe, jak: firma lub nazwa instytucji/urzędu, które Cię zatrudniają; Twoje imię i nazwisko; funkcja lub stanowisko, jakie zajmujesz; służbowy numer telefonu; służbowy adres e-mail;
            <br/>5. Twoje dane osobowe mogą być przetwarzane w celu:
            <br/>a) kontaktowym — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem Administratora jest uzyskanie kontaktu z podmiotem zatrudniającym Cię;
            <br/>b) wypełnienia obowiązków związanych z ochroną danych osobowych — na podstawie art. 6 ust. 1 lit. c RODO (niezbędność do wypełnienia obowiązku prawnego przez Administratora Danych Osobowych) oraz art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem jest uzyskanie wiedzy np. na temat korzystania z uprawnień przez podmioty danych czy realizacja wewnętrznych procedur z zakresu ochrony danych osobowych; 
            <br/>c) archiwalnym i dowodowym  — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem Administratora jest posiadanie określonych danych na potrzeby wykazywania określonych faktów, np. na żądanie właściwego organu;
            <br/>d) ustalenia, dochodzenia i obrony roszczeń — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem jest ochrona interesu majątkowego Administratora;
            <br/>e) instalowanie cookies w ramach strony internetowej — na podstawie art. 6 ust. 1 lit. a RODO (zgoda osoby, której dane dotyczą); 
            <br/>f) analitycznym i statystycznym — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem jest uzyskanie danych na temat aktywności osób poruszających się po stronie internetowej;
            <br/>g) stosowania reklamy behawioralnej — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem jest pozyskiwanie nowych użytkowników aplikacji;
            <br/>6. w każdej chwili możesz wycofać udzieloną zgodę, przy czym wycofanie zgody pozostaje bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem; 
            <br/>7. podanie wszystkich danych osobowych jest dobrowolne, jednak podanie takich danych, jak imię i nazwisko, firma, stanowisko, numer telefonu, adres e-mail jest konieczne ze względu na potrzebę kontaktu (bez tych danych kontakt nie będzie możliwy); 
            <br/>8. Twoje dane osobowe nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji, w tym profilowania; 
            <br/>9. Twoje dane osobowe mogą zostać ujawnione naszym odbiorcom takim, jak firma księgowa, firma hostingowa, prawnicy, podwykonawcy; oprócz tego możemy zostać zobowiązani na podstawie przepisu prawa do udostępnienia Twoich danych osobowych podmiotom prywatnym i publicznym; 
            <br/>10. W związku z korzystaniem przez nas z narzędzi takich jak Google Analytics przekazujemy dane do Stanów Zjednoczonych Ameryki Północnej. Dzieje się to na podstawie decyzji wykonawczej (UE) 2016/1250 przyjętej na mocy dyrektywy 95/46/WE Parlamentu Europejskiego i Rady, w sprawie adekwatności ochrony zapewnianej przez Tarczę Prywatności UE-USA (notyfikowana jako dokument nr C (2016) 4176). Możesz uzyskać od nas kopię danych przekazywanych do państwa trzeciego.
            <br/>11. Twoje dane osobowe będą przechowywane: 
            <br/>a) przez okres wykonywania umowy zawartej z podmiotem zatrudniającym Cię — w odniesieniu do danych osobowych przetwarzanych w celu kontaktowym; 
            <br/>b) przez okres przedawnienia roszczeń — w odniesieniu do danych osobowych przetwarzanych w celu ustalenia, obrony i dochodzenia roszczeń; 
            <br/>c) do czasu cofnięcia zgody lub osiągnięcia celu przetwarzania — w odniesieniu do danych osobowych przetwarzanych na podstawie zgody; 
            <br/>d) do czasu skutecznego wniesienia sprzeciwu lub osiągnięcia celu przetwarzania — w odniesieniu do danych osobowych przetwarzanych na podstawie prawnie uzasadnionego interesu Administratora Danych Osobowych; 
            <br/>12. posiadasz prawo dostępu do swoich danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, wniesienia sprzeciwu wobec przetwarzania, a także prawo do przenoszenia danych; 
            <br/>13. posiadasz prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, jeżeli uważasz, że Twoje dane osobowe są przetwarzane niezgodnie z prawem. 
          </div>
          <br/>
          <div><strong>B. Informacje dla osób, których dane zostały pozyskane z wizytówek lub źródeł powszechnie dostępnych (w szczególności internetu)</strong></div>
          <br/>
          <div>
        Zgodnie z obowiązkiem wynikającym z RODO informujemy, że: 
            <br/>1. Administratorem Twoich danych osobowych jest Mednavi sp. z o.o., zwana dalej „Administratorem”. Nasza siedziba mieści się w Warszawie. Można się z nami skontaktować pod adresem e-mail: biuro@mednavi.pl
            <br/>2. Funkcję inspektora ochrony danych pełni w Mednavi  Anna Żmijewska  Kontakt z inspektorem jest możliwy pod adresem e-mail: iod@mednavi.pl.
            <br/>3. Twoje dane uzyskaliśmy z wizytówek lub ze źródeł powszechnie dostępnych (w szczególności stron internetowych i serwisów społecznościowych, gdzie zostały zamieszczone Twoje dane).
            <br/>4. Otrzymaliśmy takie Twoje dane osobowe, jak: firma lub nazwa instytucji/urzędu, które Cię zatrudniają; Twoje imię i nazwisko; funkcja lub stanowisko, jakie zajmujesz; służbowy numer telefonu; służbowy adres e-mail;
            <br/>5. Twoje dane osobowe mogą być przetwarzane w celu:
            <br/>a) kontaktowym — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem Administratora jest uzyskanie kontaktu z Tobą w celu nawiązania określonych relacji;
            <br/>b) marketingowym — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem Administratora jest realizacja założonych celów reklamowych i promocyjnych;
            <br/>c) wypełnienia obowiązków związanych z ochroną danych osobowych — na podstawie art. 6 ust. 1 lit. c RODO (niezbędność do wypełnienia obowiązku prawnego przez Administratora Danych Osobowych) oraz art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem jest uzyskanie wiedzy np. na temat korzystania z uprawnień przez podmioty danych czy realizacja wewnętrznych procedur z zakresu ochrony danych osobowych; 
            <br/>d) archiwalnym i dowodowym  — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem Administratora jest posiadanie określonych danych na potrzeby wykazywania określonych faktów, np. na żądanie właściwego organu;
            <br/>e) ustalenia, dochodzenia i obrony roszczeń — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem jest ochrona interesu majątkowego Administratora;
            <br/>f) instalowanie cookies w ramach strony internetowej — na podstawie art. 6 ust. 1 lit. a RODO (zgoda osoby, której dane dotyczą); 
            <br/>g) analitycznym i statystycznym — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem jest uzyskanie danych na temat aktywności osób poruszających się po stronie internetowej;
            <br/>h) stosowania reklamy behawioralnej — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem jest pozyskiwanie nowych użytkowników aplikacji;
            <br/>6. w każdej chwili możesz wycofać udzieloną zgodę, przy czym wycofanie zgody pozostaje bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem; 
            <br/>7. podanie wszystkich danych osobowych jest dobrowolne, jednak podanie takich danych, jak imię i nazwisko, firma, stanowisko, numer telefonu, adres e-mail jest konieczne ze względu na potrzebę kontaktu (bez tych danych kontakt nie będzie możliwy); 
            <br/>8. Twoje dane osobowe nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji, w tym profilowania; 
            <br/>9. Twoje dane osobowe mogą zostać ujawnione naszym odbiorcom takim, jak firma księgowa, firma hostingowa, prawnicy, podwykonawcy; oprócz tego możemy zostać zobowiązani na podstawie przepisu prawa do udostępnienia Twoich danych osobowych podmiotom prywatnym i publicznym; 
            <br/>10. W związku z korzystaniem przez nas z narzędzi takich jak Google Analytics przekazujemy dane do Stanów Zjednoczonych Ameryki Północnej. Dzieje się to na podstawie decyzji wykonawczej (UE) 2016/1250 przyjętej na mocy dyrektywy 95/46/WE Parlamentu Europejskiego i Rady, w sprawie adekwatności ochrony zapewnianej przez Tarczę Prywatności UE-USA (notyfikowana jako dokument nr C (2016) 4176). Możesz uzyskać od nas kopię danych przekazywanych do państwa trzeciego.
            <br/>11. Twoje dane osobowe będą przechowywane: 
            <br/>a) przez okres potrzebny do skontaktowania się z Tobą i załatwienia sprawy będącej przedmiotem kontaktu — w odniesieniu do danych osobowych przetwarzanych w celu kontaktowym; 
            <br/>b) przez okres przedawnienia roszczeń — w odniesieniu do danych osobowych przetwarzanych w celu ustalenia, obrony i dochodzenia roszczeń; 
            <br/>c) do czasu cofnięcia zgody lub osiągnięcia celu przetwarzania — w odniesieniu do danych osobowych przetwarzanych na podstawie zgody; 
            <br/>d) do czasu skutecznego wniesienia sprzeciwu lub osiągnięcia celu przetwarzania — w odniesieniu do danych osobowych przetwarzanych na podstawie prawnie uzasadnionego interesu Administratora Danych Osobowych; 
            <br/>12. posiadasz prawo dostępu do swoich danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, wniesienia sprzeciwu wobec przetwarzania, a także prawo do przenoszenia danych; 
            <br/>13. posiadasz prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, jeżeli uważasz, że Twoje dane osobowe są przetwarzane niezgodnie z prawem. 
          </div>
          <br/>
          <div><strong>C. Informacje dla osób otrzymujących przesyłki ze strony Mednavi</strong></div>
          <br/>
          <div>
        Zgodnie z obowiązkiem wynikającym z RODO informujemy, że: 
            <br/>1. Administratorem Twoich danych osobowych jest Mednavi sp. z o.o., zwana dalej „Administratorem”. Nasza siedziba mieści się w Warszawie. Można się z nami skontaktować pod adresem e-mail: biuro@mednavi.pl
            <br/>2. Funkcję inspektora ochrony danych pełni w Mednavi Anna Żmijewska  Kontakt z inspektorem jest możliwy pod adresem e-mail: iod@mednavi.pl.
            <br/>3. Twoje dane uzyskaliśmy bezpośrednio od Ciebie lub pośrednio — z wizytówek lub ze źródeł powszechnie dostępnych (w szczególności stron internetowych i serwisów społecznościowych, gdzie zostały zamieszczone Twoje dane).
            <br/>4. Przetwarzamy takie Twoje dane osobowe, jak: firma lub nazwa instytucji/urzędu, które Cię zatrudniają; adres siedziby; NIP; Twoje imię i nazwisko; funkcja lub stanowisko, jakie zajmujesz; służbowy numer telefonu; służbowy adres e-mail;
            <br/>5. Twoje dane osobowe mogą być przetwarzane w celu:
            <br/>a) podtrzymywania relacji — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem Administratora jest utrzymywania relacji z kontrahentami w dalszej perspektywie czasowej;
            <br/>b) przekazania kartki lub upominku — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem Administratora jest realizacja założonych celów marketingowych;
            <br/>c) wypełnienia obowiązków podatkowych związanych z przekazaniem kartki lub upominku  — na podstawie art. 6 ust. 1 lit. c RODO (niezbędność do wypełnienia obowiązku prawnego przez Administratora Danych Osobowych);
            <br/>d) wypełnienia obowiązków związanych z ochroną danych osobowych — na podstawie art. 6 ust. 1 lit. c RODO (niezbędność do wypełnienia obowiązku prawnego przez Administratora Danych Osobowych) oraz art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem jest uzyskanie wiedzy np. na temat korzystania z uprawnień przez podmioty danych czy realizacja wewnętrznych procedur z zakresu ochrony danych osobowych; 
            <br/>e) archiwalnym i dowodowym  — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem Administratora jest posiadanie określonych danych na potrzeby wykazywania określonych faktów, np. na żądanie właściwego organu;
            <br/>f) ustalenia, dochodzenia i obrony roszczeń — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem jest ochrona interesu majątkowego Administratora;
            <br/>g) instalowanie cookies w ramach strony internetowej — na podstawie art. 6 ust. 1 lit. a RODO (zgoda osoby, której dane dotyczą); 
            <br/>h) analitycznym i statystycznym — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem jest uzyskanie danych na temat aktywności osób poruszających się po stronie internetowej;
            <br/>i) stosowania reklamy behawioralnej — na podstawie art. 6 ust. 1 lit. f RODO (prawnie uzasadniony interes Administratora Danych Osobowych lub osoby trzeciej); prawnie uzasadnionym interesem jest pozyskiwanie nowych użytkowników aplikacji;
            <br/>6. w każdej chwili możesz wycofać udzieloną zgodę, przy czym wycofanie zgody pozostaje bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem; 
            <br/>7. podanie wszystkich danych osobowych jest dobrowolne, jednak podanie takich danych, jak imię i nazwisko, firma, siedziba jest konieczne w przypadku przekazywania kartki lub upominku (bez tych danych przekazanie kartki lub upominku nie będzie możliwe); 
            <br/>8. Twoje dane osobowe nie będą wykorzystywane do zautomatyzowanego podejmowania decyzji, w tym profilowania; 
            <br/>9. Twoje dane osobowe mogą zostać ujawnione naszym odbiorcom takim, jak firma księgowa, firma hostingowa, prawnicy, podwykonawcy; oprócz tego możemy zostać zobowiązani na podstawie przepisu prawa do udostępnienia Twoich danych osobowych podmiotom prywatnym i publicznym; 
            <br/>10. W związku z korzystaniem przez nas z narzędzi takich jak Google Analytics przekazujemy dane do Stanów Zjednoczonych Ameryki Północnej. Dzieje się to na podstawie decyzji wykonawczej (UE) 2016/1250 przyjętej na mocy dyrektywy 95/46/WE Parlamentu Europejskiego i Rady, w sprawie adekwatności ochrony zapewnianej przez Tarczę Prywatności UE-USA (notyfikowana jako dokument nr C (2016) 4176). Możesz uzyskać od nas kopię danych przekazywanych do państwa trzeciego.
            <br/>11. Twoje dane osobowe będą przechowywane: 
            <br/>a) przez okres podtrzymywania relacji — w odniesieniu do danych osobowych przetwarzanych w tym celu; 
            <br/>b) przez okres potrzebny do wysłania kartki lub przekazania upominku — w odniesieniu do danych osobowych przetwarzanych w tym celu;
            <br/>c) przez okres wynikający z przepisów podatkowych — w odniesieniu do danych osobowych przetwarzanych w celu wypełnienia obowiązków podatkowych;
            <br/>d) przez okres przedawnienia roszczeń — w odniesieniu do danych osobowych przetwarzanych w celu ustalenia, obrony i dochodzenia roszczeń; 
            <br/>e) do czasu cofnięcia zgody lub osiągnięcia celu przetwarzania — w odniesieniu do danych osobowych przetwarzanych na podstawie zgody; 
            <br/>f) do czasu skutecznego wniesienia sprzeciwu lub osiągnięcia celu przetwarzania — w odniesieniu do danych osobowych przetwarzanych na podstawie prawnie uzasadnionego interesu Administratora Danych Osobowych; 
            <br/>12. posiadasz prawo dostępu do swoich danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, wniesienia sprzeciwu wobec przetwarzania, a także prawo do przenoszenia danych; 
            <br/>13. posiadasz prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych, jeżeli uważasz, że Twoje dane osobowe są przetwarzane niezgodnie z prawem. 
          </div>
        </div>
      </section>
    </div>
  );
}

PrivacyPolicy.propTypes = propTypes;

export default PrivacyPolicy;
