import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import './style.scss';

import withContent from '../../_enhancers/withContent';
import getApiPath from '../../../utils/getApiPath';
import getRoutePath from '../../../utils/getRoutePath';

const propTypes = {
  content: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string.isRequired,
    stories: PropTypes.arrayOf(PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      full_name: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      lead: PropTypes.string.isRequired,
      link_label: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      position2: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      source_image_url: PropTypes.string.isRequired,
      suffix: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })),
  }),
};

function Stories({ content }) {
  const { stories, meta } = content;

  return (
    <>
      <Helmet>
        <title>Historie Pacjentów</title>
        <meta name="description" content={ meta.description } />

        <meta property="og:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | Historie Pacjentów` } />
        <meta property="og:description" content={ meta.description } />
        <meta property="og:image" content={ meta.image_url } />
        <meta property="og:image:secure_url" content={ meta.image_url } />
        <meta property="og:image:width" content="600px" />
        <meta property="og:image:height" content="314px" />

        <meta name="twitter:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | Historie Pacjentów` } />
        <meta name="twitter:description" content={ meta.description } />
        <meta name="twitter:image" content={ meta.image_url } />
      </Helmet>

      <section className="Stories">

        <div className="Stories__container">
          <h1 className="Stories__heading">
            { content.heading }
          </h1>

          <h3 className="Stories__subheading">
            { content.subheading }
          </h3>

          <div className="Stories__list">
            { stories.map(story => (
              <div
                className="Stories__story"
                key={ story.slug }
              >
                <Link
                  className="Stories__story-link"
                  to={ getRoutePath('STORY', story.slug) }
                >
                  <picture
                    style={ { backgroundImage: `url('${story.avatar_url}')` } }
                    className="Stories__thumb"
                  />
                </Link>

                <Link
                  className="Stories__story-link"
                  to={ getRoutePath('STORY', story.slug) }
                  >
                  { story.full_name }
                </Link>
              </div>
            )) }
          </div>
        </div>

      </section>
    </>
  );
}

Stories.propTypes = propTypes;

export { Stories };
export default withContent(Stories, getApiPath('CONTENT_STORIES'));
