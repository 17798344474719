import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

import getRoutePath from '../../../utils/getRoutePath';

import Button from '../../Button';

const propTypes = {
  publications: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    link_label: PropTypes.string.isRequired,
  })).isRequired,
};


function Publications({ publications }) {

  const links = {
    0: getRoutePath('ARTICLES'),
    1: getRoutePath('STORIES'),
  };

  return (
    <section className="Publications">
      { publications.map((publication, i) => (
        <section className="Publications__item" key={ i }>

          <h3 className="Publications__title">{ publication.title }</h3>

          <p className="Publications__subtitle">{ publication.subtitle }</p>

          <Button
            dark={ i === 0 }
            className="Publications__button"
            to={ links[i] }
          >
            { publication.link_label }
          </Button>
        </section>
      )) }
    </section>
  );
}

Publications.propTypes = propTypes;

export default Publications;
