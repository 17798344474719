export default function getOptions(array) {
  const options = array.map(value => {
    const option = {
      value: value,
      label: value,
    };

    return option;
  });

  return options;
};
