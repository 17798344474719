import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Group, Rect } from 'react-konva';

const propTypes = {
  id: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};


class DiagramNodeGroup extends Component {
  static propTypes = propTypes;

  constructor(props) {
    super(props);

    const state = {
      nodeWidth: 311,
      groupSpacing: 24,
      childrenHeight: props.children.length * 124,
      spacing: 12,
    };
    this.state = state;
  }

  componentDidMount() {
    this.setState({
      childrenHeight: this.getChildrenHeight(),
    });

    this.setChildrenPosition();
  }

  componentDidUpdate (prevProps, prevState) {
    if(prevProps.activeNode !== this.props.activeNode) {
      this.setGroupHeight();
    }
  }

  getChildrenHeight = () => {
    const childrenHeight = this.previewWrapper.getChildren().reduce((acc, child) => {
      const height = child.getAttr('height');
      acc += height;
      return acc;
    }, 0);

    return childrenHeight;
  }

  setChildrenPosition = () => {
    const { spacing } = this.state;
    const children = this.previewWrapper.getChildren();

    children.reduce((acc, child, index) => {
      const height = child.getAttr('height');
      const position = spacing * (index +1) + acc;
      acc += height;
      child.setAttr('y', position );
      child.setAttr('x', spacing );
      return acc;
    }, 0);
  }

  setGroupHeight = () => {
    const childrenHeight = this.getChildrenHeight();
    this.previewGroup.setAttr('height', childrenHeight + this.state.spacing );

    this.setState({
      childrenHeight: childrenHeight,
    });

    this.setChildrenPosition();
  }

  render() {
    const { id, children } = this.props;
    const { nodeWidth, childrenHeight, groupSpacing } = this.state;
    const childrenNodes = React.Children.count(children);
    const nodeGroupHeight = childrenHeight + groupSpacing / 2 * (childrenNodes + 1);
    const nodeGroupWidth = nodeWidth + groupSpacing;

    return (
      <Group
        id={ `preview-${id}` }
        width={ nodeGroupWidth }
        height={ nodeGroupHeight }
        ref={ group => this.previewGroup = group }
      >
        <Rect
          x={ 0 }
          y={ 0 }
          width={ nodeGroupWidth }
          height={ nodeGroupHeight }
          stroke="#20C5D8"
          strokeWidth={ 1 }
          cornerRadius={ 12 }
          preventDefault={ false }
        />

        <Group
          ref={ node => { this.previewWrapper = node; } }
        >
          { children }
        </Group>
      </Group>
    );
  }
}

export default DiagramNodeGroup;
