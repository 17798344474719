import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import clientManagerInstance from '../../../providers/clientProvider';
import getApiPath from '../../../utils/getApiPath';
import removeTrailingLetters from '../../../utils/removeTrailingLetters';
import withContent from '../../_enhancers/withContent';
import appLogo from '../../../../src/assets/images/svg/logo.svg';
import withNotification from '../../_enhancers/withNotification';
import UpsideDownHero from '../../_landingComponents/UpsideDownHero';
import UpsideDownMission from '../../_landingComponents/UpsideDownMission';
import UpsideDownTabs from '../../_landingComponents/UpsideDownTabs';
import pdfFile from '../../../assets/files/uzytkownicy-mediow-spolecznosciowych.pdf';

import './style.scss';

class UpsideDown extends React.Component {
  constructor(props) {
    super(props);

    this.client = props.client || clientManagerInstance.client;
  }

  render() {
    const { content } = this.props;
    const { hero, meta } = content;

    return (
      <>
        <Helmet>
          <title>Świat do góry nogami</title>
          <meta name="description" content="Świat do góry nogami" />

          <meta property="og:url" content="http://mednavi.pl/swiatdogorynogami" />
          <meta property="og:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | #swiatdogorynogami` } />
          <meta property="og:description" content="Gdy pojawia się rak, świat wywraca się do góry nogami." />
          <meta property="og:image" content={ appLogo } />
          <meta property="og:image:secure_url" content={ appLogo } />
          <meta property="og:image:width" content="600px" />
          <meta property="og:image:height" content="314px" />

          <meta name="twitter:title" content={ `${process.env.REACT_APP_PAGE_TITLE} | Świat do góry nogami` } />
          <meta name="twitter:description" content={ meta.description } />
          <meta name="twitter:image" content={ meta.image_url } />
        </Helmet>

        <UpsideDownHero { ...hero } />

        <UpsideDownMission />

        <UpsideDownTabs />

        <section className="UpsideDown__ina">
          <div className="UpsideDown__ina-avatar" />
          <h5>Cześć, jestem Ina.<br />Miałam guza mózgu.</h5>

          <p className="UpsideDownMission__suffix-content UpsideDownMission__suffix-content--narrow"
            dangerouslySetInnerHTML={ {
              __html: removeTrailingLetters('Kiedy zachorowałam szukałam informacji o najlepszej' +
              ' dla mnie metodzie leczenia. Pomagała mi rodzina i przyjaciele. Wiem, jak trudno przedzierać się przez' +
              ' informacyjny chaos. Dlatego Mednavi porządkuje wiedzę. My pytamy lekarzy, sprawdzamy, aktualizujemy…' +
              ' Ty oszczędzasz czas.'),
            } } />

          <p style={ { textAlign: 'center' } }>
            <a
              href="https://jesteminna.com.pl/"
              target="_blank"
              rel="noopener noreferrer"
              className="UpsideDown__blog"
            >
              Poznaj moją historię na prywatnym blogu
            </a>
          </p>

          <p style={ { textAlign: 'center', marginTop: '36px' } }>
            <a
              target="_blank"
              without rel="noopener noreferrer"
              href={ pdfFile }
              className="SignUp__highlight UpsideDown__info">
                Dane osobowe - obowiązek informacyjny - media społecznościowe
            </a>
          </p>
        </section>
      </>
    );
  }
}

export { UpsideDown as Home };
export default withNotification(withRouter(withContent(UpsideDown, getApiPath('CONTENT_HOME'))));
